import { 
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_STOP,
  FETCH_START_WITH_FORWARD,
} from "../types/common"



export const fetchStart = () => ({
  type: FETCH_START
})
export const fetchStartWithForward = () => ({
  type: FETCH_START_WITH_FORWARD
})

export const fetchSuccess = () => ({
  type: FETCH_SUCCESS
})

export const fetchStop = () => ({
  type: FETCH_STOP
})