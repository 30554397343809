import React, {FunctionComponent} from 'react'
import {Stack} from 'react-bootstrap'
import {ArrowClockwise, ArrowRight, XCircleFill} from 'react-bootstrap-icons'
import {Card, Tooltip} from 'react-bootstrap-v5'
interface FailedBankStatementProps {
  file: File & {key: string}
  status?: number
  error?: string
  onClose: () => void
}

const FailedBankStatement: FunctionComponent<FailedBankStatementProps> = ({
  file,
  error,
  status,
  onClose,
}) => {
  const uploadStatus = status == 200 ? 'success' : 'error'

  const colors: {[key: string]: any} = {
    error: '#dc3545',
    success: '#198754',
  }

  function getColor() {
    if (['error', 'success'].includes(uploadStatus)) {
      return colors[uploadStatus]
    }
    return 'text.disabled'
  }

  const getIcon = () => {
    if (uploadStatus === 'error') {
      return <ArrowClockwise color='#dc3545' size={20} />
    }

    return <Card />
  }

  const getCancelIcon = () => {
    if (status != 200 || error) {
      return <XCircleFill style={{cursor: 'pointer'}} onClick={onClose} size={20} />
    }
  }
  return (
    <>
      <Stack
        style={{
          width: 'auto',
          display: 'inline-block',
          marginBottom: '10px',
          marginRight: '10px',
          verticalAlign: 'top',
        }}
      >
        <Stack
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            color: getColor(),
            border: '1px solid',
            borderColor: getColor(),
            borderRadius: 50,
            padding: '9px 15px',
            fontSize: '12px',
            minWidth: '100%',
            width: 'auto',
            display: 'inline-flex',
          }}
          gap={1}
        >
          {getIcon()}
          <div
            style={{
              fontSize: 14,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '16rem',
            }}
          >
            {file?.name}
          </div>
          {getCancelIcon()}
        </Stack>

        {error && (
          <div style={{fontSize: 10, color: getColor()}}>
            <span style={{fontSize: 14}}>*</span>
            {error}
          </div>
        )}
      </Stack>
    </>
  )
}

export default FailedBankStatement
