/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import clsx from 'clsx'

import CompanyVerification from '../CompanyVerification'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ServerError, useMutation} from '@apollo/client'
import mutations from '../../../../setup/graphql/mutations'
import Alert from '../../../../_metronic/partials/alert'
import {fetchStart, fetchStop} from '../../../../redux/actions/common'
import BankStatementValidator from '../../../../components/BankStatementValidator/BankStatementValidator'
import {generatePerfiosReport} from '../../../../components/BankStatementValidator/utils/perfiosTransaction'
import {CustomLoaderModal} from '../../../../_metronic/helpers/components/CustomLoaderModal'

const Step2: FC = ({
  // prevStep,
  // goToStep,
  updateApplication,
}: any) => {
  const dispatch = useDispatch()

  const [openCustomLoaderModal, setOpenCustomLoaderModal] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const [openVerification, setOpenVerification] = useState(false)
  const [fileGroup, setFileGroup] = useState<any>([])
  const [uploadedFiles, setUploadedFiles] = useState<any>([])

  const [isBankReportGenerated, setIsBankReportGenerated] = useState<boolean>(false)

  const [removeAllUploadedStatements, setRemoveAllUploadedStatements] = useState<boolean>(false)

  const [uploadedCorrectStatements, setUploadedCorrectStatements] = useState(false)

  const [createIncomeDocument, onSuccessCreateIncomeDocument] = useMutation(
    mutations.CREATE_INCOME_DOCUMENTS
  )
  const [deleteIncomeDocument, onSuccessDeleteIncomeDocument] = useMutation(
    mutations.DELETE_INCOME_DOCUMENT
  )
  const [validateStmtsNow, setValidateStmtsNow] = useState<boolean>(true)

  const loanApplication = useSelector((state: any) => state.partnerConnect.partnerApplication)

  useEffect(() => {
    if (loanApplication.hasOwnProperty('incomeDocuments')) {
      const {incomeDocuments} = loanApplication
      let file = []
      for (const document of incomeDocuments) {
        const _file = {
          id: document.id,
          type: document.type,
          file: document.documentFile,
        }
        file.push(_file)
      }

      if (
        file &&
        file.length &&
        loanApplication?.bankStatementsReportFileName &&
        loanApplication?.bankStatementsReportFilePath
      ) {
        setIsBankReportGenerated(true)
      }
      setValidateStmtsNow(loanApplication?.perfiosIntegration)
      setFileGroup(file)
    }
  }, [loanApplication])

  useEffect(() => {
    if (removeAllUploadedStatements) {
      handleRemoveAllIncomeDocuments()
    }
  }, [removeAllUploadedStatements])

  useEffect(() => {
    if (onSuccessCreateIncomeDocument.called && !onSuccessCreateIncomeDocument.loading) {
      dispatch(fetchStop())
      if (onSuccessCreateIncomeDocument.data) {
        if (onSuccessCreateIncomeDocument.data) {
          const {createIncomeDocuments} = onSuccessCreateIncomeDocument.data
          let file = [...fileGroup]
          for (const document of createIncomeDocuments) {
            const _file = {
              id: document.id,
              type: document.type,
              file: document.documentFile,
            }
            file.push(_file)
          }

          setFileGroup(file)

          if (loanApplication.applicationCompleteStep < 2) {
            updateApplication({
              variables: {
                data: {applicationCompleteStep: 2},
                where: {id: loanApplication.id},
              },
            })
          }

          onSuccessCreateIncomeDocument.reset()
        }
      } else {
      }

      if (onSuccessCreateIncomeDocument.error) {
        const {networkError} = onSuccessCreateIncomeDocument.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
      }
    }
  }, [
    onSuccessCreateIncomeDocument.loading,
    onSuccessCreateIncomeDocument.data,
    onSuccessCreateIncomeDocument.error,
    onSuccessCreateIncomeDocument.called,
  ])

  const handleStatementUpload = (type: string = '') => {
    try {
      let files = []
      let uploadDocs = []

      for (let file of uploadedFiles) {
        const {response, ...restFileData} = file
        file.filename = file.name
        files.push({type: type, restFileData})
        uploadDocs.push({
          application: {
            connect: {
              id: loanApplication.id,
            },
          },
          type: type,
          documentFile: {
            upload: file,
          },
        })
      }

      // _fileGroup = _fileGroup.concat(files);
      // setFileGroup(_fileGroup);

      createIncomeDocument({
        variables: {
          data: uploadDocs,
        },
      })
    } catch (error) {
      throw error
    }
  }

  const isValidFile = (event: any, type: string) => {
    const checkType = [...event.target.files].some((file: any) => file.type !== type)
    if (checkType) {
      setShowAlert(true)
      setAlertMsg(`Please upload a ${type === 'application/pdf' ? 'pdf' : 'xlsx'} file`)
      setAlertType('primary')
      return false
    }
    return true
  }

  const handleFileUpload = (event: any, type: string, fileType: string = 'application/pdf') => {
    if (event.target.validity.valid) {
      // let _fileGroup = [...fileGroup];
      let files = []
      let uploadDocs = []

      // _fileGroup = _fileGroup.filter((o:any) => {return o.type !== type});

      const isValid = isValidFile(event, fileType)

      if (isValid) {
        for (let file of event.target.files) {
          file.filename = file.name
          files.push({type: type, file})
          uploadDocs.push({
            application: {
              connect: {
                id: loanApplication.id,
              },
            },
            type: type,
            documentFile: {
              upload: file,
            },
          })
        }

        // _fileGroup = _fileGroup.concat(files);
        // setFileGroup(_fileGroup);

        createIncomeDocument({
          variables: {
            data: uploadDocs,
          },
        })

        dispatch(fetchStart())
      }
    }
  }

  const handleRemoveAllIncomeDocuments = () => {
    try {
      if (fileGroup && fileGroup?.length) {
        for (const file of fileGroup) {
          deleteIncomeDocument({
            variables: {
              where: {id: file.id},
            },
          })
        }
        updateApplication({
          variables: {
            data: {
              applicationCompleteStep: 2,
              bankStatementsReportFileName: '',
              bankStatementsReportFilePath: '',
              bankStatementsXlsxReportFileName: '',
              bankStatementsXlsxReportFilePath: '',
            },
            where: {id: loanApplication.id},
          },
        })
        setFileGroup([])
      }
    } catch (error) {
      throw error
    }
  }

  /**
   * Calls generate report api
   * @returns report details uploaded on azure
   */
  const retrieveBankReport = async () => {
    try {
      const report = await generatePerfiosReport(
        loanApplication?.id,
        `${loanApplication?.id}_report`,
        loanApplication?.applicationType
      )
      return report
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  const removeSingleFile = (id: string) => {
    const _fileGroup = fileGroup.filter((o: any) => o.id !== id)
    setFileGroup(_fileGroup)
    deleteIncomeDocument({
      variables: {
        where: {id: id},
      },
    })
  }

  const submitAll = async () => {
    try {
      // dispatch(fetchStart())

      if (
        validateStmtsNow &&
        (loanApplication.applicationCompleteStep !== 2 ||
          !loanApplication.bankStatementsReportFilePath ||
          removeAllUploadedStatements)
      ) {
        setOpenCustomLoaderModal(true)
        const report = await retrieveBankReport()
        await handleStatementUpload('bank-statement')
        // await addBankReportToApplication(reportPath)

        updateApplication({
          variables: {
            data: {
              applicationCompleteStep: 2,
              bankStatementsReportFileName: report?.reportFileName,
              bankStatementsReportFilePath: report?.reportFilePath,
              bankStatementsXlsxReportFileName: report?.reportXlsxFileName,
              bankStatementsXlsxReportFilePath: report?.reportXlsxFilePath,
            },
            where: {id: loanApplication.id},
          },
        })

        setUploadedFiles([])
        setIsBankReportGenerated(true)

        setOpenCustomLoaderModal(false)
      }

      setOpenVerification(true)
      // dispatch(fetchStop())
    } catch (error) {
      setOpenCustomLoaderModal(false)
      setShowAlert(true)
      setAlertMsg(
        'Sorry we failed to process your statements at this moment, please try after some time.'
      )
      setAlertType('primary')
    }
  }

  return (
    <div className='d-flex flex-column flex-column-fluid w-100'>
      <h2>Income Documents</h2>
      <div className='sub-heading fs-5 mb-10'>Request you to upload income documents</div>

      <div className='card'>
        <div className='card-header'>
          <div className='d-flex flex-column-fluid'>
            <div className='w-75'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light'>
                    <img src={toAbsoluteUrl('/media/alexi/taxes.png')} alt='' width='40' />
                  </div>
                </div>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Bank Statements</span>
                </h3>
              </div>
            </div>
            <div className='w-25'>
              <div className='card-toolbar flex-center mt-5'>
                <div
                  className={clsx(
                    'badge badge-light fw-bold py-4 px-3',
                    fileGroup.some(({type}: any) => type === 'bank-statement')
                      ? 'badge-primary'
                      : ''
                  )}
                ></div>
              </div>
            </div>
          </div>
          {!validateStmtsNow && (
            <div className='w-25'>
              <div className='card-toolbar flex-center mt-5'>
                <div
                  className={clsx(
                    'badge badge-light fw-bold py-4 px-3',
                    fileGroup.some(({type}: any) => type === 'bank-statement')
                      ? 'badge-primary'
                      : ''
                  )}
                >
                  {fileGroup.some(({type}: any) => type === 'bank-statement')
                    ? 'Completed'
                    : 'To Upload'}
                </div>
              </div>
            </div>
          )}
        </div>

        {validateStmtsNow && (
          <div className='card-body'>
            <BankStatementValidator
              refId={loanApplication?.id}
              refType={loanApplication?.applicationType}
              setUploadedCorrectStatements={setUploadedCorrectStatements}
              setUploadedFiles={setUploadedFiles}
              setIsBankReportGenerated={setIsBankReportGenerated}
              isBankReportGenerated={isBankReportGenerated}
              removeAllUploadedStatements={setRemoveAllUploadedStatements}
              validateMonthsTill={6}
            />
          </div>
        )}
        {!validateStmtsNow && (
          <div className='card-body'>
            <div className='d-flex'>
              <div className='w-75'>
                <ul>
                  <li>
                    PDFs (not scanned copies) of company's operating bank account(s) statements for
                    the past 6 months.
                    <ul>
                      <li>
                        Ex: If today is 2 July 22, then please upload bank statements from Jan 22 to
                        Jun 22 (both months inclusive)
                      </li>
                    </ul>
                  </li>
                  <li>
                    If your company is multi-banked, then please upload 6 months bank statements for
                    each bank account
                  </li>
                  <li>
                    If your statements are password protected, we request you to disable the
                    password before uploading it.
                  </li>
                </ul>
              </div>
              <div className='w-25'>
                <div className='bg-light-primary px-6 py-8 rounded-2 me-7 mb-7 text-center'>
                  <div className='svg-icon svg-icon-5x'>
                    {fileGroup.some(({type}: any) => type === 'bank-statement') ? (
                      <img src={toAbsoluteUrl('/media/svg/files/pdf.svg')} alt='' />
                    ) : (
                      <KTSVG path='/media/svg/files/upload.svg' />
                    )}
                  </div>
                  <div className='text-muted  fs-5 my-5 text-left'>
                    {fileGroup.some(({type}: any) => type === 'bank-statement') ? (
                      <>
                        {/* {fileGroup.length > 0 && fileGroup.filter(({file, type}:any) => type === 'bank-statement')[0].file.filename}  */}
                        {fileGroup
                          .filter(({type}: any) => type === 'bank-statement')
                          .map(({file, type, id}: any, i: number) => {
                            const filename = file.filename.split('.')
                            const name = filename[0].substring(0, 15)
                            const extension = filename[1]
                            return (
                              <div key={i} className='d-flex justify-content-between'>
                                {`${name}...${extension}`}{' '}
                                <span
                                  className='cursor-pointer'
                                  onClick={() => removeSingleFile(id)}
                                >
                                  <i className='fa fa-times'></i>
                                </span>
                              </div>
                            )
                          })}
                      </>
                    ) : (
                      'Upload file here'
                    )}
                  </div>
                  <div className='upload'>
                    <input
                      id='upload-file-bank'
                      type='file'
                      multiple
                      accept='application/pdf'
                      onChange={(e: any) => handleFileUpload(e, 'bank-statement')}
                    />
                    <label htmlFor='upload-file-bank'>
                      {fileGroup.some(({type}: any) => type === 'bank-statement')
                        ? 'Add more +'
                        : 'Browse File'}
                    </label>
                  </div>
                  {/* {fileGroup.some(({type}:any) => type === 'bank-statement')
                  ? <div><button className="btn btn-primary w-100" onClick={() => removeFile('bank-statement')}>Remove X</button></div>
                  : <div className="upload">
                      <input id="upload-file-bank" type="file" multiple accept="application/pdf" onChange={(e:any) => handleFileUpload(e, 'bank-statement')} />
                      <label htmlFor="upload-file-bank">Browse File</label>
                    </div>
                } */}
                </div>
              </div>
            </div>
          </div>
        )}

        {uploadedCorrectStatements && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              color: '#198754',
              fontSize: '16px',
              fontWeight: 900,
              marginBottom: '20px',
            }}
          >
            <span>
              Congratulations!!! Your Bank Statement has been validated, now you can proceed for
              next step.
            </span>
          </div>
        )}
        {validateStmtsNow &&
        loanApplication.applicationCompleteStep === 2 &&
        loanApplication.bankStatementsReportFilePath &&
        fileGroup.length &&
        fileGroup.some(({type}: any) => type === 'bank-statement') &&
        !removeAllUploadedStatements &&
        !uploadedCorrectStatements ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              color: '#198754',
              fontSize: '16px',
              fontWeight: 900,
              marginBottom: '20px',
            }}
          >
            <span>
              You have already uploaded your bank statements, now you can either reupload your bank
              statement or proceed for next step.
            </span>
          </div>
        ) : null}
      </div>

      <div className='d-flex flex-end'>
        <button
          className='btn btn-lg btn-primary me-3'
          // disabled={fileGroup.length < 3}
          // disabled={!fileGroup.some(({type}: any) => type === 'bank-statement')}
          style={{
            background:
              (validateStmtsNow && !isBankReportGenerated && !uploadedCorrectStatements) ||
              (!validateStmtsNow && !fileGroup.some(({type}: any) => type === 'bank-statement'))
                ? 'grey'
                : '#EC0055',
          }}
          disabled={
            validateStmtsNow
              ? !isBankReportGenerated && !uploadedCorrectStatements
              : !fileGroup.some(({type}: any) => type === 'bank-statement')
          }
          onClick={() => submitAll()}
        >
          Next
        </button>
      </div>

      {openVerification && (
        <CompanyVerification
          show={openVerification}
          handleClose={() => setOpenVerification(false)}
          // goToStep={goToStep}
        />
      )}

      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}

      <CustomLoaderModal
        openCustomLoaderModal={openCustomLoaderModal}
        setOpenCustomLoaderModal={setOpenCustomLoaderModal}
        message='Please wait while we process your statements, it might take a while.'
      />
    </div>
  )
}

export {Step2}
