import React, {FunctionComponent, useMemo} from 'react'
import {Button, Stack} from 'react-bootstrap'

interface ReUploadComponentProps {
  setReuploadWarningModal: (flag: boolean) => void
}

export const ReUploadComponent: FunctionComponent<ReUploadComponentProps> = ({
  setReuploadWarningModal,
}) => {
  return (
    <div
      style={{
        border: '1px dashed #190355',
        padding: '63px 40px',
        background: 'rgba(26,3,85,0.02)',
        minHeight: '150px',
        fontSize: '14px',
        marginBottom: '15px',
        borderRadius: '10px',
      }}
    >
      <Stack
        style={{
          alignItems: 'cetner',
          justifyContent: 'center',
          color: 'primary.main',
        }}
        gap={1}
      >
        <div>
          <div
            style={{
              display: 'inline',
              borderBottom: '1px solid',
              borderBottomColor: 'primary.main',
              fontSize: '14px',
              cursor: 'pointer',
            }}
            onClick={() => setReuploadWarningModal(true)}
          >
            Re-Upload
          </div>
          <span style={{marginLeft: '5px'}}>Your Bank Statements</span>
        </div>
      </Stack>
    </div>
  )
}
