import { FC, useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { fetchStart, fetchStop, fetchStartWithForward } from '../../../../redux/actions/common'
import mutations from '../../../../setup/graphql/mutations'
import query from '../../../../setup/graphql/query'
import Alert from '../../../../_metronic/partials/alert'
import { setSingPassInfo } from '../redux/actions'
import { AlexiRoutes } from '../../../../utils/constants/AlexiRoutes.enum'

export const historyHead = [
  'yearofassessment',
  'trade',
  'category',
  'employment',
  'amount',
  'interest',
  'rent',
  'taxclearance',
]
export const shareholderHead = [
  'entity-reference',
  'person-reference',
  'allocation',
  'category',
  'share-type',
  'currency',
  'corppass-email',
  'corppass-mobileno',
]
export const financialHead = [
  'currency',
  'company-revenue',
  'company-profit-loss-after-tax',
  'company-profit-loss-before-tax',
  'current-period-end-date',
  'current-period-start-date',
  'group-capital-paid-up-capital-amount',
  'group-profit-loss-after-tax',
  'group-profit-loss-before-tax',
  'group-revenue',
  'is-audited',
]
export const corppassHead = ['person-reference', 'corppass-email', 'corppass-mobileno']
export const capitalHead = [
  'capital-type',
  'currency',
  'issued-capital-amount',
  'paid-up-capital-amount',
  'share-allotted-amount',
]
export const appointmentHead = [
  'person-reference',
  'category',
  'position',
  'appointment-date',
  'corppass-email',
  'corppass-mobileno',
]

export const toStr = (data: any, type: any) => {
  if (data === undefined) return null

  if (type === 'ADDRESSLOCAL') {
    let val = ''
    if (data?.type === 'SG') {
      if (data?.block?.value) val = val + 'BLK ' + data.block.value
      if (data?.building?.value) val = val + ' ' + data.building.value
      if (data?.floor?.value) val = val + ' ' + data.floor.value
      if (data?.unit?.value) val = val + '-' + data.unit.value
      if (data?.street?.value) val = val + ' ' + data.street.value
      if (data?.postal?.value) val = val + ' S(' + data.postal.value + ')'
    } else if (data?.type === 'Unformatted') {
      if (data?.line1) val = data?.line1?.value
      if (data?.line2) val = val + ' ' + data?.line2?.value
    }
    return val
  } else if (type === 'PHONENUMLOCAL') {
    let val = data.prefix.value + data.areacode.value + ' ' + data.nbr.value
    return val
  } else if (type === 'MONEY') {
    let val = data.value.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return val
  } else if (type === 'HOUSINGTYPE') {
    let val = ''
    if (data?.housingtype?.desc) val = data.housingtype.desc
    else if (data?.hdbtype?.desc) val = data.hdbtype.desc
    return val
  } else if (type === 'PRIMARYSSIC' && data?.['basic-profile']?.['primary-activity']) {
    let val =
      data['basic-profile']['primary-activity'].code +
      ' - ' +
      data['basic-profile']['primary-activity'].desc
    return val
  } else if (type === 'SECONDARYSSIC' && data?.['basic-profile']?.['secondary-activity']) {
    let val =
      data['basic-profile']['secondary-activity'].code +
      ' - ' +
      data['basic-profile']['secondary-activity'].desc
    return val
  } else if (type === 'ENTITYADDRESS') {
    let val = ''
    let adddata = data['addresses']['addresses-list']
    if (adddata[0] !== undefined) {
      adddata = adddata[0]
    }
    let standard = adddata?.['standard']?.code
    if (standard === 'D') {
      let add = adddata
      if (add?.block?.value) val = val + 'BLK ' + add.block.value
      // if (add.building.value !== undefined && add.building.value != "")
      if (add?.building?.value) val = val + ' ' + add.building.value
      if (add?.floor?.value) val = val + ' ' + add.floor.value
      if (add?.unit?.value) val = val + '-' + add.unit.value
      if (add?.street?.value) val = val + ' ' + add.street.value
      if (add?.postal?.value) val = val + ' S(' + add.postal.value + ')'
    } else {
      let add = adddata
      if (add?.['line1']?.value) val = val + add['line1'].value
      if (add?.['line2']?.value) val = val + add['line2'].value
    }
    return val
  } else {
    return null
  }
}

const Step3: FC<any> = ({ updateApplication }) => {
  const dispatch = useDispatch()
  const history: any = useHistory()

  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')

  const [createExternalDataStore, onSuccessCreateExternalDataStore] = useMutation(
    mutations.CREATE_EXTERNAL_DATA_STORE
  )

  const companyVerificationMethod = useSelector(
    (state: any) => state.kycApplication.companyVerificationMethod
  )
  const { person, entity }: any = useSelector((state: any) => state.kycApplication.entityPerson)
  const loanApplication = useSelector((state: any) => state.kycApplication.loanApplication)

  console.log('Person:', person)

  useEffect(() => {
    if (onSuccessCreateExternalDataStore.called && !onSuccessCreateExternalDataStore.loading) {
      dispatch(fetchStop())
      if (onSuccessCreateExternalDataStore.data) {
        history.push(`/${AlexiRoutes.SG_ONBOARDING}/${loanApplication.id}?step=4`)

        onSuccessCreateExternalDataStore.reset()
      } else {
      }

      if (onSuccessCreateExternalDataStore.error) {
        setShowAlert(true)
        setAlertMsg('error')
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [onSuccessCreateExternalDataStore.loading])

  const initialValues: any = {
    uinfin: person?.uinfin?.value ?? '',
    name: person?.name?.value ?? '',
    sex: person?.sex?.desc ?? '',
    // race: person.race.desc,
    nationality: person?.nationality?.desc ?? '',
    dob: person?.dob?.value ?? '',
    email: person?.email?.value ?? '',
    mobileno: person
      ? `${person?.mobileno?.prefix?.value}${person?.mobileno?.areacode?.value}${person?.mobileno?.nbr?.value}`
      : '',
    // regadd: person ? `${person.regadd.block?.value} ${person.regadd.building?.value} ${person.regadd.floor?.value} ${person.regadd.unit?.value} ${person.regadd.street?.value} ${person.regadd.postal?.value}` : '',
    regadd: person ? toStr(person.regadd, 'ADDRESSLOCAL') : '',
    // housingtype: `${person.housingtype.desc} ${person.hdbtype.desc}`,
    // marital: person.marital.desc,
    // edulevel: person.edulevel.desc,
    residentialstatus: person?.residentialstatus?.desc ?? '',
    ownerprivate: person?.ownerprivate?.value ?? '',
    noahistory: person?.noahistory?.noas || [],

    entityName: entity ? entity?.['basic-profile']?.['entity-name']?.value : '',
    entityType: entity ? entity?.['basic-profile']?.['entity-type']?.desc : '',
    entityStatus: entity ? entity?.['basic-profile']?.['entity-status']?.value : '',
    businessExpiryDate: entity ? entity?.['basic-profile']['business-expiry-date']?.value : '',
    registrationDate: entity ? entity?.['basic-profile']['registration-date']?.value : '',
    countryOfIncorporation: entity
      ? entity?.['basic-profile']?.['country-of-incorporation']?.desc
      : '',
    businessConstitution: entity ? entity?.['basic-profile']?.['business-constitution']?.desc : '',
    // primarySSIC: entity ? `${entity['basic-profile']['primary-activity'].code}-${entity['basic-profile']['primary-activity'].desc}` : '',
    // secondarySSIC: entity ? `${entity['basic-profile']['secondary-activity'].code}-${entity['basic-profile']['secondary-activity'].desc}` : '',
    primarySSIC: entity ? toStr(entity, 'PRIMARYSSIC') : '',
    secondarySSIC: entity ? toStr(entity, 'SECONDARYSSIC') : '',
    ownership: entity ? entity['basic-profile']['ownership']?.desc : '',
    companyType: entity ? entity['basic-profile']['company-type']?.desc : '',
    uen: entity ? entity?.['basic-profile']?.['uen']?.value : '',
    enregadd: entity ? toStr(entity, 'ENTITYADDRESS') : '',

    financials: entity ? entity?.financials?.highlights : [],
    corppassContacts: entity ? entity?.['corppass-contacts']?.['corppass-contacts-list'] ?? [] : [],
    capitals: entity ? entity?.capitals?.['capitals-list'] ?? [] : [],
    shareholdersList: entity ? entity?.shareholders?.['shareholders-list'] : [],
    appointments: entity ? entity?.['appointments']?.['appointments-list'] : [],

    confirmCheckbox: false,
  }

  const validationForManual =
    companyVerificationMethod === 2
      ? {
          uinfin: Yup.string().required('NRIC/FIN is required'),
          name: Yup.string().required('Name is required'),
          sex: Yup.string().required('Sex is required'),
          nationality: Yup.string().required('Nationality is required'),
          dob: Yup.string().required('Date of Birth is required'),
          email: Yup.string().required('Email is required'),
          mobileno: Yup.string().required('Mobile number is required'),
          regadd: Yup.string().required('Registered Address is required'),
          residentialstatus: Yup.string().required('Residential Status is required'),
          ownerprivate: Yup.string().required('Owner Private is required'),

          entityName: Yup.string().required('Entity Name is required'),
          entityType: Yup.string().required('Entity Type is required'),
          entityStatus: Yup.string().required('Entity Status is required'),
          businessExpiryDate: Yup.string().required('Business Expiry Date is required'),
          registrationDate: Yup.string().required('Registration Date is required'),
          countryOfIncorporation: Yup.string().required('Country Of Incorporation is required'),
          businessConstitution: Yup.string().required('Business Constitution is required'),
          primarySSIC: Yup.string().required('Primary SSIC is required'),
          secondarySSIC: Yup.string().required('Secondary SSIC is required'),
          ownership: Yup.string().required('Ownership is required'),
          companyType: Yup.string().required('Company Type is required'),
          uen: Yup.string().required('UEN is required'),
          enregadd: Yup.string().required('Registered Address is required'),
        }
      : ''

  const validationSchema = Yup.object().shape({
    ...validationForManual,
    confirmCheckbox: Yup.bool().oneOf([true], 'Please select checkbox to move ahead'),
  })

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any, { setStatus, setSubmitting }: any) => {
      const { confirmCheckbox, ...rest } = values

      dispatch(fetchStartWithForward())

      createExternalDataStore({
        variables: {
          data: {
            data: rest,
            application: {
              connect: {
                id: loanApplication?.id,
              },
            },
            type: 'singpass',
          },
        },
      })

      updateApplication({
        variables: {
          data: {
            applicationCompleteStep: 3,
            experianConsent: true,
            mibCompanyName: values?.entityName || '',
            mibCompanyUEN: values?.uen || '',
            companyUEN: values?.uen || '',
          },
          where: { id: loanApplication.id },
        },
      })

      dispatch(setSingPassInfo(rest))
    },
  })

  return (
    <div className='d-flex flex-column flex-column-fluid w-100'>
      <h2>Company Profile</h2>
      {companyVerificationMethod === 1 && (
        <div className='sub-heading fs-5 mb-10'>
          The following details have been provided to us by MyInfo Business
        </div>
      )}
      {companyVerificationMethod === 2 && (
        <div className='sub-heading fs-5 mb-10'>The following details need to be filled </div>
      )}

      <form onSubmit={formik.handleSubmit}>
        <h3 className='mb-10'>Personal Information</h3>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            NRIC/FIN
          </label>
          <div className='col-sm-9'>
            <input
              name='uinfin'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={formik.values.uinfin}
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.uinfin && formik.errors.uinfin && (
              <div className='text-danger mt-2'>{formik.errors.uinfin}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Name
          </label>
          <div className='col-sm-9'>
            <input
              name='name'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={formik.values.name}
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='text-danger mt-2'>{formik.errors.name}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Sex
          </label>
          <div className='col-sm-9'>
            <input
              name='sex'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={formik.values.sex}
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.sex && formik.errors.sex && (
              <div className='text-danger mt-2'>{formik.errors.sex}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Date of birth
          </label>
          <div className='col-sm-9'>
            <input
              name='dob'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={formik.values.dob}
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.dob && formik.errors.dob && (
              <div className='text-danger mt-2'>{formik.errors.dob}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Mobile number
          </label>
          <div className='col-sm-9'>
            <input
              name='mobileno'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1
                  ? formik.values.mobileno || 'N/A'
                  : formik.values.mobileno
              }
              onChange={formik.handleChange}
              // disabled={companyVerificationMethod === 1}
            />
            {formik.touched.mobileno && formik.errors.mobileno && (
              <div className='text-danger mt-2'>{formik.errors.mobileno}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Email
          </label>
          <div className='col-sm-9'>
            <input
              name='email'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1 ? formik.values.email || 'N/A' : formik.values.email
              }
              onChange={formik.handleChange}
              // disabled={companyVerificationMethod === 1}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='text-danger mt-2'>{formik.errors.email}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Nationality
          </label>
          <div className='col-sm-9'>
            <input
              name='nationality'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1
                  ? formik.values.nationality || 'N/A'
                  : formik.values.nationality
              }
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.nationality && formik.errors.nationality && (
              <div className='text-danger mt-2'>{formik.errors.nationality}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Residential Status
          </label>
          <div className='col-sm-9'>
            <input
              name='residentialstatus'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1
                  ? formik.values.residentialstatus || 'N/A'
                  : formik.values.residentialstatus
              }
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.residentialstatus && formik.errors.residentialstatus && (
              <div className='text-danger mt-2'>{formik.errors.residentialstatus}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Registered Address
          </label>
          <div className='col-sm-9'>
            <input
              name='regadd'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1
                  ? formik.values.regadd || 'N/A'
                  : formik.values.regadd
              }
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.regadd && formik.errors.regadd && (
              <div className='text-danger mt-2'>{formik.errors.regadd}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Owner Private
          </label>
          <div className='col-sm-9'>
            <input
              name='ownerprivate'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1
                  ? formik.values.ownerprivate || 'N/A'
                  : formik.values.ownerprivate
              }
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.ownerprivate && formik.errors.ownerprivate && (
              <div className='text-danger mt-2'>{formik.errors.ownerprivate}</div>
            )}
          </div>
        </div>

        <div className='card'>
          <div className='card-header border-0 px-0'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Notice of Assessment History</span>
            </h3>
          </div>
          <div className='card-toolbar'></div>
          <div className='card-body p-0'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    {formik.values.noahistory.length > 0 &&
                      [...historyHead, ...Object.keys(formik.values.noahistory[0])]
                        .reduce(
                          (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                          []
                        )
                        .map((key: any, i: number) => {
                          let tableHead = ''
                          if (key === 'taxclearance') tableHead = 'tax-clearance'
                          else if (key === 'yearofassessment') tableHead = 'year-of-assessment'
                          else tableHead = key

                          const value = tableHead
                            .replace(/-/g, ' ')
                            .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                          return (
                            <th className='min-w-150px' key={i}>
                              {value}
                            </th>
                          )
                        })}
                  </tr>
                </thead>
                <tbody>
                  {formik.values.noahistory.length > 0 &&
                    Object.values(formik.values.noahistory).map((list: any, i) => {
                      const headList: any = [
                        ...historyHead,
                        ...Object.keys(formik.values.noahistory[0]),
                      ].reduce(
                        (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                        []
                      )
                      return (
                        <tr key={i}>
                          {headList.map((head: any, i: number) => {
                            let value = `${list[head].value}`

                            if (head === 'amount' || head === 'interest' || head === 'employment')
                              value = value
                                .toString()
                                .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')
                            if (head === 'amount' || head === 'interest') value = 'SGD$ ' + value
                            else value = value.toString()
                            return <td key={i}>{value}</td>
                          })}
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <h3 className='mb-10 mt-10'>Corporate Information</h3>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            UEN
          </label>
          <div className='col-sm-9'>
            <input
              name='uen'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1 ? formik.values.uen || 'N/A' : formik.values.uen
              }
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.uen && formik.errors.uen && (
              <div className='text-danger mt-2'>{formik.errors.uen}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Entity Name
          </label>
          <div className='col-sm-9'>
            <input
              name='entityName'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1
                  ? formik.values.entityName || 'N/A'
                  : formik.values.entityName
              }
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.entityName && formik.errors.entityName && (
              <div className='text-danger mt-2'>{formik.errors.entityName}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Entity Type
          </label>
          <div className='col-sm-9'>
            <input
              name='entityType'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1
                  ? formik.values.entityType || 'N/A'
                  : formik.values.entityType
              }
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.entityType && formik.errors.entityType && (
              <div className='text-danger mt-2'>{formik.errors.entityType}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Entity Status
          </label>
          <div className='col-sm-9'>
            <input
              name='entityStatus'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1
                  ? formik.values.entityStatus || 'N/A'
                  : formik.values.entityStatus
              }
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.entityStatus && formik.errors.entityStatus && (
              <div className='text-danger mt-2'>{formik.errors.entityStatus}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Registered Address
          </label>
          <div className='col-sm-9'>
            <input
              name='enregadd'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1
                  ? formik.values.enregadd || 'N/A'
                  : formik.values.enregadd
              }
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.enregadd && formik.errors.enregadd && (
              <div className='text-danger mt-2'>{formik.errors.enregadd}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Registration Date
          </label>
          <div className='col-sm-9'>
            <input
              name='registrationDate'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1
                  ? formik.values.registrationDate || 'N/A'
                  : formik.values.registrationDate
              }
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.registrationDate && formik.errors.registrationDate && (
              <div className='text-danger mt-2'>{formik.errors.registrationDate}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Primary SSIC
          </label>
          <div className='col-sm-9'>
            <input
              name='primarySSIC'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1
                  ? formik.values.primarySSIC || 'N/A'
                  : formik.values.primarySSIC
              }
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.primarySSIC && formik.errors.primarySSIC && (
              <div className='text-danger mt-2'>{formik.errors.primarySSIC}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Secondary SSIC
          </label>
          <div className='col-sm-9'>
            <input
              name='secondarySSIC'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1
                  ? formik.values.secondarySSIC || 'N/A'
                  : formik.values.secondarySSIC
              }
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.secondarySSIC && formik.errors.secondarySSIC && (
              <div className='text-danger mt-2'>{formik.errors.secondarySSIC}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Business Expiry Date
          </label>
          <div className='col-sm-9'>
            <input
              name='businessExpiryDate'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1
                  ? formik.values.businessExpiryDate || 'N/A'
                  : formik.values.businessExpiryDate
              }
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.businessExpiryDate && formik.errors.businessExpiryDate && (
              <div className='text-danger mt-2'>{formik.errors.businessExpiryDate}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Country Of Incorporation
          </label>
          <div className='col-sm-9'>
            <input
              name='countryOfIncorporation'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1
                  ? formik.values.countryOfIncorporation || 'N/A'
                  : formik.values.countryOfIncorporation
              }
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.countryOfIncorporation && formik.errors.countryOfIncorporation && (
              <div className='text-danger mt-2'>{formik.errors.countryOfIncorporation}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Business Constitution
          </label>
          <div className='col-sm-9'>
            <input
              name='businessConstitution'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1
                  ? formik.values.businessConstitution || 'N/A'
                  : formik.values.businessConstitution
              }
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.businessConstitution && formik.errors.businessConstitution && (
              <div className='text-danger mt-2'>{formik.errors.businessConstitution}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Ownership
          </label>
          <div className='col-sm-9'>
            <input
              name='ownership'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1
                  ? formik.values.ownership || 'N/A'
                  : formik.values.ownership
              }
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.ownership && formik.errors.ownership && (
              <div className='text-danger mt-2'>{formik.errors.ownership}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
            Company Type
          </label>
          <div className='col-sm-9'>
            <input
              name='companyType'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={
                companyVerificationMethod === 1
                  ? formik.values.companyType || 'N/A'
                  : formik.values.companyType
              }
              onChange={formik.handleChange}
              disabled={companyVerificationMethod === 1}
            />
            {formik.touched.companyType && formik.errors.companyType && (
              <div className='text-danger mt-2'>{formik.errors.companyType}</div>
            )}
          </div>
        </div>

        {/* Tables */}
        <div className='card'>
          <div className='card-header border-0 px-0'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Financials</span>
            </h3>
          </div>
          <div className='card-toolbar'></div>
          <div className='card-body p-0'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    {formik.values.financials.length > 0 &&
                      [...financialHead, ...Object.keys(formik.values.financials[0])]
                        .reduce(
                          (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                          []
                        )
                        .map((tableHead: any, i: number) => {
                          const value = tableHead
                            .replace(/-/g, ' ')
                            .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                          return (
                            <th className='min-w-150px' key={i}>
                              {value}
                            </th>
                          )
                        })}
                  </tr>
                </thead>
                <tbody>
                  {formik.values.financials.length > 0 &&
                    Object.values(formik.values.financials).map((list: any, i: number) => {
                      const headList: any = [
                        ...financialHead,
                        ...Object.keys(formik.values.financials[0]),
                      ].reduce(
                        (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                        []
                      )
                      return (
                        <tr key={i}>
                          {headList.map((head: any, i: number) => {
                            let value: any
                            if (head === 'currency') value = `${list?.[head]?.desc ?? 'N/A'}`
                            else if (
                              head === 'current-period-end-date' ||
                              head === 'current-period-start-date'
                            )
                              value = `${list?.[head]?.value ?? 'N/A'}`
                            else
                              value = `${
                                list?.[head]?.value
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 'N/A'
                              }`
                            return <td key={i}>{value || 'N/A'}</td>
                          })}
                        </tr>
                      )
                    })}
                </tbody>
              </table>

              {formik.values.financials.length === 0 && (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      {financialHead.map((tableHead: any, i: number) => {
                        const value = tableHead
                          .replace(/-/g, ' ')
                          .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                        return (
                          <th className='min-w-150px' key={i}>
                            {value}
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {financialHead.map((key: string, i: number) => {
                        return (
                          <Fragment key={i}>
                            <td>N/A</td>
                          </Fragment>
                        )
                      })}
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <div className='card'>
          <div className='card-header border-0 px-0'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Corppass Contacts</span>
            </h3>
          </div>
          <div className='card-toolbar'></div>
          <div className='card-body p-0'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    {formik.values.corppassContacts &&
                      formik.values.corppassContacts.length > 0 &&
                      [...corppassHead, ...Object.keys(formik.values.corppassContacts[0])]
                        .reduce(
                          (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                          []
                        )
                        .map((key: any, i: number) => {
                          let tableHead = ''
                          if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                          else tableHead = key
                          const value = tableHead
                            .replace(/-/g, ' ')
                            .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                          return (
                            <th className='min-w-150px' key={i}>
                              {value}
                            </th>
                          )
                        })}
                  </tr>
                </thead>
                <tbody>
                  {formik.values.corppassContacts &&
                    formik.values.corppassContacts.length > 0 &&
                    Object.values(formik.values.corppassContacts).map((list: any, i) => {
                      const headList: any = [
                        ...corppassHead,
                        ...Object.keys(formik.values.corppassContacts[0]),
                      ].reduce(
                        (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                        []
                      )
                      return (
                        <tr key={i}>
                          {headList.map((head: any, i: number) => {
                            let value: any
                            if (head === 'person-reference') {
                              value = (
                                <div>
                                  <div>
                                    <span className='fw-bold'>IdNo: </span>
                                    {list?.[head]?.idno?.value ?? 'N/A'}
                                  </div>
                                  <div>
                                    <span className='fw-bold'>Nationality: </span>
                                    {list?.[head]?.nationality?.desc ?? 'N/A'}
                                  </div>
                                  <div>
                                    <span className='fw-bold'>Person name: </span>
                                    {list?.[head]?.['person-name']?.value ?? 'N/A'}
                                  </div>
                                </div>
                              )
                            } else if (head === 'corppass-email')
                              value = `${list?.[head]?.value ?? 'N/A'}`
                            else if (head === 'corppass-mobileno')
                              value = `${list?.[head]?.value ?? 'N/A'}`
                            return <td key={i}>{value || 'N/A'}</td>
                          })}
                        </tr>
                      )
                    })}
                </tbody>
              </table>

              {formik.values.corppassContacts.length === 0 && (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      {corppassHead.map((key: any, i: number) => {
                        let tableHead = ''
                        if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                        else tableHead = key
                        const value = tableHead
                          .replace(/-/g, ' ')
                          .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                        return (
                          <th className='min-w-150px' key={i}>
                            {value}
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {corppassHead.map((key: string, i: number) => {
                        return (
                          <Fragment key={i}>
                            <td>N/A</td>
                          </Fragment>
                        )
                      })}
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <div className='card'>
          <div className='card-header border-0 px-0'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Capitals</span>
            </h3>
          </div>
          <div className='card-toolbar'></div>
          <div className='card-body p-0'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    {formik.values.capitals &&
                      formik.values.capitals.length > 0 &&
                      [...capitalHead, ...Object.keys(formik.values.capitals[0])]
                        .reduce(
                          (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                          []
                        )
                        .map((tableHead: any, i: number) => {
                          const value = tableHead
                            .replace(/-/g, ' ')
                            .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                          return (
                            <th className='min-w-150px' key={i}>
                              {value}
                            </th>
                          )
                        })}
                  </tr>
                </thead>
                <tbody>
                  {formik.values.capitals &&
                    formik.values.capitals.length > 0 &&
                    Object.values(formik.values.capitals).map((list: any, i) => {
                      const headList: any = [
                        ...capitalHead,
                        ...Object.keys(formik.values.capitals[0]),
                      ].reduce(
                        (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                        []
                      )
                      return (
                        <tr key={i}>
                          {headList.map((head: any, i: number) => {
                            let value: any
                            if (
                              head === 'capital-type' ||
                              head === 'currency' ||
                              head === 'currency'
                            )
                              value = `${list?.[head]?.desc ?? 'N/A'}`
                            else if (
                              head === 'issued-capital-amount' ||
                              head === 'paid-up-capital-amount' ||
                              head === 'share-allotted-amount'
                            )
                              value = `SGD$ ${
                                list?.[head]?.value
                                  .toString()
                                  .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,') ?? 'N/A'
                              }`
                            return <td key={i}>{value || 'N/A'}</td>
                          })}
                        </tr>
                      )
                    })}
                </tbody>
              </table>

              {formik.values.capitals.length === 0 && (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      {capitalHead.map((tableHead: any, i: number) => {
                        const value = tableHead
                          .replace(/-/g, ' ')
                          .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                        return (
                          <th className='min-w-150px' key={i}>
                            {value}
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {capitalHead.map((key: string, i: number) => {
                        return (
                          <Fragment key={i}>
                            <td>N/A</td>
                          </Fragment>
                        )
                      })}
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <div className='card'>
          <div className='card-header border-0 px-0'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Shareholders List</span>
            </h3>
          </div>
          <div className='card-toolbar'></div>
          <div className='card-body p-0'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    {formik.values.shareholdersList &&
                      formik.values.shareholdersList.length > 0 &&
                      [...shareholderHead, ...Object.keys(formik.values.shareholdersList[0])]
                        .reduce(
                          (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                          []
                        )
                        .map((key: any, i: number) => {
                          let tableHead = ''
                          if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                          else tableHead = key
                          const value = tableHead
                            .replace(/-/g, ' ')
                            .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                          return (
                            <th className='min-w-150px' key={i}>
                              {value}
                            </th>
                          )
                        })}
                  </tr>
                </thead>
                <tbody>
                  {formik.values.shareholdersList &&
                    formik.values.shareholdersList.length > 0 &&
                    Object.values(formik.values.shareholdersList).map((list: any, i) => {
                      const headList: any = [
                        ...shareholderHead,
                        ...Object.keys(formik.values.shareholdersList[0]),
                      ].reduce(
                        (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                        []
                      )
                      return (
                        <tr key={i}>
                          {headList.map((head: any, i: number) => {
                            let value: any
                            // if(head === 'person-reference') value = `IdNo: ${list[head].idno.value} Nationality: ${list[head].nationality.desc} Person name: ${list[head]['person-name'].value}`
                            if (head === 'person-reference') {
                              value = (
                                <div>
                                  <div>
                                    <span className='fw-bold'>IdNo: </span>
                                    {list?.[head]?.idno?.value ?? 'N/A'}
                                  </div>
                                  <div>
                                    <span className='fw-bold'>Nationality: </span>
                                    {list?.[head]?.nationality?.desc ?? 'N/A'}
                                  </div>
                                  <div>
                                    <span className='fw-bold'>Person name: </span>
                                    {list?.[head]?.['person-name']?.value ?? 'N/A'}
                                  </div>
                                </div>
                              )
                            } else if (head === 'entity-reference') {
                              value = (
                                <div>
                                  <div>
                                    <span className='fw-bold'>Name: </span>
                                    {list?.[head]?.['entity-name']?.value ?? 'N/A'}
                                  </div>
                                  <div>
                                    <span className='fw-bold'>Type: </span>
                                    {list?.[head]?.['entity-type']?.desc ?? 'N/A'}
                                  </div>
                                  <div>
                                    <span className='fw-bold'>UEN: </span>
                                    {list?.[head]?.uen?.value ?? 'N/A'}
                                  </div>
                                </div>
                              )
                            } else if (
                              head === 'category' ||
                              head === 'currency' ||
                              head === 'share-type'
                            )
                              value = `${list?.[head]?.desc ?? 'N/A'}`
                            else if (head === 'allocation')
                              value = `SGD$  ${list?.[head]?.value
                                .toString()
                                .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')}`
                            else if (head === 'corppass-email' || head === 'corppass-mobileno')
                              value = `${list?.[head]?.value ?? 'N/A'}`
                            return <td key={i}>{value}</td>
                          })}
                        </tr>
                      )
                    })}
                </tbody>
              </table>

              {formik.values.shareholdersList.length === 0 && (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      {shareholderHead.map((key: any, i: number) => {
                        let tableHead = ''
                        if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                        else tableHead = key
                        const value = tableHead
                          .replace(/-/g, ' ')
                          .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                        return (
                          <th className='min-w-150px' key={i}>
                            {value}
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {shareholderHead.map((key: string, i: number) => {
                        return (
                          <Fragment key={i}>
                            <td>N/A</td>
                          </Fragment>
                        )
                      })}
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <div className='card'>
          <div className='card-header border-0 px-0'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Appointments</span>
            </h3>
          </div>
          <div className='card-toolbar'></div>
          <div className='card-body p-0'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    {formik.values.appointments &&
                      formik.values.appointments.length > 0 &&
                      [...appointmentHead, ...Object.keys(formik.values.appointments[0])]
                        .reduce(
                          (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                          []
                        )
                        .map((key: any, i: number) => {
                          let tableHead = ''
                          if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                          else tableHead = key
                          const value = tableHead
                            .replace(/-/g, ' ')
                            .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                          return (
                            <th className='min-w-150px' key={i}>
                              {value}
                            </th>
                          )
                        })}
                  </tr>
                </thead>
                <tbody>
                  {formik.values.appointments &&
                    formik.values.appointments.length > 0 &&
                    Object.values(formik.values.appointments).map((list: any, i) => {
                      const headList: any = [
                        ...appointmentHead,
                        ...Object.keys(formik.values.appointments[0]),
                      ].reduce(
                        (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                        []
                      )

                      return (
                        <tr key={i}>
                          {headList.map((head: any, i: number) => {
                            let value: any
                            // if(head === 'person-reference') value = `IdNo: ${list[head].idno.value} Nationality: ${list[head].nationality.desc} Person name: ${list[head]['person-name'].value}`
                            if (head === 'person-reference') {
                              value = (
                                <div>
                                  <div>
                                    <span className='fw-bold'>IdNo: </span>
                                    {list?.[head]?.idno?.value ?? 'N/A'}
                                  </div>
                                  <div>
                                    <span className='fw-bold'>Nationality: </span>
                                    {list?.[head]?.nationality?.desc ?? 'N/A'}
                                  </div>
                                  <div>
                                    <span className='fw-bold'>Person name: </span>
                                    {list?.[head]?.['person-name']?.value ?? 'N/A'}
                                  </div>
                                </div>
                              )
                            } else if (head === 'position') value = `${list?.[head]?.desc ?? 'N/A'}`
                            else if (head === 'appointment-date')
                              value = `${list?.[head]?.value ?? 'N/A'}`
                            else if (head === 'corppass-email')
                              value = `${list?.[head]?.value ?? 'N/A'}`
                            else if (head === 'category') value = `${list?.[head]?.desc ?? 'N/A'}`
                            else if (head === 'corppass-mobileno')
                              value = `${list?.[head]?.value ?? 'N/A'}`
                            else value = ''
                            return <td key={i}>{value || 'N/A'}</td>
                          })}
                        </tr>
                      )
                    })}
                </tbody>
              </table>

              {formik.values.appointments.length === 0 && (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      {appointmentHead.map((key: any, i: number) => {
                        let tableHead = ''
                        if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                        else tableHead = key
                        const value = tableHead
                          .replace(/-/g, ' ')
                          .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                        return (
                          <th className='min-w-150px' key={i}>
                            {value}
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {appointmentHead.map((key: string, i: number) => {
                        return (
                          <Fragment key={i}>
                            <td>N/A</td>
                          </Fragment>
                        )
                      })}
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <div className='mt-5'>
          <label className='form-check form-check-inline form-check-solid me-5'>
            <input
              name='confirmCheckbox'
              type='checkbox'
              className='form-check-input'
              checked={formik.values.confirmCheckbox}
              onChange={formik.handleChange}
            />
            <span className='fw-bold ps-2 fs-6'>
              By checking this box you confirm that Business Loan Application can fetch your
              business information data from an authorized credit bureau for underwriting purpose. 
            </span>
          </label>
          {/* {formik.touched.confirmCheckbox && formik.errors.confirmCheckbox && ()} */}
          <div className='text-danger mt-2'>{formik.errors.confirmCheckbox}</div>
        </div>

        <div className='d-flex flex-end'>
          <button type='submit' className='btn btn-lg btn-primary me-3'>
            Next
          </button>
        </div>
      </form>

      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}
    </div>
  )
}

export { Step3 }
