import React, {FC} from 'react'
import {Route, Switch} from 'react-router-dom'
import PartnerConnect from './PartnerConnect'
import PartnerConnectType from './PartnerConnectType'
import PartnerConnectComplete from './PartnerConnectComplete'

const LoanApplicationWrapper: FC = () => {
  return (
    <Switch>
      <Route path='/partnerconnect/completed' exact>
        {' '}
        <PartnerConnectComplete />{' '}
      </Route>
      <Route path='/partnerconnect/completed/:id' exact>
        {' '}
        <PartnerConnectComplete />{' '}
      </Route>
      <Route path='/partnerconnect/type' exact>
        {' '}
        <PartnerConnectType />{' '}
      </Route>
      <Route path='/partnerconnect/type/:type' exact>
        {' '}
        <PartnerConnectType />{' '}
      </Route>

      <Route path='/partnerconnect' exact>
        {' '}
        <PartnerConnect />{' '}
      </Route>
      <Route path='/partnerconnect/:partnerId' exact>
        {' '}
        <PartnerConnect />{' '}
      </Route>
      <Route path='/partnerconnect/:partnerId/:id' exact>
        {' '}
        <PartnerConnect />{' '}
      </Route>
    </Switch>
  )
}

export default LoanApplicationWrapper
