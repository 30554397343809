import { LoanApplicationLocalStorageKeys } from '../../../../../utils/constants/LocalStorageKeys.enum'
import {
  SET_PARTNER_APPLICATION,
  SET_PARTNER_APPLICATION_STATUS,
  SET_PARTNER_STEP,
  SET_PARTNER_SUB_STEP,
  SET_COMPANY_VERIFICATION_METHOD,
  SET_ENTITY_DATA,
  SET_PERSON_DATA,
  SET_SHAREHOLDERS,
  SET_PARTNER_ELIGIBILITY,
  SET_GUARANTOR,
  SET_DECISION_MAKER,
  SET_SINGPASS_INFO,
  AUTHORIZE_ACRA_SUCCEEDED,
  FETCH_ENTITY_PERSON_SUCCEEDED,
  RESET_PARTNER,
  SET_DIRECTOR,
  APPLICATION_DETAILS_DELETE,
  APPLICATION_DETAILS_SAVE,
  SET_PARTNER_DETAILS,
} from '../types'

const INITIAL_STATE = {
  partner: null,
  partnerApplication: null,
  partnerApplicationStatus: 1,
  partnerStep: 0,
  partnerSubStep: 1,
  partnerEligibility: null,
  shareholders: null,
  guarantor: null,
  decisionMaker: null,
  directors: null,
  companyVerificationMethod: 1,
  acraToken: '',
  entityPerson: {
    entity: null,
    person: null,
  },
  singPass: null,
  applicationDetails: null,
}

const reducer = (state = INITIAL_STATE, action: any) => {
  /**
   * This was made in order to fetch previous value on page refresh
   */
  const isExistingLoanSubmittionMethodAvailable = localStorage.getItem(
    LoanApplicationLocalStorageKeys.LOAN_APPLICATION_SUBMISSION_METHOD
  )

  if (isExistingLoanSubmittionMethodAvailable) {
    state.partnerApplicationStatus = +isExistingLoanSubmittionMethodAvailable
  }
  switch (action.type) {
    case SET_PARTNER_APPLICATION: {
      const partnerApplication = action.payload
      return {
        ...state,
        partnerApplication,
      }
    }
    case SET_PARTNER_DETAILS: {
      return {
        ...state,
        partner: action.payload,
      }
    }
    case SET_PARTNER_APPLICATION_STATUS: {
      return {
        ...state,
        partnerApplicationStatus: action.status,
      }
    }
    case SET_PARTNER_STEP: {
      return {
        ...state,
        partnerStep: action.step,
      }
    }
    case SET_PARTNER_SUB_STEP: {
      return {
        ...state,
        partnerSubStep: action.step,
      }
    }
    case SET_COMPANY_VERIFICATION_METHOD: {
      return {
        ...state,
        companyVerificationMethod: action.method,
      }
    }
    case SET_ENTITY_DATA: {
      return {
        ...state,
        entityPerson: action.payload,
      }
    }
    case SET_PERSON_DATA: {
      return {
        ...state,
        personData: action.payload,
      }
    }
    case SET_PARTNER_ELIGIBILITY: {
      return {
        ...state,
        partnerEligibility: action.payload,
      }
    }
    case SET_SHAREHOLDERS: {
      return {
        ...state,
        shareholders: action.payload,
      }
    }
    case SET_GUARANTOR: {
      return {
        ...state,
        guarantor: action.payload,
      }
    }
    case SET_DECISION_MAKER: {
      return {
        ...state,
        decisionMaker: action.payload,
      }
    }
    case SET_DIRECTOR: {
      return {
        ...state,
        directors: action.payload,
      }
    }
    case SET_SINGPASS_INFO: {
      return {
        ...state,
        singPass: action.payload,
      }
    }
    case AUTHORIZE_ACRA_SUCCEEDED: {
      const { access_token } = action.payload

      localStorage.setItem('acra_access_token', access_token)

      return {
        ...state,
        acraToken: access_token,
      }
    }
    case FETCH_ENTITY_PERSON_SUCCEEDED: {
      const { payload } = action

      return {
        ...state,
        entityPerson: payload,
      }
    }
    case RESET_PARTNER: {
      return state
    }
    case APPLICATION_DETAILS_DELETE: {
      return {
        ...state,
        applicationDetails: null,
      }
    }
    case APPLICATION_DETAILS_SAVE: {
      const { payload } = action
      return {
        ...state,
        applicationDetails: payload,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
