import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import SgVendorApplication from './SgVendorApplication'
import SgVendorApplicationType from './SgVendorApplicationType'
import SgVendorApplicationComplete from './SgVendorApplicationComplete'
import { AlexiRoutes } from '../../../utils/constants/AlexiRoutes.enum'

const LoanApplicationWrapper: FC = () => {
  return (
    <Switch>
      <Route path={`/${AlexiRoutes.SG_VENDORS_ONBOARDING}/completed`} exact>
        {' '}
        <SgVendorApplicationComplete />{' '}
      </Route>
      <Route path={`/${AlexiRoutes.SG_VENDORS_ONBOARDING}/completed/:id`} exact>
        {' '}
        <SgVendorApplicationComplete />{' '}
      </Route>
      <Route path={`/${AlexiRoutes.SG_VENDORS_ONBOARDING}/type`} exact>
        {' '}
        <SgVendorApplicationType />{' '}
      </Route>
      <Route path={`/${AlexiRoutes.SG_VENDORS_ONBOARDING}/type/:type`} exact>
        {' '}
        <SgVendorApplicationType />{' '}
      </Route>

      <Route path={`/${AlexiRoutes.SG_VENDORS_ONBOARDING}`} exact>
        {' '}
        <SgVendorApplication />{' '}
      </Route>
      <Route path={`/${AlexiRoutes.SG_VENDORS_ONBOARDING}/:partnerId`} exact>
        {' '}
        <SgVendorApplication />{' '}
      </Route>
      <Route path={`/${AlexiRoutes.SG_VENDORS_ONBOARDING}/:partnerId/:id`} exact>
        {' '}
        <SgVendorApplication />{' '}
      </Route>
    </Switch>
  )
}

export default LoanApplicationWrapper
