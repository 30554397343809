import {FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {setPartnerDetails} from './redux/actions'
import axios from 'axios'

const CoApplicantComplete: FC = () => {
  const dispatch = useDispatch()
  const partnerDetails = useSelector((state: any) => state.partnerConnect.partner)
  // const partnerData = JSON.parse(localStorage.getItem("partnerDetails") as string);
  // const [loading, setLoading] = useState(false)

  useEffect(() => {
    // if(partnerDetails == null && partnerData)
    // {
    //   getPartnerDetails(partnerData?.id)
    // }

    window.addEventListener('beforeunload', saveStateToLocalStorage)

    const storeDate = JSON.stringify(localStorage.getItem('storeDate'))
    const dateDiff = getDifferenceInMinutes(new Date(storeDate), new Date())
    if (dateDiff < 3) {
      document.title = 'B2B PayLater - Application'
      const partnerData = JSON.parse(localStorage.getItem('partnerDetails') as string)
      dispatch(setPartnerDetails(partnerData))
      localStorage.removeItem('partnerDetails')
    } else {
      localStorage.removeItem('partnerDetails')
    }
  }, [])

  const saveStateToLocalStorage = () => {
    if (partnerDetails) {
      localStorage.setItem('partnerDetails', JSON.stringify(partnerDetails))
      localStorage.setItem('storeDate', new Date().toString())
    }
  }

  const getDifferenceInMinutes = (date1: any, date2: any) => {
    const diffInMs = Math.abs(date2 - date1)
    return diffInMs / (1000 * 60)
  }

  localStorage.removeItem('coApplicationId')
  localStorage.removeItem('partnerId')
  localStorage.removeItem('singpass')

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid w-100'>
        <div className='d-flex flex-column flex-lg-row-auto w-xl-500px'>
          <div className='d-flex flex-row-fluid flex-column p-20 pt-lg-20'>
            <div className='logo text--center mb-5'>
              <img
                alt='Logo'
                src={toAbsoluteUrl(`${partnerDetails?.logoPath || '/media/logos/logo-cred.png'}`)}
                style={{maxHeight: '100px'}}
              />
            </div>

            <h1 className='title mb-2 text-black'>Congratulations</h1>
            <div className='desc text-bold'>Thank you for verifying your eKYC</div>
            <div className='desc text-bold'>
              Please return to the main journey and complete your submission
            </div>
          </div>
          <div
            className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-150px min-h-lg-300px'
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/alexi/Illustration-14-1.svg')}')`,
            }}
          ></div>
        </div>
        <div className='d-flex flex-column flex-lg-row-fluid py-10'></div>
      </div>
    </div>
  )
}

export default CoApplicantComplete
