/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { Logout, AuthPage } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
// import {RootState} from '../../setup'
import { MasterInit } from '../../_metronic/layout/MasterInit'

import LoanApplicationWrapper from '../modules/loanApplication'
import PartnerConnectWrapper from '../modules/partnerConnect'
import CoApplicantWrapper from '../modules/coApplicant'
import Callback from '../modules/loanApplication/Callback'
import KycOnboardingApplicationWrapper from '../modules/kyc-onboarding'
import SgVendorApplicationWrapper from '../modules/sg-vendors-onboarding'
import { AlexiRoutes } from '../../utils/constants/AlexiRoutes.enum'

const Routes: FC = () => {
  const isAuthorized = true

  const { loading } = useSelector(({ common }: any) => common)
  // const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  return (
    <>
      <Switch>
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*  Otherwise redirect to root page (`/`) */
          <Redirect from='/auth' to='/' />
        )}

        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />

        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to='/auth/login' />
        ) : (
          <>
            <Switch>
              <Route path={`/${AlexiRoutes.CALLBACK}`} component={Callback} />
              <Route path={`/${AlexiRoutes.LOAN_APPLICATION}`} component={LoanApplicationWrapper} />
              <Route
                path={`/${AlexiRoutes.SG_ONBOARDING}`}
                component={KycOnboardingApplicationWrapper}
              />
              <Route
                path={`/${AlexiRoutes.SG_VENDORS_ONBOARDING}`}
                component={SgVendorApplicationWrapper}
              />
              <Route path={`/${AlexiRoutes.PARTNER_CONNECT}`} component={PartnerConnectWrapper} />
              <Route path={`/${AlexiRoutes.CO_APPLICANT}`} component={CoApplicantWrapper} />
              <MasterLayout>
                <PrivateRoutes />
              </MasterLayout>
            </Switch>
            {loading && (
              <div className='full-page-loader text-danger'>
                <div className='d-flex justify-content-center align-items-center h-100'>
                  <div className='spinner-grow' style={{ width: '4rem', height: '4rem' }}>
                    <span className='sr-only'>Loading...</span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Switch>
      <MasterInit />
    </>
  )
}

export { Routes }
