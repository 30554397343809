import { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import LoanApplication from './KycApplication'
import KycComplete from './KycComplete'
import { AlexiRoutes } from '../../../utils/constants/AlexiRoutes.enum'

const KycOnboardingApplicationWrapper: FC = () => {
  return (
    <Switch>
      <Route path={`/${AlexiRoutes.SG_ONBOARDING}/completed`} exact>
        <KycComplete />
      </Route>
      <Route path={`/${AlexiRoutes.SG_ONBOARDING}/completed/:id`} exact>
        <KycComplete />
      </Route>
      <Route path={`/${AlexiRoutes.SG_ONBOARDING}`} exact>
        <LoanApplication />
      </Route>
      <Route path={`/${AlexiRoutes.SG_ONBOARDING}/:id`} exact>
        <LoanApplication />
      </Route>
    </Switch>
  )
}

export default KycOnboardingApplicationWrapper
