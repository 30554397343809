import {
  SET_APPLICATION_COMPLETE,
  SET_CO_APPLICANT,
  SET_OPEN_VERIFICATION,
  SET_SUB_STEP,
  SET_PERSON_DATA,
  SET_COMPANY_VERIFICATION_METHOD,
  SET_PARTNER_DETAILS,
} from '../types'

const INITIAL_STATE = {
  co_applicant: null,
  subStep: 1,
  openVerification: false,
  applicationComplete: false,
  companyVerificationMethod: 1,
  personData: null,
  partner: null,
}

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_SUB_STEP: {
      const subStep = action.step
      return {
        ...state,
        subStep,
      }
    }
    case SET_CO_APPLICANT: {
      return {
        ...state,
        co_applicant: action.co_applicant,
      }
    }
    case SET_PARTNER_DETAILS: {
      return {
        ...state,
        partner: action.payload,
      }
    }
    case SET_OPEN_VERIFICATION: {
      return {
        ...state,
        openVerification: action.open,
      }
    }
    case SET_APPLICATION_COMPLETE: {
      return {
        ...state,
        applicationComplete: action.complete,
      }
    }
    case SET_COMPANY_VERIFICATION_METHOD: {
      return {
        ...state,
        companyVerificationMethod: action.method,
      }
    }
    case SET_PERSON_DATA: {
      return {
        ...state,
        personData: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
