import { Spinner } from 'react-bootstrap-v5'

export function FallbackView() {
  return (
    <div className='splash-screen'>
      <Spinner style={{
        height: "5.35rem",
        width: "5.35rem"
      }} animation="grow" variant="danger" />
    </div>
  )
}
