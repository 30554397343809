
const ENV = {
  SANDBOX: 'https://sandbox.api.myinfo.gov.sg',
  TEST: 'https://test.api.myinfo.gov.sg'
}

export const myInfoConfig = {
  CLIENT_ID: '',
  CLIENT_SECRET: '',
  CLIENT_SECURE_CERT: '',
  CLIENT_SECURE_CERT_PASSPHRASE: '',
  CALLBACK_URL: '',
  SCOPES: 'uinfin,name,sex,race,nationality,dob,email,mobileno,regadd,housingtype,hdbtype,marital,edulevel,noa-basic,ownerprivate,cpfcontributions,cpfbalances',
  API_AUTHORISE: {
    SANDBOX: `${ENV.SANDBOX}/com/v3/authorise`,
    TEST: `${ENV.TEST}/com/v3/authorise`
  },
  API_TOKEN: {
    SANDBOX: `${ENV.SANDBOX}/com/v3/authorise`,
    TEST: `${ENV.TEST}/com/v3/authorise`
  },
  API_PERSON: {
    SANDBOX: `${ENV.SANDBOX}/biz/v2/entity-person-sample`,
    TEST: `${ENV.TEST}/biz/v2/entity-person-sample`
  }
}

export const acraCofig = {
  CLIENT_ID: '9EBF4DA1E3A5FAF77F972CC283F6BC49E6C5B43870FCBB56D200276AE54A3F04',
  CLIENT_NAME: 'deeplinq',
  CLIENT_SECRET: 'rXGxdd89',
  AUTH_URL: 'https://www.apimall.acra.gov.sg/authorizeServer/oauth/token?grant_type=client_credentials',
  TOKEN_VERIFY_URL: 'https://www.apimall.acra.gov.sg/authorizeServer/oauth/check_token',

}

