import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Modal } from 'react-bootstrap-v5'
import axios from 'axios'
//const type = parse(history.location.search).type as string;

import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { setCompanyVerificationMethod, setEntityData, setKycStep } from './redux/actions'
import { setEntityDataPartner } from '../partnerConnect/redux/actions'

interface Props {
  show: boolean
  handleClose: any
  // goToStep:any;
}

const CompanyVerification: FC<Props> = ({
  show,
  handleClose,
}: // goToStep
any) => {
  const dispatch = useDispatch()
  const history: any = useHistory()
  const loanApplicationId = localStorage.getItem('loanApplicationId')
  const loanApplication = useSelector((state: any) => state.kycApplication.loanApplication)
  const companyVerificationMethod = useSelector(
    (state: any) => state.kycApplication.companyVerificationMethod
  )

  const selectCompanyVerificationMethod = (method: number) => {
    dispatch(setCompanyVerificationMethod(method))
  }

  const connectMyInfo = async () => {
    localStorage.setItem('loanApplicationId', loanApplication?.id)
    localStorage.setItem('singpass', 'myinfobiz')
    // const singPassUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3002' : 'https://iris-dev.credilinq.ai/api/alexi-singpass';
    const singPassUrl = process.env.REACT_APP_SINGPASS_URL || 'http://localhost:3002'

    const getEnv = await axios.get(singPassUrl + '/getEnv').then((response) => response.data)

    const state = Math.floor(Math.random() * 100000)
    const {
      clientId,
      redirectUrl,
      attributes,
      purpose,
      environment,
      authApiUrl,
      myInfoAuthApiUrl,
      myInfoBizAuthApiUrl,
    } = getEnv
    const authoriseUrl: any = `${authApiUrl}?client_id=${clientId}&attributes=${attributes}&purpose=${purpose}&state=${encodeURIComponent(
      state
    )}&redirect_uri=${redirectUrl}`
    ;(window as any).location = authoriseUrl
  }

  const submitProfileVerification = () => {
    if (companyVerificationMethod === 1) {
      connectMyInfo()
    } else {
      // goToStep(3);
      dispatch(setKycStep(2))
      if (loanApplicationId && ['stage', 'sandbox', 'dev'].includes(localStorage.getItem('env') as string)) {

        (async () => {
          const response = await import(`../../../utils/custom/202101103R/step3.json`);
          const eligibilityData: any = response.default;
          console.log(eligibilityData)
          if (eligibilityData) {
            // Dispatch the action to update the loan eligibility in the Redux store
            dispatch(setEntityDataPartner(eligibilityData))
          }
        })()


      }
      // history.replace(`/application/${loanApplication.id}?step=3`)
    }
    handleClose()
  }

  return (
    <>
      <div className=''>
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
          <div>
            <h3 className='fw-bold m-0'>Verify Company Profile</h3>
            <div className='text-muted mt-2'>
              Please select how you would like to verify your company profile
            </div>
          </div>
          {/* <div className='d-flex ms-2'>
            <div
              className='btn btn-icon btn-sm btn-light-primary ms-2'
              data-bs-dismiss='modal'
              onClick={handleClose}
            >
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
          </div> */}
        </div>

        <div className='d-block mx-5 my-10'>
          <div className='d-flex align-items-center justify-content-center px-8 min-h-145px'>
            <div className='fv-row fv-plugins-icon-container'>
              <div className='row'>
                <div className='col-lg-12 loan-application'>
                  <label
                    className={`btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center mb-10 ${
                      companyVerificationMethod === 1 ? 'selected' : ''
                    }`}
                    htmlFor='kt_create_account_form_account_type_personal'
                    onClick={() => selectCompanyVerificationMethod(1)}
                  >
                    {/* <KTSVG
                        path='/media/icons/duotune/communication/com005.svg'
                        className='svg-icon-3x me-5'
                      /> */}

                    <div className='d-block fw-bold text-start'>
                      {/* <span className='text-dark fw-bolder d-block fs-4 mb-2'>MyInfo Business with Singpass</span> */}
                      <img src={toAbsoluteUrl('/media/alexi/singpass-inline.svg')} alt='Singpass' />
                      <div className='text-gray-400 fw-bold fs-6 mt-3'>
                        Takes about 1-2 minutes{' '}
                      </div>
                    </div>
                  </label>
                </div>

                <div className='col-lg-12 loan-application'>
                  <label
                    className={`btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center ${
                      companyVerificationMethod === 2 ? 'selected' : ''
                    }`}
                    htmlFor='kt_create_account_form_account_type_corporate'
                    onClick={() => selectCompanyVerificationMethod(2)}
                  >
                    <KTSVG
                      path='/media/icons/duotune/finance/fin006.svg'
                      className='svg-icon-3x me-5'
                    />

                    <span className='d-block fw-bold text-start'>
                      <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                        Fill out details Manually
                      </span>
                      <span className='text-gray-400 fw-bold fs-6'>Takes about 5-10 minutes</span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex flex-center mt-10'>
            <button
              className='btn btn-lg btn-primary me-3'
              onClick={() => submitProfileVerification()}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanyVerification
