export const SET_PARTNER_APPLICATION = "SET_PARTNER_APPLICATION";
export const SET_PARTNER_APPLICATION_STATUS = "SET_PARTNER_APPLICATION_STATUS";
export const SET_PARTNER_STEP = "SET_PARTNER_STEP";
export const SET_PARTNER_SUB_STEP = "SET_PARTNER_SUB_STEP";
export const SET_COMPANY_VERIFICATION_METHOD = "SET_COMPANY_VERIFICATION_METHOD";
export const SET_ENTITY_DATA = "SET_ENTITY_DATA";
export const SET_PERSON_DATA = "SET_PERSON_DATA";
export const SET_PARTNER_ELIGIBILITY = "SET_PARTNER_ELIGIBILITY";
export const SET_SHAREHOLDERS = "SET_SHAREHOLDERS";
export const SET_GUARANTOR = "SET_GUARANTOR";
export const SET_DIRECTOR = "SET_DIRECTOR";
export const SET_DECISION_MAKER = "SET_DECISION_MAKER";
export const SET_SINGPASS_INFO = "SET_SINGPASS_INFO";
export const RESET_PARTNER = "RESET_PARTNER";

export const AUTHORIZE_ACRA = "AUTHORIZE_ACRA";
export const AUTHORIZE_ACRA_SUCCEEDED = "AUTHORIZE_ACRA_SUCCEEDED";

export const FETCH_ENTITY_PERSON = "FETCH_ENTITY_PERSON";
export const FETCH_ENTITY_PERSON_SUCCEEDED = "FETCH_ENTITY_PERSON_SUCCEEDED";

export const APPLICATION_DETAILS_SAVE = "APPLICATION_DETAILS_SAVE";
export const APPLICATION_DETAILS_DELETE = "APPLICATION_DETAILS_DELETE";
export const SET_PARTNER_DETAILS = "SET_PARTNER_DETAILS";