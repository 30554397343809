import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'

import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { setPartnerApplication, setPartnerDetails } from './redux/actions'
import { useLazyQuery } from '@apollo/client'
import query from '../../../setup/graphql/query'
import { useParams } from 'react-router-dom'
import { fetchStart, fetchStop } from '../../../redux/actions/common'
import { getIntegrationURLFromENV } from '../../../utils/getIntegrationURLFromENV'
import axios from 'axios'
import Alert from '../../../_metronic/partials/alert'
import { LoanApplicationLocalStorageKeys } from '../../../utils/constants/LocalStorageKeys.enum'

const PartnerConnectComplete: FC = () => {
  const dispatch = useDispatch()
  const [getApplication, onSuccessGetApplication] = useLazyQuery(query.GET_APPLICATION)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const loanApplication = useSelector((state: any) => state.sgVendorsApplication.partnerApplication)
  const params: any = useParams()
  const { id } = params
  const partnerDetails = useSelector((state: any) => state.sgVendorsApplication.partner)

  useEffect(() => {
    if (onSuccessGetApplication.called && !onSuccessGetApplication.loading) {
      dispatch(fetchStop())
      if (onSuccessGetApplication.data) {
        const loanApplication = onSuccessGetApplication.data.getLatestApplication
        if (loanApplication !== null) {
          dispatch(setPartnerApplication(loanApplication))

          if (loanApplication?.partnerId) {
            getPartnerDetails(loanApplication?.partnerId, loanApplication?.credilinqEnvironment)
          }
          localStorage.removeItem('loanApplicattionId')
          localStorage.removeItem('perfiosId')
          localStorage.removeItem('coApplicationId')
          localStorage.removeItem('accountDetails')
          localStorage.removeItem('env')
          localStorage.removeItem('uploadedFiles')
          localStorage.removeItem('loanType')
          localStorage.removeItem('singpass')
          localStorage.removeItem('partnerApplicationType')
          localStorage.removeItem('partnerId')
          localStorage.removeItem(
            LoanApplicationLocalStorageKeys.LOAN_APPLICATION_SUBMISSION_METHOD
          )
        }
        if (onSuccessGetApplication.error) {
          setShowAlert(true)
          setAlertMsg('error')
          setAlertType('primary')
          dispatch(fetchStop())
        }
      }
    }
  }, [onSuccessGetApplication.loading])

  const getPartnerDetails = async (partnerId: string, env: string = 'stage') => {
    const API_URL = getIntegrationURLFromENV(env)

    const partnerData = await axios.get(`${API_URL}ext/api/businesspartner/${partnerId || ''}`)

    if (partnerData.data && partnerData.status === 200) {
      dispatch(setPartnerDetails(partnerData.data))
    }
  }

  useEffect(() => {
    const loanApplicationId = localStorage.getItem('loanApplicationId')
    if (id) {
      if (loanApplication === null) {
        dispatch(fetchStart())
        getApplication({
          variables: {
            appId: id || loanApplicationId,
          },
        })
      }
    } else {
      localStorage.removeItem('loanApplicattionId')
      localStorage.removeItem('perfiosId')
      localStorage.removeItem('coApplicationId')
      localStorage.removeItem('accountDetails')
      localStorage.removeItem('env')
      localStorage.removeItem('uploadedFiles')
      localStorage.removeItem('loanType')
      localStorage.removeItem('singpass')
      localStorage.removeItem('partnerApplicationType')
      localStorage.removeItem('partnerId')
      localStorage.removeItem(LoanApplicationLocalStorageKeys.LOAN_APPLICATION_SUBMISSION_METHOD)
    }
  }, [id])

  useEffect(() => {
    ReactPixel.track('Completed Registration')

    window.addEventListener('beforeunload', saveStateToLocalStorage)
    const storeDate = JSON.stringify(localStorage.getItem('storeDate'))
    const dateDiff = getDifferenceInMinutes(new Date(storeDate), new Date())
    if (dateDiff < 3) {
      const partnerData = JSON.parse(localStorage.getItem('partnerDetails') as string)
      dispatch(setPartnerDetails(partnerData))

      localStorage.removeItem('partnerDetails')

      const partnerApplicationData = JSON.parse(
        localStorage.getItem('partnerApplicationDetails') as string
      )
      dispatch(setPartnerApplication(partnerApplicationData))

      localStorage.removeItem('partnerApplicationDetails')
    } else {
      localStorage.removeItem('partnerDetails')
      localStorage.removeItem('partnerApplicationDetails')
    }
  }, [])

  const saveStateToLocalStorage = () => {
    if (partnerDetails && loanApplication) {
      localStorage.setItem('partnerDetails', JSON.stringify(partnerDetails))
      localStorage.setItem('partnerApplicationDetails', JSON.stringify(loanApplication))
      localStorage.setItem('storeDate', new Date().toString())
    }
  }

  const getDifferenceInMinutes = (date1: any, date2: any) => {
    const diffInMs = Math.abs(date2 - date1)
    return diffInMs / (1000 * 60)
  }

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid w-100'>
        <div className='d-flex flex-column flex-lg-row-auto w-xl-800px'>
          <div className='d-flex flex-row-fluid flex-column p-20 pt-lg-20'>
            <div className='logo text--center mb-5'>
              <img
                alt='Logo'
                src={toAbsoluteUrl(`${partnerDetails?.logoPath || '/media/logos/logo-cred.png'}`)}
                style={{ maxHeight: '100px' }}
              />
            </div>

            <h1 className='title mb-2 text-black'>Congratulations</h1>
            <div className='desc text-bold'>
              {/* Your Loan Application has been submitted successfully and our team will get back to you with the updated status in 48 hours */}
              <div className='fs-5 mt-3'>You Credit Application is successfully submitted.</div>
              <div className='fs-5 mt-3'>
                You will be contacted on your registered mobile number and email address if our
                analysts will be looking for some clarification.
              </div>
              <div className='fs-5 mt-3'>
                A copy of this application will be sent to you shortly on your email address. We
                request you to sign the application form and email us back.
              </div>
              <div className='fs-5 mt-3'>Thank you for trusting us for your financial needs.</div>
            </div>
          </div>
          <div
            className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-150px min-h-lg-300px'
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/alexi/Illustration-14-1.svg')}')`,
            }}
          ></div>
        </div>
        <div className='d-flex flex-column flex-lg-row-fluid py-10'>
          <div className='d-flex flex-center flex-column flex-column-fluid'>
            <div className='p-10 p-lg-15 mx-auto' style={{ minWidth: '540px' }}>
              <h1 className='text-center mb-10'>Application Details</h1>

              <h6 className='font-weight-bolder mb-3'>Loan Application Number</h6>
              <div className='text-dark-50 bg-gray-200 p-4 mb-10'>
                {loanApplication ? loanApplication.applicationNumber : 'xxxxxxxxxxxxxxxxx'}
              </div>

              <h6 className='font-weight-bolder mb-3'>Applicant Name</h6>
              <div className='text-dark-50 bg-gray-200 p-4 mb-10'>
                {loanApplication ? loanApplication.applicantName : 'xxxxxxxxxxxxxxxxx'}
              </div>

              <h6 className='font-weight-bolder mb-3'>Applicant Mobile Number</h6>
              <div className='text-dark-50 bg-gray-200 p-4 mb-10'>
                {loanApplication ? loanApplication.mobileNumber : 'xxxxxxxxxxxxxxxxx'}
              </div>

              <div>
                You can talk to us by dialing +65 8332 7436 or emailing us at{' '}
                <a href='mailto::loans@credilinq.ai'>loans@credilinq.ai</a> and use above
                information as reference
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}
    </div>
  )
}

export default PartnerConnectComplete
