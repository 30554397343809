import React, {FunctionComponent, useMemo} from 'react'
import {Stack} from 'react-bootstrap'
import {useDropzone} from 'react-dropzone'

interface UploadDropZoneProps {
  onDrop: (acceptedFiles: Array<File>) => void
  disabled?: boolean
}
// * This component is using react-dropzone which helps in drag and drop files it sets a state with list of added files object
export const UploadDropZone: FunctionComponent<UploadDropZoneProps> = ({
  onDrop,
  disabled = false,
}) => {
  const {getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject} =
    useDropzone({
      onDrop,
      accept: {'application/pdf': []},
    })

  const baseStyle = {
    px: 3,
    py: 5,
    borderRadius: 1,
    border: '1px dashed #0000001e',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    pointerEvents: 'auto',
  }

  // border:'1px dashed #190355', padding: '40px 15px', background:"rgba(26,3,85,0.02)", minHeight:'100px', marginBottom:'15px'

  return (
    <div
      {...getRootProps(baseStyle)}
      style={{
        border: '1px dashed #190355',
        padding: '63px 40px',
        background: 'rgba(26,3,85,0.02)',
        minHeight: '150px',
        fontSize: '14px',
        marginBottom: '15px',
        borderRadius: '10px',
      }}
    >
      <input {...getInputProps()} />

      <Stack
        style={{
          alignItems: 'cetner',
          justifyContent: 'center',
          color: disabled ? 'grey.400' : 'primary.main',
        }}
        gap={1}
      >
        <div style={{cursor: 'pointer'}}>
          {}
          <div
            style={{
              display: 'inline',
              borderBottom: '1px solid',
              borderBottomColor: disabled ? 'grey.400' : 'primary.main',
              fontSize: '14px',
            }}
          >
            Click to upload
          </div>{' '}
          or drag and drop Bank Statements
        </div>
      </Stack>
    </div>
  )
}
