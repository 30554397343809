// import {format, subMonths} from 'date-fns'
import dayjs from 'dayjs'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const linkStyle = {
  color: '#601A79',
  marginLeft: '10px',
  textDecoration: 'none',
}

interface FileUploadInfoProps {
  validateMonthsTill?: number
}

const FileUploadInfo: FunctionComponent<FileUploadInfoProps> = ({ validateMonthsTill = 6 }) => {
  const [endMonth, setEndMonth] = useState('')
  const [startMonth, setStartMonth] = useState('')
  useEffect(() => {
    setEndMonth(dayjs().subtract(1, 'month').format('MMM YY'))
    setStartMonth(dayjs().subtract(validateMonthsTill, 'month').format('MMM YY'))
  }, [])

  return (
    <ul>
      <li>
        <span>
          PDFs (not scanned copies) of company&apos;s operating bank current account(s) statements
          for the past <b>{validateMonthsTill}</b> months.
          <br /> Example: If today is <b>{dayjs().format('DD MMM YYYY')}</b>, then please upload
          bank statements from <b>{startMonth}</b> to <b>{endMonth}</b> (both months inclusive)
        </span>
      </li>
      <li>
        If your company is multi-banked, then please upload <b>{validateMonthsTill}</b> months bank
        statements for each bank account
      </li>
      <li>
        If your file is password protected, we request you to remove the password and upload the
        file to avoid submission failure
      </li>
      <li>
        <li>
          In case if you are facing any issue while uploading bank statements, Please contact us on
          <Link
            to='mailto:loans@credilinq.ai'
            style={{
              color: '#601A79',
              marginLeft: '10px',
              textDecoration: 'none',
              margin: 0,
            }}
          >
            &nbsp;loans@credilinq.ai
          </Link>
        </li>
      </li>
    </ul>
  )
}

export default FileUploadInfo
