import { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useMutation, useLazyQuery } from '@apollo/client'
import PhoneInput from 'react-phone-number-input'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { fetchStart, fetchStop, fetchStartWithForward } from '../../../../redux/actions/common'
import mutations from '../../../../setup/graphql/mutations'
import query from '../../../../setup/graphql/query'
import Alert from '../../../../_metronic/partials/alert'
import {
  setShareholders,
  setDecisionMaker,
  setKycStep,
  setSingPassInfo,
  setKycApplication,
  setDirector,
} from '../redux/actions'
import { on } from 'events'
import { AlexiRoutes } from '../../../../utils/constants/AlexiRoutes.enum'

const errorMsg = {
  required: 'Required',
}

const Step3Sub1: FC<any> = ({ updateApplication }) => {
  const dispatch = useDispatch()
  const history: any = useHistory()

  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const [decisionMakerList, setDecisionMakerList] = useState<any>([])
  const [directorList, setDirectorList] = useState<any>([])
  // const [information, setInformation] = useState<any>(null);
  const [shareholderLists, setShareholderLists] = useState<any[]>([])

  const [getShareholders, onSuccessGetShareholders] = useLazyQuery(query.GET_SHAREHOLDERS)
  const [createExternalDataStore, onSuccessCreateExternalDataStore] = useMutation(
    mutations.CREATE_EXTERNAL_DATA_STORE
  )
  const [updateShareholderKycStatus, onSuccessUpdateShareholderKycStatus] = useMutation(
    mutations.UPDATE_SHAREHOLDER_KYC_STATUS
  )

  const companyVerificationMethod = useSelector(
    (state: any) => state.kycApplication.companyVerificationMethod
  )
  const { person, entity }: any = useSelector((state: any) => state.kycApplication.entityPerson)
  const loanApplication = useSelector((state: any) => state.kycApplication.loanApplication)
  const shareholders = useSelector((state: any) => state.kycApplication.shareholders)

  useEffect(() => {
    if (
      onSuccessUpdateShareholderKycStatus.called &&
      !onSuccessUpdateShareholderKycStatus.loading
    ) {
      dispatch(fetchStop())
      if (onSuccessUpdateShareholderKycStatus.data) {
        if (onSuccessUpdateShareholderKycStatus.data) {
          dispatch(setKycStep(4))
          dispatch(fetchStop())
          const { updateShareholderKycStatuses } = onSuccessUpdateShareholderKycStatus.data
          const id = updateShareholderKycStatuses[0].id
          const url = `${window.location.origin}/co-applicant/${id}`
          history.push(`/${AlexiRoutes.SG_ONBOARDING}/${loanApplication.id}?step=5`)
        }
      } else {
      }

      if (onSuccessUpdateShareholderKycStatus.error) {
        setShowAlert(true)
        setAlertMsg('error')
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [
    onSuccessUpdateShareholderKycStatus.data,
    onSuccessUpdateShareholderKycStatus.loading,
    onSuccessUpdateShareholderKycStatus.error,
  ])

  useEffect(() => {
    if (loanApplication.shareholderKycStatus.length > 0) {
      const _shareholders = [...loanApplication.shareholderKycStatus].sort(
        ({ allocation: a }: any, { allocation: b }: any) => b - a
      )
      setDecisionMakerList(
        _shareholders.filter((e: any) => e.isDecisionMaker).map((e: any) => e.id)
      )
      setDirectorList(_shareholders.filter((e: any) => e.isDirector).map((e: any) => e.id))
      var shareholders = []
      if (
        _shareholders.some(
          (e: any) =>
            (e.isGurantor || e.isDirector || e.isDecisionMaker) &&
            !e.corppasEmail &&
            !e.corppasMobileNumber
        )
      ) {
        let count = 0
        shareholders = _shareholders.reduce((a: any, b: any) => {
          if (b.isDirector) count++
          if (count > 2 && b.isDirector) {
            return [...a, { ...b, isDirector: false }]
          }
          return [...a, b]
        }, [])
      } else {
        shareholders = _shareholders
      }
      setShareholderLists(shareholders)
      dispatch(setShareholders(shareholders))
    } else {
      dispatch(fetchStart())
      getShareholders({
        variables: {
          uen: loanApplication.companyUEN || loanApplication.mibCompanyUEN || '',
          app: loanApplication.id || '',
        },
      })
    }
  }, [loanApplication.shareholderKycStatus])

  useEffect(() => {
    if (onSuccessGetShareholders.called && !onSuccessGetShareholders.loading) {
      dispatch(fetchStop())
      if (onSuccessGetShareholders.data) {
        const _shareholders = onSuccessGetShareholders.data.unwrapShareholders
        setDirectorList(_shareholders.filter((e: any) => e.isDirector).map((e: any) => e.id))

        setDecisionMakerList(
          _shareholders.filter((e: any) => e.isDecisionMaker).map((e: any) => e.id)
        )
        var shareholders = []
        if (
          _shareholders.some(
            (e: any) =>
              (e.isGurantor || e.isDirector || e.isDecisionMaker) &&
              !e.corppasEmail &&
              !e.corppasMobileNumber
          )
        ) {
          let count = 0
          shareholders = _shareholders.reduce((a: any, b: any) => {
            if (b.isDirector) count++
            if (count > 2 && b.isDirector) {
              return [...a, { ...b, isDirector: false }]
            }
            return [...a, b]
          }, [])
        } else {
          shareholders = _shareholders
        }
        setShareholderLists(shareholders)
        dispatch(fetchStop())
        dispatch(setShareholders(shareholders))
        // onSuccessGetShareholders.reset();
      } else {
      }

      if (onSuccessGetShareholders.error) {
        setShowAlert(true)
        setAlertMsg('error')
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [onSuccessGetShareholders.loading])

  const initialValues: any = {
    shareholders: shareholderLists || [],
  }

  Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a: any) => a) {
    return this.test('unique', message, function (list: any) {
      return list.length === new Set(list.map(mapper)).size
    })
  })

  const validationSchema = Yup.object().shape({
    shareholders: Yup.array().of(
      Yup.object().shape({
        isDecisionMaker: Yup.boolean(),
        corppasEmail: Yup.string()
          .email('Invalid Email')
          .when('isDecisionMaker', {
            is: (isDecisionMaker: boolean) => isDecisionMaker === true,
            then: Yup.string().required('Email is required'),
          })
          .when('isDirector', {
            is: (isDirector: boolean) => isDirector === true,
            then: Yup.string().required('Email is required'),
          }),
        corppasMobileNumber: Yup.string()
          .when('isDecisionMaker', {
            is: (isDecisionMaker: boolean) => isDecisionMaker === true,
            then: Yup.string()
              .required('Mobile number is required')
              .test(
                'singaporemobileValidation',
                'Only singapore number supported',
                (value: any) => {
                  return /\+65\d{8}/g.test(value)
                }
              ),
            /* .test('mobileValidation', 'Invalid Mobile number', (value: any) => {
              if (/^\+65(8|9)\d{7}$/g.test(value) || /^\+977\d{10}$/g.test(value)) {
                return true
              }
              return false
            }) */
          })
          .when('isDirector', {
            is: (isDirector: boolean) => isDirector === true,
            then: Yup.string().required('Mobile number is required'),
          }),
      })
    ),
  })

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any, { setStatus, setSubmitting }: any) => {
      const { shareholders } = values

      dispatch(fetchStartWithForward())
      let gurantors = []
      const _decisionMaker = shareholders.filter((o: any) => o.isDecisionMaker)[0]

      if (_decisionMaker) {
        const _directors = shareholders.filter((o: any) => o.isDirector)
        const list = shareholders.filter((o: any) => o.isDirector || o.isDecisionMaker)
        var phoneNumberList = list.map((x: any) => x.corppasMobileNumber)
        var emailList = list.map((x: any) => x.corppasEmail)
        if (
          phoneNumberList.length !== Array.from(new Set(phoneNumberList)).length ||
          emailList.length !== Array.from(new Set(emailList)).length
        ) {
          dispatch(fetchStop())
          setShowAlert(true)
          setAlertMsg('Mobile numbers and email addresses for shareholders must be unique!')
          setAlertType('primary')
          return
        } else if (_directors.length < Math.min(directorList.length, 2)) {
          dispatch(fetchStop())
          setShowAlert(true)
          setAlertMsg(
            `There must be at least ${Math.min(
              Math.max(directorList.length, 1),
              2
            )} Directors who needs to fill eKYC form!`
          )
          setAlertType('primary')
          return
        } else if (_directors.length > 2) {
          dispatch(fetchStop())
          setShowAlert(true)
          setAlertMsg(`Only 2 directors can be selected!`)
          setAlertType('primary')
          return
        }
        const shareholdersToBeUpdated = shareholders.filter(
          (o: any) => o.isCompanyDirector || _decisionMaker.id == o.id
        )
        gurantors = shareholdersToBeUpdated.reduce((acc: any, curr: any) => {
          acc.push({
            data: {
              isDecisionMaker: curr.isDecisionMaker,
              isDirector: curr.isDirector,
              isGuarantor: curr.isGuarantor,
              corppasEmail: curr.corppasEmail,
              corppasMobileNumber: curr.corppasMobileNumber,
            },
            where: { id: curr.id },
          })
          return acc
        }, [])
        // if (!gurantors.some((o: any) => o.data.isDecisionMaker)) {
        //   gurantors.push({
        //     "data": {
        //       "isDecisionMaker": true,
        //       "corppasEmail": _decisionMaker.corppasEmail,
        //       "corppasMobileNumber": _decisionMaker.corppasMobileNumber,
        //     },
        //     "where": { "id": _decisionMaker.id }
        //   })
        // }
        const _loanApplication = { ...loanApplication, shareholderKycStatus: shareholders }
        dispatch(setKycApplication(_loanApplication))

        dispatch(setDecisionMaker(_decisionMaker))
        dispatch(setDirector(_directors))

        updateShareholderKycStatus({
          variables: {
            data: gurantors,
          },
        })

        if (loanApplication.applicationCompleteStep < 4) {
          updateApplication({
            variables: {
              data: { applicationCompleteStep: 4 },
              where: { id: loanApplication.id },
            },
          })
        }

        // goToStep(4);
        setSubmitting(false)
      } else {
        dispatch(fetchStop())
        setShowAlert(true)
        setAlertMsg('Please Select a Decision Maker')
        setAlertType('warning')
      }
    },
  })

  // Note: because of this scrollbar is scrolled constantly at top
  // useEffect(() => {
  //   if (formik.errors.hasOwnProperty('shareholders') && formik.errors.shareholders.length > 0) {
  //     window.scrollTo(0, 0)
  //   }
  // }, [formik])
  return (
    <div className='d-flex flex-column flex-column-fluid w-100'>
      <h2>Shareholders</h2>
      <div className='sub-heading text-muted mb-10'>
        The following details have been provided to us by credit bureau
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className='card'>
          {/* <div className='card-header border-0 px-0'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'> Shareholders</span>
            </h3>
          </div> */}
          <div className='card-toolbar'></div>
          <div className='card-body p-0'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='min-w-150px'>Details</th>
                    <th className='min-w-140px'>Allocation</th>
                    <th className='min-w-120px'>
                      Decision Maker
                      {!formik.values.shareholders.some((o: any) => o.isDecisionMaker) && (
                        <div className='text-danger mt-2'>Decision Maker is required</div>
                      )}
                    </th>
                    <th className='min-w-120px'>Director</th>
                    <th className='min-w-120px'>Contact Details</th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.shareholders.length > 0 &&
                    formik.values.shareholders.map((o: any, i: number) => {
                      return (
                        <tr key={i}>
                          <td>
                            <div>
                              <p>
                                <b>ID:</b> {o.idNumber}
                              </p>
                              <p>
                                <b>Name:</b> {o.personName}
                              </p>
                              <p>
                                <b>Nationality:</b> {o.nationality}
                              </p>
                            </div>
                          </td>
                          <td>{o.allocation.toFixed(2)}%</td>
                          <td>
                            <input
                              name={`shareholders[${i}].isDecisionMaker`}
                              type='checkbox'
                              className='form-check-input custom-checkbox'
                              checked={o.isDecisionMaker}
                              onChange={(e: any) => {
                                for (const [
                                  index,
                                  shareholder,
                                ] of formik.values.shareholders.entries()) {
                                  if (shareholder.isDecisionMaker) {
                                    formik.setFieldValue(
                                      `shareholders[${index}].isDecisionMaker`,
                                      false
                                    )
                                  }

                                  if (shareholder.isDecisionMaker && !shareholder.isDirector) {
                                    formik.setFieldValue(`shareholders[${index}].corppasEmail`, '')
                                    formik.setFieldValue(
                                      `shareholders[${index}].corppasMobileNumber`,
                                      ''
                                    )
                                  }
                                }

                                formik.setFieldValue(
                                  `shareholders[${i}].isDecisionMaker`,
                                  e.target.checked
                                )
                              }}
                              // disabled={
                              //   formik.values.shareholders.some((o: any) => o.isDecisionMaker) &&
                              //   formik.values.shareholders.find((o: any) => o.isDecisionMaker).idNumber !== o.idNumber
                              // }
                            />
                          </td>
                          <td>
                            {o.isCompanyDirector && (
                              <input
                                name={`shareholders[${i}].isDirector`}
                                type='checkbox'
                                className='form-check-input custom-checkbox'
                                defaultChecked={o.isDirector}
                                onChange={(e: any) => {
                                  formik.setFieldValue(
                                    `shareholders[${i}].isDirector`,
                                    e.target.checked
                                  )
                                  if (
                                    !e.target.checked &&
                                    !formik.values.shareholders[i].isDecisionMaker
                                  ) {
                                    formik.setFieldValue(`shareholders[${i}].corppasEmail`, '')
                                    formik.setFieldValue(
                                      `shareholders[${i}].corppasMobileNumber`,
                                      ''
                                    )
                                  }
                                }}
                                disabled={
                                  shareholders.filter(
                                    (shareholder: any) => shareholder.isCompanyDirector
                                  ).length < 3
                                }
                                readOnly
                              />
                            )}
                          </td>
                          <td>
                            {(o.isDecisionMaker || o.isDirector) && (
                              <>
                                <input
                                  name={`shareholders[${i}].corppasEmail`}
                                  type='text'
                                  placeholder='Email'
                                  className='form-control form-control-lg form-control-solid mb-5'
                                  value={formik.values.shareholders[i].corppasEmail}
                                  onChange={(e: any) =>
                                    formik.setFieldValue(
                                      `shareholders[${i}].corppasEmail`,
                                      e.target.value
                                    )
                                  }
                                />
                                {Object.keys(formik.touched).includes('shareholders') &&
                                  Object.keys(formik.errors).includes('shareholders') && (
                                    <>
                                      {(formik.touched.shareholders as any)[i] &&
                                        (formik.errors.shareholders as any)[i] &&
                                        (formik.touched.shareholders as any)[i].corppasEmail &&
                                        (formik.errors.shareholders as any)[i].corppasEmail && (
                                          <div className='text-danger mt-2'>
                                            {(formik.errors.shareholders as any)[i].corppasEmail}
                                          </div>
                                        )}
                                    </>
                                  )}
                                <PhoneInput
                                  name={`shareholders[${i}].corppasMobileNumber`}
                                  international
                                  defaultCountry='SG'
                                  // countryCallingCodeEditable={false}
                                  value={formik.values.shareholders[i].corppasMobileNumber}
                                  onChange={(value) =>
                                    formik.setFieldValue(
                                      `shareholders[${i}].corppasMobileNumber`,
                                      value
                                    )
                                  }
                                  placeholder='Enter phone number'
                                  className='int-phone-input'
                                />
                                {Object.keys(formik.touched).includes('shareholders') &&
                                  Object.keys(formik.errors).includes('shareholders') && (
                                    <>
                                      {(formik.touched.shareholders as any)[i] &&
                                        (formik.errors.shareholders as any)[i] &&
                                        (formik.touched.shareholders as any)[i]
                                          .corppasMobileNumber &&
                                        (formik.errors.shareholders as any)[i]
                                          .corppasMobileNumber && (
                                          <div className='text-danger mt-2'>
                                            {
                                              (formik.errors.shareholders as any)[i]
                                                .corppasMobileNumber
                                            }
                                          </div>
                                        )}
                                    </>
                                  )}
                              </>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='d-flex flex-end'>
          <button type='submit' className='btn btn-lg btn-primary me-3'>
            Next
          </button>
        </div>
      </form>

      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}
    </div>
  )
}

export { Step3Sub1 }
