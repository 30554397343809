import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { parse } from 'query-string'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

// import {StepperComponent} from '../../../_metronic/assets/ts/components'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import CoApplicantComplete from './CoApplicantComplete'

import { Step1 } from './steps'
import { setPartnerDetails, setSubStep } from './redux/actions'
import axios from 'axios'
import { Spinner } from 'react-bootstrap-v5'
import { getIntegrationURLFromENV, handleSettingEnv } from '../../../utils/getIntegrationURLFromENV'

const progressPercentageCalculate = (currentStep: number, applicationComplete: boolean) => {
  if (applicationComplete) return 100
  switch (currentStep) {
    case 1:
      return 0
    case 2:
      return 33.33
    case 3:
      return 66.67
    default:
      return 0
  }
}

const CoApplicant: FC<any> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params: any = useParams()

  const { id } = params
  // const id = history.location.pathname.split('/')[2];
  const searchQuery: any = parse(history.location.search)

  const subStep = useSelector((state: any) => state.coApplicant.subStep)
  const applicationComplete = useSelector((state: any) => state.coApplicant.applicationComplete)
  const currentStep = useSelector((state: any) => state.coApplicant.subStep)
  const progress = progressPercentageCalculate(currentStep, applicationComplete)
  const [loading, setLoading] = useState(false)
  const partnerDetails = useSelector((state: any) => state.partnerConnect.partner)
  const partnerId = localStorage.getItem('partnerId')
  useEffect(() => {
    /* if(Object.keys(searchQuery).length > 0) {
      const subStep = Number(searchQuery.subStep);
      dispatch(setSubStep(subStep))
      history.push(`/co-applicant/${id}?subStep=${subStep}`);
    } else {
      dispatch(setSubStep(1));
      history.push(`/co-applicant/${id}`);
    } */
    const singpass = localStorage.getItem('singpass')

    if (singpass !== null && singpass.length > 0 && singpass === 'myinfo') {
      const subStep = Number(searchQuery.subStep)
      dispatch(setSubStep(subStep))
      history.push(`/co-applicant/${id}?subStep=${subStep}`)
    } else {
      dispatch(setSubStep(1))
      history.push(`/co-applicant/${id}`)
    }

    if (searchQuery?.partner || partnerId) {
      document.title = 'B2B PayLater - Application'
      getPartnerDetails(searchQuery?.partner || partnerId)
    }

    handleSettingEnv(searchQuery?.env)
  }, [])

  const getPartnerDetails = async (partnerId: string) => {
    setLoading(true)

    const env = localStorage.getItem('env')
    const API_URL = getIntegrationURLFromENV(searchQuery?.env || env)

    const partnerData = await axios.get(`${API_URL}ext/api/businesspartner/${partnerId || ''}`)

    if (partnerData.data && partnerData.status === 200) {
      localStorage.setItem('partnerId', partnerData.data?.id)
      dispatch(setPartnerDetails(partnerData.data))
      setLoading(false)
    }
  }

  return (
    <>
      {loading ? (
        <div
          style={{ height: '100vh' }}
          className='d-flex justify-content-center align-items-center'
        >
          <Spinner
            style={{
              height: '5.35rem',
              width: '5.35rem',
            }}
            animation='grow'
            variant='danger'
          />
        </div>
      ) : (
        <div className='d-flex flex-column flex-root stepper stepper-pills stepper-column'>
          <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
            <div
              className='d-flex flex-column flex-lg-row-auto w-xl-400px stepper-bg'
              style={{
                background: `url(${toAbsoluteUrl(
                  '/media/patterns/background-vertical.png'
                )}) no-repeat center center / cover`,
              }}
            >
              <div className='d-flex flex-row-fluid flex-column p-20 pt-lg-10'>
                <div className='logo text-center mb-5'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl(
                      `${partnerDetails?.logoPath || '/media/logos/logo-white.png'}`
                    )}
                    style={{ maxHeight: '100px', maxWidth: '250px' }}
                  />
                  <h1 className='text-white'>{partnerDetails?.company}</h1>
                </div>

                <div className='stepper-nav mb-20'>
                  <div className='stepper-item c-urrent' data-kt-stepper-element='nav'>
                    <div className='stepper-line w-40px'></div>

                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>1</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Co-Applicant eKYC</h3>

                      {/* <div className='stepper-desc fw-bold'>Setup Your Account Details</div> */}
                    </div>
                  </div>
                </div>

                <div className='logo text-center'>
                  <div className='card shadow-sm'>
                    <div className='card-body circular-progressbar-card'>
                      <h5 className='text-start mb-5'>
                        {partnerDetails ? 'Journey Progress' : 'Application Progress'}
                      </h5>
                      <div className='d-flex justify-content-center align-items-center mb-5'>
                        <div className='progress-container'>
                          <CircularProgressbar
                            value={progress}
                            text={`${progress}%`}
                            className='circular-progress-bar'
                            counterClockwise={true}
                          />
                        </div>
                      </div>
                      <p className='progress-bar-text mb-10'>
                        {partnerDetails
                          ? 'You are making a great progress, there are hardly few steps left.'
                          : 'Business Loan Application is tracking your progress and you can resume at any time'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className='d-flex flex-column flex-lg-row-fluid pb-10 px-20'
              style={{ paddingTop: '140px' }}
            >
              <div className='d-flex flex--center flex-column flex-column-fluid'>
                <div className='current' data-kt-stepper-element='content'>
                  {subStep < 4 && <Step1 />}
                  {subStep === 4 && <CoApplicantComplete />}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CoApplicant
