import { 
  FETCH_START,
  FETCH_STOP,
  FETCH_SUCCESS,
  FETCH_START_WITH_FORWARD
} from "../types/common";


const INITIAL_STATE = {
  loading: false,
  error: false,
  forwardWithButton: false
}

const reducer = (state = INITIAL_STATE, action:any) => {
  switch(action.type) {
    case FETCH_START: {
      return {
        ...state,
        loading: true,
        forwardWithButton: false
      }
    }
    case FETCH_START_WITH_FORWARD: {
      return {
        ...state,
        loading: true,
        forwardWithButton: true
      }
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case FETCH_STOP: {
      return {
        ...state,
        loading: false,
        forwardWithButton: false
      }
    }
    default: {
      return state;
    }
  }
}

export default reducer;