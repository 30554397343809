import React, {FC} from 'react'
import {Route, Switch} from 'react-router-dom'
import LoanApplication from './LoanApplication'
import LoanType from './LoanType'
import LoanComplete from './LoanComplete'

const LoanApplicationWrapper: FC = () => {
  return (
    <Switch>
      <Route path='/application/completed' exact>
        {' '}
        <LoanComplete />{' '}
      </Route>
      <Route path='/application/completed/:id' exact>
        {' '}
        <LoanComplete />{' '}
      </Route>
      <Route path='/application/type' exact>
        {' '}
        <LoanType />{' '}
      </Route>
      <Route path='/application/type/:type' exact>
        {' '}
        <LoanType />{' '}
      </Route>
      <Route path='/application' exact>
        {' '}
        <LoanApplication />{' '}
      </Route>
      <Route path='/application/:id' exact>
        {' '}
        <LoanApplication />{' '}
      </Route>
    </Switch>
  )
}

export default LoanApplicationWrapper
