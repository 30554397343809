import axios from 'axios';
import { takeLatest, put, call } from "redux-saga/effects";
import { fetchStart, fetchSuccess } from "../../../../../redux/actions/common";
import { myInfoConfig } from "../../config";
import { 
  fetchEntityPersonSucceeded,
  authorizeAcraSucceeded
 } from "../actions";

const entityPersonApiUrl = myInfoConfig.API_PERSON.SANDBOX


function authorizeAacraApi(payload:any) {
  const { username, password } = payload
  const url = `https://www.apimall.acra.gov.sg/authorizeServer/oauth/token?grant_type=client_credentials`;
  return axios.post(url, {}, {
    auth: {
      username,
      password
    }
  }).then((response) => {
    console.log(response)
  })
}

function fetchEntityPersonApi(payload:any) {
  const { uen, uinfin } = payload;
  const url = `${entityPersonApiUrl}/${uen}/${uinfin}`;
  return axios.get(url, {
    proxy: {
      host: 'localhost',
      port: 3011
    }
  }).then((response) => {
    // console.log(response.data)
    return response.data
  })
}

export function* authorizeAcra({payload}:any):any {
  yield put(fetchStart());
  try {
    const response:any  = yield call(authorizeAacraApi, payload);
    
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(authorizeAcraSucceeded(response))
    } else {
      // const { message } = response.error;
      // yield put(fetchError());
    }
  } catch(error) {
    // yield put(fetchError())
  }
}

export function* fetchEntityPerson({payload}:any):any {
  yield put(fetchStart());
  try {
    const response:any  = yield call(fetchEntityPersonApi, payload);
    
    if(!response.error) {
      yield put(fetchSuccess());
      yield put(fetchEntityPersonSucceeded(response))
    } else {
      // const { message } = response.error;
      // yield put(fetchError());
    }
  } catch(error) {
    // yield put(fetchError())
  }
}


export default [
  takeLatest('AUTHORIZE_ACRA', authorizeAcra),
  takeLatest('FETCH_ENTITY_PERSON', fetchEntityPerson),
];