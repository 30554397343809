import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../../app/modules/auth'
import Common from '../../redux/reducers/common'
import Loan from '../../app/modules/loanApplication/redux/reducers'
import KycApplication from '../../app/modules/kyc-onboarding/redux/reducers'
import sgVendorsApplication from '../../app/modules/sg-vendors-onboarding/redux/reducers'
import partnerConnect from '../../app/modules/partnerConnect/redux/reducers'
import CoApplicant from '../../app/modules/coApplicant/redux/reducers'
import LoanSagas from '../../app/modules/loanApplication/redux/sagas'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  common: Common,
  loan: Loan,
  partnerConnect: partnerConnect,
  kycApplication: KycApplication,
  sgVendorsApplication: sgVendorsApplication,
  coApplicant: CoApplicant,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga(), ...LoanSagas])
}
