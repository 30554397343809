import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Accordion } from 'react-bootstrap'

import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { setPartnerApplicationStatus, setPartnerDetails } from './redux/actions'
import { parse } from 'query-string'
import axios from 'axios'
import { Spinner } from 'react-bootstrap-v5'
import { getIntegrationURLFromENV, handleSettingEnv } from '../../../utils/getIntegrationURLFromENV'
import { MaintenanceModeBanner } from '../../../_metronic/helpers/components/MaintenanceModeBanner'
import { AlexiApplicationTypes } from '../../../utils/constants/AlexiApplicationType.enum'
import { AlexiRoutes } from '../../../utils/constants/AlexiRoutes.enum'
import { LoanApplicationLocalStorageKeys } from '../../../utils/constants/LocalStorageKeys.enum'

const PartnerConnectType: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  let { type } = useParams<{ type: string }>()
  const searchQuery: any = parse(history.location.search)
  const partnerDetails = useSelector((state: any) => state.sgVendorsApplication.partner)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleSettingEnv(searchQuery?.env)

    document.title = 'B2B PayNow'
    if (partnerDetails == null) {
      getPartnerDetails(searchQuery?.partner)
    }
  }, [])

  const getPartnerDetails = async (customerId: string) => {
    setLoading(true)

    const env = localStorage.getItem('env')

    const API_URL = getIntegrationURLFromENV(searchQuery?.env || env)

    const partnerData = await axios.get(`${API_URL}ext/api/businesspartner/${customerId || ''}`)

    if (partnerData.data && partnerData.status === 200) {
      localStorage.setItem('partnerId', partnerData.data?.id)
      dispatch(setPartnerDetails(partnerData.data))
      setLoading(false)
    }
  }

  // const loanApplicationId = localStorage.getItem('loanApplicationId');
  if (type && type.toLowerCase() === AlexiApplicationTypes.RECURRING_APPLICATION)
    localStorage.setItem('partnerApplicationType', AlexiApplicationTypes.RECURRING_APPLICATION)
  else localStorage.setItem('partnerApplicationType', '')
  localStorage.removeItem('loanApplicattionId')

  const status = useSelector((state: any) => state.sgVendorsApplication.partnerApplicationStatus)

  const selectLoanApplication = (status: number) => {
    /**
     * This was made in order to fetch previous value on page refresh
     * This is deciding factor in incrementing steps in further journey
     * 1 -> New Application
     * 2 -> Resume Application
     */
    localStorage.setItem(
      LoanApplicationLocalStorageKeys.LOAN_APPLICATION_SUBMISSION_METHOD,
      JSON.stringify(status)
    )
    dispatch(setPartnerApplicationStatus(status))
  }

  const goToApplicationSteps = () => {
    const step = status === 1 ? '1.1' : '1.0'
    // history.push(`/application?step=${step}`)
    history.push(`/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerDetails?.id}`)
  }

  return (
    <>
      {loading ? (
        <div
          style={{ height: '100vh' }}
          className='d-flex justify-content-center align-items-center'
        >
          <Spinner
            style={{
              height: '5.35rem',
              width: '5.35rem',
            }}
            animation='grow'
            variant='danger'
          />
        </div>
      ) : (
        <div className='d-flex flex-column flex-root'>
          <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
            <div
              className='d-flex flex-column flex-lg-row-auto w-xl-750px'
              style={{
                background: '#FAFAFA',
              }}
            >
              <div className='d-flex flex-column p-20 pb-10 pt-lg-20'>
                <div>
                  <div className='logo text--center mb-5'>
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl(partnerDetails?.logoPath || '/media/logos/logo-cred.png')}
                      style={{ maxHeight: '100px' }}
                    />
                  </div>

                  <div className='h2'>
                    Get a credit upto SGD 1,000,000 in less than 2 business days and enhance your
                    working capital
                  </div>
                </div>
              </div>
              <div
                style={{
                  background: '#F4F4F4',
                  padding: '40px 60px 0 60px',
                }}
              >
                <div className='row-1'>
                  <p className='plc-info-header'>Credit Line Eligibility</p>
                  <ul className='plc-info-content'>
                    <li>
                      Registered as a Vendor with an ongoing contract with the Singapore Government
                    </li>
                    <li>
                      Minimum 2 years of business operation with annual turnover greater than SG$
                      1,000,000
                    </li>
                  </ul>
                </div>
              </div>
              <div
                style={{
                  background: '#F4F4F4',
                  padding: '15px 60px 0 60px',
                }}
              >
                <div className='row-2'>
                  <p className='plc-info-header'>
                    Documentation Required (
                    <a href='https://www.credilinq.ai/sg-vendors' target='_blank' rel='noreferrer'>
                      Click Here for Details
                    </a>
                    )
                  </p>
                  <ul className='plc-info-content'>
                    <li>Bank Statements (Last 6 Months)</li>
                    <li>Approved Government Contracts</li>
                    <li>GST Statements (Optional)</li>
                  </ul>
                </div>
              </div>
              <div style={{ background: '#F4F4F4', padding: '15px 60px 20px 60px' }}>
                <div className='row-3'>
                  <p className='plc-info-header'>KYB/C Verification</p>
                  <ul className='plc-info-content'>
                    <li>Applicant should preferably have access to Myinfo business/Corppass</li>
                    <li>The directors and key appointees must have access to singpass</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className='d-flex flex-column flex-lg-row-fluid py-10'>
              <div className='d-flex flex-center flex-column flex-column-fluid'>
                <div className='w-lg-700px p-10 p-lg-15 mx-auto'>
                  <div className='w-100'>
                    <div className='pb-10 pb-lg-15'>
                      <h2 className='fw-bolder d-flex align-items-center text-dark mb-10'>
                        On behalf of my company, I would like to:
                        {/* <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Billing is issued based on your selected account type" aria-label="Billing is issued based on your selected account type"></i> */}
                      </h2>

                      {/* <div className="text-muted fw-bold fs-6 pb-lg-15">
            If you need more info, please check out <Link to="" className="link-primary fw-bolder">FAQ Page</Link>
          </div> */}

                      <div className='fv-row fv-plugins-icon-container'>
                        <div className='row'>
                          <div className='col-lg-12 loan-application'>
                            <label
                              className={`btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10 ${
                                status === 1 ? 'selected' : ''
                              }`}
                              htmlFor='kt_create_account_form_account_type_personal'
                              onClick={() => selectLoanApplication(1)}
                            >
                              {/* <KTSVG
                    path='/media/icons/duotune/communication/com005.svg'
                    className='svg-icon-3x me-5'
                  /> */}
                              <i
                                className='fa fa-user me-3 text-gray-400'
                                style={{ fontSize: '30px' }}
                              ></i>

                              <span className='d-block fw-bold text-start'>
                                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                                  Apply for new Credit Line
                                </span>
                                <span className='text-gray-400 fw-bold fs-6'>
                                  Apply for a new credit line in less than 10 minutes
                                </span>
                              </span>
                            </label>
                          </div>

                          <div className='col-lg-12 loan-application'>
                            <label
                              className={`btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center ${
                                status === 2 ? 'selected' : ''
                              }`}
                              htmlFor='kt_create_account_form_account_type_corporate'
                              onClick={() => selectLoanApplication(2)}
                            >
                              {/* <KTSVG path='/media/icons/duotune/finance/fin006.svg' className='svg-icon-3x me-5' /> */}
                              <i
                                className='fa fa-th-large me-3 text-gray-400'
                                style={{ fontSize: '30px' }}
                              ></i>

                              <span className='d-block fw-bold text-start'>
                                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                                  Resume my Journey
                                </span>
                                <span className='text-gray-400 fw-bold fs-6'>
                                  Complete your application to get your credit line
                                </span>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <MaintenanceModeBanner />
                    </div>

                    <div className='d-flex flex-stack pt-15'>
                      <div></div>
                      <button
                        type='submit'
                        className='btn btn-lg btn-primary me-3'
                        onClick={() => goToApplicationSteps()}
                      >
                        <span className='indicator-label'>Next</span>
                      </button>
                    </div>
                  </div>
                  {/* <div
                    className='mt-5'
                    style={{
                      padding: '20px',
                      marginTop: '100px !important',
                      background: '#FCFCFC',
                      boxShadow:
                        'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                    }}
                  >
                    <div className='d-flex flex-column'>
                      <h4> Everything you need to know</h4>
                      <div className='sub-heading mb-5'>
                        Please have below information and documents ready before initiating the
                        requirement journey
                      </div>
                      <div className='d-flex flex-row'>
                        <div
                          style={{
                            maxWidth: '50%',
                            flex: '0 0 50%',
                            paddingRight: '8px',
                          }}
                        >
                          <div
                            style={{
                              padding: '20px',
                              height: '100%',
                              background: '#ffffff',
                              boxShadow:
                                'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.15) 0px 4px 6px -2px',
                            }}
                          >
                            <h4 style={{marginBottom: '15px'}}>Eligibility</h4>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <img
                                alt='Logo'
                                className='h-35px logo mb-3'
                                src={toAbsoluteUrl('/media/svg/shapes/eligibility.svg')}
                              />
                              <h3>2yrs</h3>
                              <div className='sub-heading'>Minimum years in Business</div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            maxWidth: '50%',
                            flex: '0 0 50%',
                            paddingLeft: '8px',
                          }}
                        >
                          <div
                            style={{
                              padding: '20px',
                              height: '100%',
                              background: '#ffffff',
                              boxShadow:
                                'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                            }}
                          >
                            <h4 style={{marginBottom: '15px'}}>Documents</h4>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                              }}
                            >
                              <img
                                alt='Logo'
                                className='h-35px logo mb-3'
                                src={toAbsoluteUrl('/media/svg/shapes/documents.svg')}
                              />
                              <h3 style={{minWidth: '80px'}}>6 months</h3>
                              <div className='sub-heading'>Bank Statements of last 6 months</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          padding: '20px',
                          background: '#ffffff',
                          marginTop: '16px',
                          boxShadow:
                            'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.15) 0px 4px 6px -2px',
                        }}
                      >
                        <h4>Access Required</h4>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px',
                          }}
                        >
                          <img
                            alt='Logo'
                            className='h-35px logo'
                            width='124px'
                            src={toAbsoluteUrl('/media/svg/shapes/myinfo-biz-logo.svg')}
                          />
                          <div className='sub-heading'>Corppass/Myinfo business</div>
                          <img
                            alt='Logo'
                            className='h-35px logo'
                            width='124px'
                            style={{maxWidth: '80px'}}
                            src={toAbsoluteUrl('/media/svg/shapes/singpass-logo.svg')}
                          />
                          <div className='sub-heading'>Myinfo business</div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PartnerConnectType
