import React, { FunctionComponent, useEffect, useRef, useState } from 'react'

import axios from 'axios'
import { Stack } from 'react-bootstrap'
import type { UploadedBankStmtRes, Banks } from '../types/upload-file-response'
import {
  ArrowRight,
  XCircleFill,
  FileEarmarkArrowUpFill,
  ArrowClockwise,
} from 'react-bootstrap-icons'
import dayjs from 'dayjs'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import {
  getIntegrationURLFromENV,
  getIntegrationURLFromENVForPLD,
} from '../../../utils/getIntegrationURLFromENV'
import { AlexiApplicationTypes } from '../../../utils/constants/AlexiApplicationType.enum'
import { sendErrorLog } from '../utils/perfiosTransaction'
import { ErrorLogUploadType } from '../utils/constants/ErrorLogUploadType.enum'

interface UploadBankStatementProps {
  file: File & { key: string }
  validateMonthsTill?: number 
  onClose: () => void
  handleUploadedFileRes: (_: UploadedBankStmtRes) => void
  refId?: string
  refType?: string
  password?: string
  error?: string
  handleFailedFiles: (file: any, status: any, error: any, res: UploadedBankStmtRes | null) => void
}

const UploadBankStatement: FunctionComponent<UploadBankStatementProps> = ({
  file,
  onClose,
  handleUploadedFileRes,
  handleFailedFiles,
  refId,
  refType,
  password,
  error,
  validateMonthsTill = 6,
}) => {
  const [uploadStatus, setUploadStatus] = useState<'error' | 'loading' | 'success'>(
    error ? 'error' : 'loading'
  )
  const [errorMsg, setErrorMsg] = useState(error)
  const [skipDetails, setSkipDetails] = useState(false)
  const shouldRun = useRef(true)
  useEffect(() => {
    // setUploadStatus(error ? "error" : "loading");

    if (shouldRun.current) {
      shouldRun.current = false
      if (Object.keys(file).length) {
        setTimeout(async () => {
          await uploadFile(file)
        }, 1000)
      }
    }
  }, [file])

  const uploadFile = async (files: File) => {
    if (error) return

    const token = window.localStorage.getItem('perfiosId') as string
    const transactionId = JSON.parse(token)
    let fileUploadResponse = null

    let formData = new FormData()
    formData.append('file', files)
    formData.append('refId', `${refId}`)
    formData.append('validateMonthsTill',`${validateMonthsTill}`)
    formData.append(
      's3Path',
      `${refId}/${dayjs().format('DD-MM-YYYY')}/${files.name.replace(
        /(?:\.(?![^.]+$)|[^\w.])+/g,
        '_'
      )}`
    )
    formData.append('perfiosTransactionId', transactionId?.perfiosTransactionId)
    if (password) {
      formData.append('password', password)
    }

    const env = localStorage.getItem('env') as string
    const API_URL = getIntegrationURLFromENV(env)
    const API_URL_PLD = getIntegrationURLFromENVForPLD()

    let INTEGRATION_ENDPOINT =
      refType === AlexiApplicationTypes.PARTNER_CONNECT_APPLICATION ? API_URL : API_URL_PLD

    try {
      const res = await axios.post<any>(
        INTEGRATION_ENDPOINT + 'ext/api/upload-process/statements',
        formData
      )
      if (res.data) {
        const uploadedFiles = JSON.parse(
          window.localStorage.getItem('uploadedFiles') as string
        )
        window.localStorage.setItem('uploadedFiles', (uploadedFiles + 1).toString())
        setUploadStatus('success')
        // setFileResponseStatus(prev => [...prev, { fileDetails: file, status: 400, error: "internal server error" }]);

        handleUploadedFileRes(res.data)
        /* 
        * made the upload and process common in above step below code will be unused....
        */
      
        // const payload = {
        //   perfiosTransactionId: transactionId.perfiosTransactionId,
        //   files: [res.data?.Location],
        //   validateMonthsTill: validateMonthsTill,
        // }
        // setData((prev: any) => {
        //   console.log(prev,109)
        //   return [...prev, payload]
        // })

        // const ProcessRes = await axios.post<UploadedBankStmtRes>(
        //   INTEGRATION_ENDPOINT + 'ext/api/process/statements',
        //   payload
        // )
        // if (ProcessRes.data) {
        //   const uploadedFiles = JSON.parse(
        //     window.localStorage.getItem('uploadedFiles') as string
        //   )
        //   window.localStorage.setItem('uploadedFiles', (uploadedFiles + 1).toString())
        //   setUploadStatus('success')
        //   // setFileResponseStatus(prev => [...prev, { fileDetails: file, status: 400, error: "internal server error" }]);

        //   handleUploadedFileRes(ProcessRes.data)
        // }
      }
    } catch (error: any) {
      setUploadStatus('error')
      setSkipDetails(true)
      handleFailedFiles(file, 400, error?.response?.data?.message, fileUploadResponse)
      if (error?.response?.data === undefined) {
        setErrorMsg('Please upload failed bank statement again')
      } else if (
        error?.response?.data?.message === undefined ||
        error?.response?.data?.message.toLowerCase().includes('internal server error') ||
        error?.response?.data?.message.toLowerCase().includes('internal error')
      ) {
        const accountDetails = JSON.parse(
          window.localStorage.getItem('accountDetails') as string
        ) as Banks[]
        const completedAccounts = accountDetails.filter((ac: Banks) => ac.completed == true)
        if (accountDetails.length == completedAccounts.length) {
          setUploadStatus('success')
          setErrorMsg('')
        } else {
          // setFileResponseStatus(prev => [...prev, file]);
          setErrorMsg(
            `Please try reuploading this statement. If issue persists, reach out at ${`loans@credilinq.ai`}`
          )
        }
      } else if (
        error?.response?.data?.message
          .toLowerCase()
          .includes('an active transaction corresponding to this transaction id') ||
        error?.response?.data?.message.toLowerCase().includes('transaction id')
      ) {
        setErrorMsg(
          `Please try reuploading this statement. If issue persists, reach out at ${`loans@credilinq.ai`}`
        )
      } else {
        setErrorMsg(error?.response?.data?.message)
      }

      await sendErrorLog(
        (file as any)?.path,
        refId as string,
        refType as string,
        error,
        ErrorLogUploadType.STATEMENT_UPLOAD
      )
    }
  }
  const colors: { [key: string]: any } = {
    error: '#dc3545',
    success: '#198754',
  }
  function getColor() {
    if (['error', 'success'].includes(uploadStatus)) {
      return colors[uploadStatus]
    }
    return 'text.disabled'
  }

  const getIcon = () => {
    if (uploadStatus === 'error') {
      return <ArrowClockwise className='text-danger' size={20} />
    }
    if (uploadStatus === 'loading') {
      // return <FileEarmarkArrowUpFill size={20} />
      return (
        <img src={toAbsoluteUrl('/media/loaders/loader.gif')} alt='img' width={20} height={20} />
      )
    }
    return <span></span>
  }

  const getCancelIcon = () => {
    if (errorMsg) {
      return <XCircleFill onClick={onClose} style={{ cursor: 'pointer' }} size={20} />
    } else {
      return <span></span>
    }
  }
  return !skipDetails ? (
    <>
      <Stack
        style={{
          width: 'auto',
          display: 'inline-block',
          marginBottom: '10px',
          marginRight: '10px',
          verticalAlign: 'top',
          // flexDirection: 'row',
          // gap: '15px',
          // justifyContent: 'flex-start',
        }}
      >
        <Stack
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            color: getColor(),
            border: '1px solid',
            borderColor: getColor(),
            borderRadius: 50,
            padding: '9px 15px',
            fontSize: '12px',
            minWidth: '100%',
            width: 'auto',
            display: 'inline-flex',
          }}
        >
          {getIcon()}
          <div
            style={{
              fontSize: 14,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '16rem',
            }}
          >
            {file?.name}
          </div>
          {getCancelIcon()}
        </Stack>
        {uploadStatus === 'loading' && (
          <div style={{ fontSize: 10, color: 'text.disabled' }}>
            <span style={{ fontSize: 14 }}>
              {/* Please wait while we are processing your statement. It will not take much time !!! */}
              Please wait while your statements are being uploaded.
            </span>
          </div>
        )}
        {errorMsg && (
          <div className='text-danger' style={{ fontSize: 14 }}>
            <span style={{ fontSize: 14 }}>*</span>
            {errorMsg}
          </div>
        )}
      </Stack>
    </>
  ) : null
}

export default UploadBankStatement
