import axios from 'axios'
import dayjs from 'dayjs'
import { AlexiApplicationTypes } from '../../../utils/constants/AlexiApplicationType.enum'
import {
  getIntegrationURLFromENV,
  getIntegrationURLFromENVForPLD,
} from '../../../utils/getIntegrationURLFromENV'
import { GeneratePerfiosReport } from '../types/generate-report-response'
import { PerfiosTransaction } from '../types/perfiosTransaction'
import { ErrorLogUploadType } from './constants/ErrorLogUploadType.enum'

function urlInit() {
  let env = localStorage.getItem('env') as string
  let API_URL = getIntegrationURLFromENV(env)
  let API_URL_PLD = getIntegrationURLFromENVForPLD()

  return { API_URL, API_URL_PLD }
}

export async function initiatePerfiosTransaction(
  refId: string,
  applicationType: string,
  validateMonthsTill = 6
) {
  const { API_URL, API_URL_PLD } = urlInit()
  let INTEGRATION_ENDPOINT =
    applicationType === AlexiApplicationTypes.PARTNER_CONNECT_APPLICATION ? API_URL : API_URL_PLD

  const yearMonthTo = dayjs().subtract(1, 'month').endOf('month')
  const yearMonthFrom = yearMonthTo
    .clone()
    .subtract(validateMonthsTill - 1, 'months')
    .startOf('month')

  const payload = {
    yearMonthFrom: yearMonthFrom.format('YYYY-MM'),
    yearMonthTo: yearMonthTo.format('YYYY-MM'),
    acceptancePolicy: 'atLeastOneTransactionInRange', // added to avoid error
  }

  const response = await axios.post(INTEGRATION_ENDPOINT + 'perfios/initiateTransaction', payload)
  if (response.data) {
    const oldToken = window.localStorage.getItem('perfiosId')
    if (oldToken) {
      window.localStorage.removeItem('perfiosId')
    }
    const token = {
      id: refId,
      perfiosTransactionId: response.data.perfiosTransactionId,
    }
    await window.localStorage.setItem('perfiosId', JSON.stringify(token))
  }
}

export async function cancelPerfiosTransaction(applicationType: string) {
  const { API_URL, API_URL_PLD } = urlInit()
  let INTEGRATION_ENDPOINT =
    applicationType === AlexiApplicationTypes.PARTNER_CONNECT_APPLICATION ? API_URL : API_URL_PLD

  const existingToken = JSON.parse(
    window.localStorage.getItem('perfiosId') as string
  ) as PerfiosTransaction
  if (existingToken) {
    const payload = {
      perfiosTransactionId: existingToken.perfiosTransactionId,
    }
    await axios.post(INTEGRATION_ENDPOINT + 'perfios/cancelTransaction', payload)
  }
}

export async function generatePerfiosReport(
  refId: string,
  fileName: string,
  applicationType: string
) {
  try {
    const { API_URL, API_URL_PLD } = urlInit()
    let INTEGRATION_ENDPOINT =
      applicationType === AlexiApplicationTypes.PARTNER_CONNECT_APPLICATION ? API_URL : API_URL_PLD

    const payload = {
      fileName,
      refId,
      perfiosTransactionId: JSON.parse(window.localStorage.getItem('perfiosId') as string)
        ?.perfiosTransactionId,
    }
    if (payload.perfiosTransactionId) {
      const { data, status } = await axios.post<GeneratePerfiosReport>(
        INTEGRATION_ENDPOINT + 'ext/api/generate/statements/report',
        payload
      )
      if (status === 201) {
        return data
      }
    }
  } catch (error) {
    await sendErrorLog(
      'Perfios_Report.json',
      refId,
      applicationType,
      error,
      ErrorLogUploadType.REPORT_GENERATION
    )
    throw new Error('Failed to generate report at the moment')
  }
}

export async function sendErrorLog(
  fileName: string,
  refId: string,
  applicationType: string,
  error: any,
  uploadType: string
) {
  try {
    const { API_URL, API_URL_PLD } = urlInit()
    let INTEGRATION_ENDPOINT =
      applicationType === AlexiApplicationTypes.PARTNER_CONNECT_APPLICATION ? API_URL : API_URL_PLD

    const errorLogged = window.localStorage.getItem('errorLogged') as string

    window.localStorage.setItem('errorLogged', 'true')

    if (errorLogged && uploadType === ErrorLogUploadType.STATEMENT_UPLOAD && errorLogged === 'true')
      return

    await axios.post(INTEGRATION_ENDPOINT + 'ext/api/log/errors', {
      refId,
      fileName: fileName,
      error: JSON.stringify(
        error?.response?.data?.message || error?.response?.data || error?.message
      ),
    })
  } catch (error) {
    console.log('Error in sending log:', error)
    window.localStorage.setItem('errorLogged', 'false')
  }
}
