import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Modal } from 'react-bootstrap-v5'
import axios from 'axios'

import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { setCompanyVerificationMethod, setSubStep } from './redux/actions'

interface Props {
  show: boolean
  handleClose: any
  goToStep: any
}

const UserVerification: FC<Props> = ({ show, handleClose, goToStep }: any) => {
  const dispatch = useDispatch()
  const history: any = useHistory()

  const coapplicant = useSelector((state: any) => state.coApplicant.co_applicant)
  const companyVerificationMethod = useSelector(
    (state: any) => state.coApplicant.companyVerificationMethod
  )

  const selectCompanyVerificationMethod = (method: number) => {
    dispatch(setCompanyVerificationMethod(method))
  }

  const connectMyInfo = async () => {
    // localStorage.setItem('coApplicationId', coapplicant?.id);
    localStorage.setItem('singpass', 'myinfo')

    // const singPassUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3002' : 'https://iris-dev.credilinq.ai/api/alexi-singpass';
    const singPassUrl = process.env.REACT_APP_SINGPASS_URL || 'http://localhost:3002'

    // For Netrust Configuration
    // /**
    //  * Generate Netrust Code Challenge
    //  */

    // const code = await axios
    //   .get(singPassUrl + '/singpass/myinfo/generateCode')
    //   .then((response) => response.data)

    // localStorage.setItem('SINGPASS_SESSION_ID', code?.session_id ?? '')

    const getEnv = await axios.get(singPassUrl + '/getEnvMyInfo').then((response) => response.data)

    const state = Math.floor(Math.random() * 100000)
    const {
      clientId,
      redirectUrl,
      attributes,
      purpose,
      environment,
      authApiUrl,
      myInfoAuthApiUrl,
      myInfoBizAuthApiUrl,
    } = getEnv

    // For Netrust Integration
    // For V3
    // const authoriseUrl: any = `${authApiUrl}?client_id=${clientId}&attributes=${attributes}&purpose=${purpose}&state=${encodeURIComponent(
    //   state
    // )}&redirect_uri=${redirectUrl}&response_type=code&code_challenge_method=S256&code_challenge=${
    //   code?.code_challenge
    // }`

    // For V4
    // const authoriseUrl: any = `${authApiUrl}?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=code&code_challenge_method=S256&purpose_id=${purpose}&scope=${
    //   attributes?.split(',')?.join('+') || ''
    // }&code_challenge=${encodeURIComponent(code?.code_challenge)}`

    // For Without Netrust Integration
    const authoriseUrl: any = `${authApiUrl}?client_id=${clientId}&attributes=${attributes}&purpose=${purpose}&state=${encodeURIComponent(
      state
    )}&redirect_uri=${redirectUrl}`

    ;(window as any).location = authoriseUrl
  }

  const submitProfileVerification = () => {
    localStorage.setItem('coApplicationId', coapplicant?.id)
    if (companyVerificationMethod === 1) {
      connectMyInfo()
    } else {
      dispatch(setSubStep(3))
      history.replace(`/co-applicant/${coapplicant.id}?subStep=3`)
    }
    handleClose()
  }

  return (
    <>
      <Modal
        className='bg-transparent'
        id='kt_mega_menu_modal'
        data-backdrop='static'
        aria-hidden='true'
        role='dialog'
        tabIndex='-1'
        backdrop='static'
        keyboard={false}
        // dialogClassName='modal-fullscreen'
        contentClassName='shadow-none'
        show={show}
        onHide={handleClose}
      >
        <div className='modal-content'>
          <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
            <div>
              <h3 className='fw-bold m-0'>Verify Company Profile</h3>
              <div className='text-muted mt-2'>
                Please select how you would like to verify your company profile
              </div>
            </div>
            {/* <div className='d-flex ms-2'>
              <div
                className='btn btn-icon btn-sm btn-light-primary ms-2'
                data-bs-dismiss='modal'
                onClick={handleClose}
              >
                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
              </div>
            </div> */}
          </div>

          <div className='d-block mx-5 my-10'>
            <div className='d-flex align-items-center border-bottom px-8 min-h-145px'>
              <div className='fv-row fv-plugins-icon-container'>
                <div className='row'>
                  <div className='col-lg-12 loan-application'>
                    <label
                      className={`btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center mb-10 ${
                        companyVerificationMethod === 1 ? 'selected' : ''
                      }`}
                      htmlFor='kt_create_account_form_account_type_personal'
                      onClick={() => selectCompanyVerificationMethod(1)}
                    >
                      <div className='d-block fw-bold text-start'>
                        <img
                          src={toAbsoluteUrl('/media/alexi/singpass-info-inline.png')}
                          alt='Singpass'
                          width='100%'
                        />
                        <div className='text-gray-400 fw-bold fs-6 mt-3'>
                          Takes about 1-2 minutes{' '}
                        </div>
                      </div>
                    </label>
                  </div>

                  <div className='col-lg-12 loan-application'>
                    <label
                      className={`btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center mb-10 ${
                        companyVerificationMethod === 2 ? 'selected' : ''
                      }`}
                      htmlFor='kt_create_account_form_account_type_corporate'
                      onClick={() => selectCompanyVerificationMethod(2)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/finance/fin006.svg'
                        className='svg-icon-3x me-5'
                      />

                      <span className='d-block fw-bold text-start'>
                        <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                          Fill out details Manually
                        </span>
                        <span className='text-gray-400 fw-bold fs-6'>Takes about 5-10 minutes</span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex flex-center mt-10'>
              <button
                className='btn btn-lg btn-primary me-3'
                onClick={() => submitProfileVerification()}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default UserVerification
