/* eslint-disable import/no-anonymous-default-export */
import { gql } from "@apollo/client";
import keys from './keys'

export default {
    GET_APPLICATION: gql`
        query($appId:ID,$mobileNumber:String){
            getLatestApplication(applicationId:$appId, mobileNo: $mobileNumber){
                ${keys.Application.join(',')},
                incomeDocuments{
                    ${keys.IncomeDocuments.join(',')}
                    documentFile{
                        ${keys.DocumentFile.join(',')}
                    }
                },
                loanEligibility{
                    ${keys.LoanEligibility.join(',')}
                },
                shareholderKycStatus{
                    ${keys.ShareholderKYCStatus.join(',')}
                },
                externalDataStore{
                    ${keys.ExternalDataStore.join(',')}
                },
                phoneDiscussion{
                    ${keys.PhoneDisscussion.join(',')}
                }
            }
        }
    `,
    GET_LOAN_ELIGIBILITY: gql`
        query($data: LoanEligibilityWhereUniqueInput!){
            loanEligibility(where:$data){
                ${keys.LoanEligibility.join(',')}
            } 
        }

    `,
    GET_SHAREHOLDERS: gql`
        query($uen: String!, $app: ID!){
            unwrapShareholders(uen:$uen, applicationId:$app){
                ${keys.ShareholderKYCStatus.join(',')}
            }
        }
    `,
    GET_SHAREHOLDER: gql`
        query($data: ShareholderKycStatusWhereUniqueInput!){
            shareholderKycStatus(where:$data){
                ${keys.ShareholderKYCStatus.join(',')}
            }
        }
    `,
    FETCH_BRIGS_DATA: gql`
        query($coApplicationId: ID!){
            fetchBrigsData(shareholderID: $coApplicationId)
        }
    `
}