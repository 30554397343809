export * from './AssetHelpers'
export * from './RouterHelpers'
export * from './components/KTSVG'
export * from './dataExamples'



export function trim(obj:any) {
  let _obj:any = {}
  for (let [key, value] of Object.entries(obj)) {
    _obj[key] = typeof value === 'string' ? value.replace(/\s+/g, " ").trim() : value;
  } 
  return _obj;
}

export function convertToDate(obj:any) {
  const {year, month, day} = obj;
  return new Date(year, month-1, day)
}

export function convertToDateTime(obj:any) {
  const {year, month, day, hour, minute} = obj;
  return new Date(year, month-1, day, hour, minute);
}