import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PinInput from 'react-pin-input'

import { KTSVG } from '../../../../_metronic/helpers'
import { fetchStart, fetchStartWithForward } from '../../../../redux/actions/common'

const Step1Sub2: FC<any> = ({ sendOTP, validateOTP, mobileNo, setMobileNo }) => {
  const dispatch = useDispatch()

  const [otp, setOtp] = useState<any>('')

  // const loanApplication = useSelector((state: any) => state.sgVendorsApplication.partnerApplication);

  useEffect(() => {
    return () => {
      setMobileNo('')
    }
  }, [])

  const resendOTP = () => {
    dispatch(fetchStart())
    sendOTP({
      variables: {
        mobileNo: mobileNo,
        isApplicantOrShareholder: true,
      },
    })
  }

  // const submitOTP = () => {
  //   if(otp.length === 6){
  //     dispatch(fetchStartWithForward());
  //     validateOTP({
  //       variables: {
  //         otp,
  //         mobileNo: mobileNo,
  //         isApplicantOrShareholder: true
  //       }
  //     })
  //   }
  // }

  const onSubmitOTP = (otp: string) => {
    dispatch(fetchStartWithForward())
    validateOTP({
      variables: {
        otp,
        mobileNo: mobileNo,
        isApplicantOrShareholder: true,
      },
    })
  }

  return (
    <>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <KTSVG
          path='/media/svg/misc/smartphone.svg'
          className='svg-icon svg-icon-5x me-1 mobile-icon-svg'
        />
        <h2>OTP Verification</h2>
        <div className='text-muted mb-20'>
          Enter the verification code we sent to{' '}
          {mobileNo && mobileNo.replace(/[^\w ]/g, '').replace(/\d(?=.*\d{4})/g, '*')}
        </div>
        <div>Type your 6 digit security code</div>
        <PinInput
          length={6}
          initialValue=''
          secret
          focus={true}
          onChange={(value, index) => {
            setOtp(value)
          }}
          type='numeric'
          inputMode='number'
          style={{ padding: '10px' }}
          inputStyle={{ borderColor: 'red' }}
          inputFocusStyle={{ borderColor: 'blue' }}
          onComplete={(value, index) => {
            onSubmitOTP(value)
          }}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />

        <div className='mb-10'>
          Didn’t get the code?{' '}
          <span
            className='nav-link inline-block cursor-pointer accent-color'
            onClick={() => resendOTP()}
          >
            Resend
          </span>
        </div>

        {/* <div className="d-flex">
          <button 
            type="button"
            className='btn btn-lg btn-primary me-3' 
            disabled={otp.length<6}
            onClick={() => submitOTP()}
          >Submit
          </button>
        </div> */}
      </div>
    </>
  )
}

export { Step1Sub2 }
