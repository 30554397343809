import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import RangeSlider from 'react-bootstrap-range-slider'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import NumberFormat from 'react-number-format'
import { useFormik, validateYupSchema } from 'formik'
import * as Yup from 'yup'

import { validateUEN } from '../../../../_metronic/helpers/validation'
import { fetchStartWithForward } from '../../../../redux/actions/common'
import { deleteApplicationDetails, saveApplicationDetails } from '../redux/actions'
import { trim } from '../../../../_metronic/helpers'
import { MaintenanceModeBanner } from '../../../../_metronic/helpers/components/MaintenanceModeBanner'
import { AlexiApplicationTypes } from '../../../../utils/constants/AlexiApplicationType.enum'
import { getStoredEnv } from '../../../../utils/getIntegrationURLFromENV'
import { useHistory, useParams } from 'react-router-dom'
import { parse } from 'query-string'

const errorMsg = {
  required: 'required',
  amountRange: 'Amount range should be between 75,000 - 500,000',
  durationRange: 'Duration range should be between 3 - 12 months',
}

const Step1Sub1: FC<any> = ({
  onSuccessCreateApplicaton,
  sendOTP,
  createApplication,
  mobileNo,
  applicantName,
}) => {
  const dispatch = useDispatch()
  const params: any = useParams()
  const history = useHistory()

  const searchQuery: any = parse(history.location.search)

  const maxAmount = 500000
  const minAmount = 75000
  const maxDuration = 12
  const minDuration = 3

  const [loanAmountRange, setLoanAmountRange] = useState<any>(75000)
  const [loanDurationRange, setLoanDurationRange] = useState<any>(6)
  // const [mobileNo, setMobileNo] = useState<any>('+977984');
  // const [sendOTP, {data,loading,error}] = useMutation(mutations.SEND_OTP, { variables: { mobileNo, isApplicantOrShareholder: true } });

  const savedApplicationDetails = useSelector(
    (state: any) => state.kycApplication.applicationDetails
  )

  const handleLoanAmountRange = (value: string) => {
    dispatch(saveApplicationDetails(formik.values))
    setLoanAmountRange(value)
    formik.setFieldValue('amountRequested', value)
    //if(error.match(/ name/i)) formik.setFieldError('name', error);
  }

  const handleLoanDurationRange = (value: string) => {
    dispatch(saveApplicationDetails(formik.values))
    setLoanDurationRange(value)
    formik.setFieldValue('durationInMonths', value)
  }

  const initialValues = {
    applicantName: applicantName ? applicantName : searchQuery?.name || '',
    mobileNumber: mobileNo ? mobileNo : '',
  }

  const validationSchema = Yup.object().shape({
    applicantName: Yup.string()
      .required('Applicant Name is required')
      .test('unique', 'Applicant Name must not contain numbers', (value: any) => {
        return !/\d/.test(value)
      }),
    mobileNumber: Yup.string()
      .required('Mobile number is required')
      .test(
        'mobileValidation',
        'Invalid Mobile number. Kindly recheck the entered number',
        () => true
      )
      .test('singaporemobileValidation', 'Only singapore number supported', (value: any) => {
        return /\+65\d{8}/g.test(value)
      }),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: savedApplicationDetails || initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const {
        amountRequested,
        durationInMonths,
        companyUEN,
        positionInCompany,
        applicantName,
        mobileNumber,
        promoCode,
      } = trim(values)

      dispatch(fetchStartWithForward())

      if (!params?.id && !searchQuery?.mobileNo && !searchQuery?.name) {
        createApplication({
          variables: {
            data: {
              applicantName,
              mobileNumber,
              applicationStatus: 'in-progress',
              applicationCompleteStep: 1.1,
              applicationType: AlexiApplicationTypes.KYC_ONBOARDING,
              partnerId: localStorage.getItem('partnerId') || '',
              credilinqEnvironment: getStoredEnv(),
            },
          },
        })
      } else {
        sendOTP({
          variables: {
            mobileNo,
            isApplicantOrShareholder: true,
          },
        })
        onSuccessCreateApplicaton.reset()
        localStorage.setItem('loanApplicationId', params.id)
      }
      setSubmitting(false)
    },
  })

  useEffect(() => {
    return () => {
      dispatch(deleteApplicationDetails())
    }
  }, [])
  return (
    <div className='d-flex flex-column flex-column-fluid'>
      <MaintenanceModeBanner />
      <h2>Welcome</h2>
      <div className='sub-heading text-muted mb-10'>
        Please proceed to complete the Business & Director verification with Myinfo
        business/Corppass and Myinfo singpass
      </div>
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className='row mb-10'>
          <label htmlFor='' className='mt-3 form-label fs-6 fw-bolder text-dark col-sm-3 required'>
            Applicant Name
          </label>
          <div className='col-sm-9'>
            <input
              name='applicantName'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={formik.values.applicantName}
              onChange={formik.handleChange}
              disabled={!!searchQuery?.name}
            />
            {formik.touched.applicantName && formik.errors.applicantName && (
              <div className='text-danger mt-2'>{formik.errors.applicantName}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='mt-3 form-label fs-6 fw-bolder text-dark col-sm-3 required'>
            Mobile Number
          </label>
          <div className='col-sm-9'>
            <PhoneInput
              id='mobileNumber'
              international
              defaultCountry='SG'
              // countryCallingCodeEditable={false}
              value={formik.values.mobileNumber}
              onChange={(value) => formik.setFieldValue('mobileNumber', value)}
              placeholder='Enter phone number'
              className='int-phone-input'
              disabled={!!searchQuery?.mobile}
            />
            {formik.touched.mobileNumber && formik.errors.mobileNumber && (
              <div className='text-danger mt-2'>{formik.errors.mobileNumber}</div>
            )}
          </div>
        </div>

        <div className='d-flex flex-end'>
          <button type='submit' className='btn btn-lg btn-primary me-3'>
            Send OTP
          </button>
        </div>
      </form>
    </div>
  )
}

export { Step1Sub1 }
