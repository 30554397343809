import React, {Dispatch, SetStateAction} from 'react'
import {Modal, Spinner} from 'react-bootstrap-v5'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../AssetHelpers'
import {KTSVG} from './KTSVG'
type Props = {
  message: string
  openCustomLoaderModal: boolean
  setOpenCustomLoaderModal: Dispatch<SetStateAction<boolean>>
}

const CustomLoaderModal = ({openCustomLoaderModal, setOpenCustomLoaderModal, message}: Props) => {
  return (
    <Modal
      className='bg-transparent'
      id='kt_mega_menu_modal'
      data-backdrop='static'
      aria-hidden='true'
      role='dialog'
      tabIndex='-1'
      contentClassName='shadow-none'
      show={openCustomLoaderModal}
    >
      <div className='modal-content'>
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5'>
          <h4 className='fw-bold m-0' style={{maxWidth: 'calc(100% - 60px)'}}>
            {message}
          </h4>
          <Spinner
            style={{
              height: '50px',
              width: '50px',
            }}
            animation='grow'
            variant='danger'
          />
        </div>
      </div>
    </Modal>
  )
}

export {CustomLoaderModal}
