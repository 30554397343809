import axios from 'axios'
import { parse } from 'query-string'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { MaintenanceModeBanner } from '../../../_metronic/helpers/components/MaintenanceModeBanner'
import { AlexiApplicationTypes } from '../../../utils/constants/AlexiApplicationType.enum'
import { getIntegrationURLFromENV, handleSettingEnv } from '../../../utils/getIntegrationURLFromENV'
import { setLoanApplicationStatus, setPartnerDetails } from './redux/actions'
import { LoanApplicationLocalStorageKeys } from '../../../utils/constants/LocalStorageKeys.enum'

const LoanType: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  let { type } = useParams<{ type: string }>()
  const searchQuery: any = parse(history.location.search)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleSettingEnv(searchQuery?.env)

    /**
     * Setting Partner Id
     */
    getPartnerDetails(searchQuery?.partner)
  }, [])

  const getPartnerDetails = async (partnerId: string) => {
    setLoading(true)
    const env = localStorage.getItem('env')
    const API_URL = getIntegrationURLFromENV(searchQuery?.env || env)

    const partnerData = await axios.get(`${API_URL}ext/api/businesspartner/${partnerId || ''}`)

    if (partnerData.data && partnerData.status === 200) {
      localStorage.setItem('partnerId', partnerData.data?.id)
      localStorage.setItem('partnerDetails', JSON.stringify(partnerData.data))
      dispatch(setPartnerDetails(partnerData.data))

      setLoading(false)
    }
  }

  // const loanApplicationId = localStorage.getItem('loanApplicationId');
  if (type && type.toLowerCase() === AlexiApplicationTypes.RECURRING_APPLICATION)
    localStorage.setItem('loanType', AlexiApplicationTypes.RECURRING_APPLICATION)
  else localStorage.setItem('loanType', AlexiApplicationTypes.LOAN_APPLICATION)
  localStorage.removeItem('loanApplicationId')

  const status = useSelector((state: any) => state.loan.loanApplicationStatus)

  const selectLoanApplication = (status: number) => {
    /**
     * This was made in order to fetch previous value on page refresh
     * This is deciding factor in incrementing steps in further journey
     * 1 -> New Application
     * 2 -> Resume Application
     */
    localStorage.setItem(
      LoanApplicationLocalStorageKeys.LOAN_APPLICATION_SUBMISSION_METHOD,
      JSON.stringify(status)
    )
    dispatch(setLoanApplicationStatus(status))
  }

  const goToApplicationSteps = () => {
    const step = status === 1 ? '1.1' : '1.0'
    history.push(`/application`)
  }

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div className='d-flex flex-column flex-lg-row-auto w-xl-500px'>
          <div className='d-flex flex-row-fluid flex-column p-20 pt-lg-20'>
            <div className='logo text--center mb-5'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo-cred.png')}
                className='h-100px'
              />
            </div>

            <h2 className='title mb-2 text-black'>Welcome to CrediLinq’s Loan Application </h2>
            <div className='desc text-bold mb-20'>
              Get a loan decision in less than 48 hours for loan upto SGD$ 500,000 without any
              collateral
            </div>
            <div
              className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-150px min-h-lg-300px'
              style={{
                backgroundImage: `url('${toAbsoluteUrl('/media/alexi/Illustration-25-1.svg')}')`,
              }}
            ></div>
          </div>
        </div>

        <div className='d-flex flex-column flex-lg-row-fluid py-10'>
          <div className='d-flex flex-center flex-column flex-column-fluid'>
            <div className='w-lg-700px p-10 p-lg-15 mx-auto'>
              <div className='w-100'>
                <div className='pb-10 pb-lg-15'>
                  <h2 className='fw-bolder d-flex align-items-center text-dark mb-10'>
                    On behalf of my company, I would like to:
                    {/* <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Billing is issued based on your selected account type" aria-label="Billing is issued based on your selected account type"></i> */}
                  </h2>

                  {/* <div className="text-muted fw-bold fs-6 pb-lg-15">
                    If you need more info, please check out <Link to="" className="link-primary fw-bolder">FAQ Page</Link>
                  </div> */}

                  <div className='fv-row fv-plugins-icon-container'>
                    <div className='row'>
                      <div className='col-lg-12 loan-application'>
                        <label
                          className={`btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10 ${
                            status === 1 ? 'selected' : ''
                          }`}
                          htmlFor='kt_create_account_form_account_type_personal'
                          onClick={() => selectLoanApplication(1)}
                        >
                          {/* <KTSVG
                            path='/media/icons/duotune/communication/com005.svg'
                            className='svg-icon-3x me-5'
                          /> */}
                          <i
                            className='fa fa-user me-3 text-gray-400'
                            style={{ fontSize: '30px' }}
                          ></i>

                          <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                              Apply for new loan
                            </span>
                            <span className='text-gray-400 fw-bold fs-6'>
                              Loan Eligibility in less than 5 minutes{' '}
                            </span>
                          </span>
                        </label>
                      </div>

                      <div className='col-lg-12 loan-application'>
                        <label
                          className={`btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center ${
                            status === 2 ? 'selected' : ''
                          }`}
                          htmlFor='kt_create_account_form_account_type_corporate'
                          onClick={() => selectLoanApplication(2)}
                        >
                          {/* <KTSVG path='/media/icons/duotune/finance/fin006.svg' className='svg-icon-3x me-5' /> */}
                          <i
                            className='fa fa-th-large me-3 text-gray-400'
                            style={{ fontSize: '30px' }}
                          ></i>

                          <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                              Resume my existing application
                            </span>
                            <span className='text-gray-400 fw-bold fs-6'>
                              Finalize your application to see how much you’re eligible for
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <MaintenanceModeBanner />
                </div>

                <div className='d-flex flex-stack pt-15'>
                  <div></div>
                  <button
                    type='submit'
                    className='btn btn-lg btn-primary me-3'
                    onClick={() => goToApplicationSteps()}
                  >
                    <span className='indicator-label'>Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoanType
