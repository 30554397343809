/* eslint-disable react-hooks/exhaustive-deps */
import { ServerError, useLazyQuery, useMutation } from '@apollo/client'
import { parse } from 'query-string'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { usePrevious } from '../../../_metronic/helpers/customHooks'
import Alert from '../../../_metronic/partials/alert'
import { fetchStop } from '../../../redux/actions/common'
import mutations from '../../../setup/graphql/mutations'
import query from '../../../setup/graphql/query'
import {
  setDecisionMaker,
  setDirector,
  setGuarantor,
  setLoanApplication,
  setLoanEligibility,
  setLoanStep,
  setLoanSubStep,
  setPartnerDetails,
  setSingPassInfo,
} from './redux/actions'

import axios from 'axios'
import ReactPixel from 'react-facebook-pixel'
import { getIntegrationURLFromENV, handleSettingEnv } from '../../../utils/getIntegrationURLFromENV'
import { Step1, Step2, Step3, Step3Sub1, Step4 } from './steps'

const LoanApplication: FC = () => {
  const dispatch = useDispatch()
  const params: any = useParams()
  const history: any = useHistory()
  const { pathname } = useLocation()
  const partnerDetails = useSelector((state: any) => state.loan.partner)
  // let mainStep = 0, mainSubStep = 0;

  const { id } = params
  const searchQuery: any = parse(history.location.search)

  /**
   * Setting ENV & PartnerId
   */
  useEffect(() => {
    handleSettingEnv(searchQuery?.env)
  }, [])

  useEffect(() => {
    if (partnerDetails == null) {
      const localId = localStorage.getItem('partnerId')
      getPartnerDetails(searchQuery?.partner || localId)
    }
  }, [])

  const getPartnerDetails = async (partnerId: string) => {
    const env = localStorage.getItem('env')
    const API_URL = getIntegrationURLFromENV(searchQuery?.env || env)

    const partnerData = await axios.get(`${API_URL}ext/api/businesspartner/${partnerId || ''}`)

    if (partnerData.data && partnerData.status === 200) {
      localStorage.setItem('partnerId', partnerData.data?.id)
      localStorage.setItem('partnerDetails', JSON.stringify(partnerData.data))
      dispatch(setPartnerDetails(partnerData.data))
    }
  }

  // if(searchQuery.length>0) {
  //   mainStep = Number(searchQuery.step.split('.')[0]);
  //   mainSubStep = Number(searchQuery.step.split('.')[1]);
  // }

  const prevStep: any = usePrevious(searchQuery)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const [progress, setProgress] = useState<any>(0)

  const [getApplication, onSuccessGetApplication] = useLazyQuery(query.GET_APPLICATION)
  const [updateApplication, onSuccessUpdateApplicaton] = useMutation(mutations.UPDATE_APPLICATION)
  const loanApplication = useSelector((state: any) => state.loan.loanApplication)
  const loanStep = useSelector((state: any) => state.loan.loanStep)
  const loanSubStep = useSelector((state: any) => state.loan.loanSubStep)
  const status = useSelector((state: any) => state.loan.loanApplicationStatus)
  const singpassInfo = useSelector((state: any) => state.loan.singpass)
  const entityPerson = useSelector((state: any) => state.loan.entityPerson)
  const forwardWithButton = useSelector((state: any) => state.common.forwardWithButton)
  const companyVerificationMethod = useSelector(
    (state: any) => state.coApplicant.companyVerificationMethod
  )

  const pageRedirect = (loanApplication: any) => {
    if (history.location.search.length > 0) {
      const { applicationCompleteStep } = loanApplication
      const searchQuery: any = parse(history.location.search)
      const mainStep = Number(searchQuery.step.split('.')[0])
      const mainSubStep = Number(searchQuery.step.split('.')[1])

      if (applicationCompleteStep === 5) {
        if (mainStep !== applicationCompleteStep) {
          dispatch(setLoanStep(mainStep))
        } else {
          if (entityPerson.entity !== null || singpassInfo !== null) {
            dispatch(setLoanStep(applicationCompleteStep))
          } else {
            dispatch(setLoanStep(2))
            history.replace(`/application/${loanApplication.id}?step=2`)
          }
        }
      } else if (applicationCompleteStep === 4) {
        if (entityPerson.entity !== null || singpassInfo !== null) {
          dispatch(setLoanStep(mainStep))
        } else {
          dispatch(setLoanStep(2))
          history.replace(`/application/${loanApplication.id}?step=2`)
        }
      } else if (applicationCompleteStep === 3) {
        if (mainStep !== applicationCompleteStep) {
          dispatch(setLoanStep(mainStep))
        } else {
          if (entityPerson.entity !== null) {
            dispatch(setLoanStep(applicationCompleteStep))
          } else {
            dispatch(setLoanStep(2))
            history.replace(`/application/${loanApplication.id}?step=2`)
          }
        }
      } else if (applicationCompleteStep === 2) {
        if (mainStep === applicationCompleteStep) {
          dispatch(setLoanStep(2))
        } else {
          if (mainStep === 3 && companyVerificationMethod === 1 && entityPerson.entity == null) {
            history.push(`/application/${loanApplication.id}?step=2`)
          } else {
            dispatch(setLoanStep(mainStep))
            dispatch(setLoanSubStep(mainSubStep))
            history.push(
              `/application/${loanApplication.id}?step=${mainStep}${
                mainSubStep > 0 ? `.${mainSubStep}` : ''
              }`
            )
          }
          /*           if(entityPerson.entity !== null){
            dispatch(setLoanStep(mainStep));
            dispatch(setLoanSubStep(mainSubStep));
            history.push(`/application/${loanApplication.id}?step=${mainStep}${mainSubStep>0 ? `.${mainSubStep}` : ''}`);
          } else {
            dispatch(setLoanStep(2));
            history.replace(`/application/${loanApplication.id}?step=2`);
          } */
        }
      } else if (applicationCompleteStep < 2) {
        if (mainStep < applicationCompleteStep) {
          dispatch(setLoanStep(mainStep))
          dispatch(setLoanSubStep(mainSubStep))
        } else {
          // const currentStep = loanApplication.applicationCompleteStep;
          // const step = Math.floor(currentStep) === 1 ? Math.floor(currentStep) : Math.floor(currentStep)+1;
          // const subStep = Number(currentStep.toString().split('.')[1]);

          // dispatch(setLoanStep(step));
          // dispatch(setLoanSubStep(subStep));
          // history.replace(`/application/${loanApplication.id}?step=${step}.${subStep}`);
          dispatch(setLoanStep(mainStep))
        }
      } else {
        dispatch(setLoanStep(mainStep))
        dispatch(setLoanSubStep(mainSubStep))
      }
    } else {
      dispatch(setLoanStep(1))
      dispatch(setLoanSubStep(2))
      history.replace(`/application/${loanApplication.id}`)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname, searchQuery])

  useEffect(() => {
    if (prevStep && Object.keys(prevStep).length > 0 && Object.keys(searchQuery).length > 0) {
      if (prevStep.step !== searchQuery.step && loanApplication && !forwardWithButton) {
        pageRedirect(loanApplication)
      }
    }

    if (Object.keys(searchQuery).length === 0 && id && status === 1) {
      dispatch(setLoanStep(1))
      dispatch(setLoanSubStep(2))
    }
  }, [searchQuery])

  useEffect(() => {
    const loanApplicationId = localStorage.getItem('loanApplicationId')
    /* if(id) {
      if(loanApplication === null && (loanSubStep > 2 || entityPerson) ) {
      // if(loanApplication === null) {
        getApplication({
          variables: {
            appId: id || loanApplicationId
          }
        })
      } else {
        if(loanSubStep === 0){
          history.replace('/application/type');

        }
      }
    } else if(status > 0) {
      const loanSubStep = status === 1 ? 1 : 0;
      dispatch(setLoanStep(1));
      dispatch(setLoanSubStep(loanSubStep));
    } else {
      history.replace('/application/type');
    } */

    if (id) {
      if (loanApplication === null) {
        getApplication({
          variables: {
            appId: id || loanApplicationId,
          },
        })
      }
    } else {
      const loanSubStep = status === 1 ? 1 : 0
      dispatch(setLoanStep(1))
      dispatch(setLoanSubStep(loanSubStep))
    }
  }, [id])

  useEffect(() => {
    if (loanStep > 0) {
      const progress = progressPercentageCalculate()
      setProgress(progress)
    }
  }, [loanStep, loanSubStep])

  useEffect(() => {
    if (onSuccessGetApplication.called && !onSuccessGetApplication.loading) {
      dispatch(fetchStop())
      if (onSuccessGetApplication.data) {
        const loanApplication = onSuccessGetApplication.data.getLatestApplication
        // const currentStep = loanApplication.applicationCompleteStep;
        // const step = Math.floor(currentStep) === 1 ? Math.floor(currentStep) : Math.floor(currentStep)+1;
        // const step = currentStep < 1.4 ? Math.floor(currentStep) : Math.floor(currentStep)+1;

        if (loanApplication !== null) {
          const loanApplicationId = localStorage.getItem('loanApplicationId')
          // const loanEligibility = loanApplication.loanEligibility.length > 0 ? loanApplication.loanEligibility[0] : null;
          const {
            applicationCompleteStep,
            loanEligibility,
            shareholderKycStatus,
            externalDataStore,
          } = loanApplication
          const singpassInfo = externalDataStore.filter((o: any) => o.type === 'singpass')

          if (loanEligibility !== null) {
            dispatch(setLoanEligibility(loanEligibility))
          }

          if (shareholderKycStatus.length > 0) {
            const decisionMaker = shareholderKycStatus.filter((o: any) => o.isDecisionMaker)[0]
            const gurantors = shareholderKycStatus.filter((o: any) => o.isGuarantor)
            const directors = shareholderKycStatus.filter((o: any) => o.isDirector)

            dispatch(setGuarantor(gurantors))
            dispatch(setDirector(directors))
            dispatch(setDecisionMaker(decisionMaker ? decisionMaker : null))
          }

          if (singpassInfo.length > 0) {
            dispatch(setSingPassInfo(singpassInfo[0].data))
          }

          pageRedirect(loanApplication)

          /* if(history.location.search.length>0){
            const searchQuery:any = parse(history.location.search)
            const mainStep = Number(searchQuery.step.split('.')[0]);
            const mainSubStep = Number(searchQuery.step.split('.')[1]);

            if(applicationCompleteStep === 4) {

            } else if(applicationCompleteStep === 3) {

            } else if(applicationCompleteStep === 2) {
              if(mainStep === applicationCompleteStep) {
                dispatch(setLoanStep(2));
              } else {
                if(entityPerson){
                  dispatch(setLoanStep(mainStep));
                } else {
                  dispatch(setLoanStep(2));
                  history.replace(`/application/${loanApplication.id}?step=2`);
                }
              }

            } else if(applicationCompleteStep < 2) {
              if(mainStep < applicationCompleteStep) {
                dispatch(setLoanStep(mainStep));
                dispatch(setLoanSubStep(mainSubStep));
              } else {
                const currentStep = loanApplication.applicationCompleteStep;
                const step = Math.floor(currentStep) === 1 ? Math.floor(currentStep) : Math.floor(currentStep)+1;
                const subStep = Number(currentStep.toString().split('.')[1]);

                dispatch(setLoanStep(step));
                dispatch(setLoanSubStep(subStep));
                history.replace(`/application/${loanApplication.id}?step=${step}.${subStep}`);
              }
            } else {
              dispatch(setLoanStep(mainStep));
              dispatch(setLoanSubStep(mainSubStep));
            }

          } else {
            dispatch(setLoanStep(1));
            dispatch(setLoanSubStep(2));
            history.replace(`/application/${loanApplication.id || loanApplicationId}`);
          } */

          /* const currentStep = loanApplication.applicationCompleteStep;
          const currentSubStep = Number(currentStep.toString().split('.')[1]);

          const step = currentStep < 1.4 
            ? Math.floor(currentStep) 
            : entityPerson !== null ? 3 : Math.floor(currentStep)+1;
          const subStep = currentSubStep === 1 ? 3 : currentSubStep+1; */

          dispatch(setLoanApplication(loanApplication))
        } else {
          if (status === 1) {
            history.replace('/application/type')
          }
        }
      } else {
      }

      if (onSuccessGetApplication.error) {
        setShowAlert(true)
        setAlertMsg('error')
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [onSuccessGetApplication.loading])

  useEffect(() => {
    if (onSuccessUpdateApplicaton.called && !onSuccessUpdateApplicaton.loading) {
      // dispatch(fetchStop());
      if (onSuccessUpdateApplicaton.data) {
        const { updateApplication } = onSuccessUpdateApplicaton.data
        const application = { ...loanApplication, ...updateApplication }
        dispatch(setLoanApplication(application))
        onSuccessUpdateApplicaton.reset()
        dispatch(fetchStop())
      } else {
      }

      if (onSuccessUpdateApplicaton.error) {
        const { networkError } = onSuccessUpdateApplicaton.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
      }
    }
  }, [
    onSuccessUpdateApplicaton.loading,
    onSuccessUpdateApplicaton.data,
    onSuccessUpdateApplicaton.error,
    onSuccessUpdateApplicaton.called,
  ])

  const additionalProps: any = {
    updateApplication: updateApplication,
    getApplication: getApplication,
  }
  /* const progressPercentageCalculate = (currentStep: number) => {
    let currentStepIndex = currentStep - 1
    if(currentStep >= 2) return currentStepIndex / 5 * 100
    if (currentStep >= 1 &&  currentStep <  2) return parseFloat((20 * (((currentStep * 10 % 10) - 1) / 3)).toFixed(2))
    return 0
  } */

  const progressPercentageCalculate = () => {
    let progress = 0
    if (loanStep > 0 || loanSubStep > 0) {
      if (loanStep === 1) {
        progress = Math.floor(5 * loanSubStep)
      } else if (loanStep > 1) {
        progress = Math.floor(20 * loanStep)
      }
    }

    return progress
  }

  useEffect(() => {
    ReactPixel.track('Applicaiton Registration Start')
  }, [])

  return (
    <div className='d-flex flex-column flex-root stepper stepper-pills stepper-column'>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div
          className='d-flex flex-column flex-lg-row-auto w-xl-400px stepper-bg'
          style={{
            background: `url(${toAbsoluteUrl(
              '/media/patterns/background-vertical.png'
            )}) no-repeat center center / cover`,
          }}
        >
          <div className='d-flex flex-row-fluid flex-column p-20 pt-lg-10'>
            <div className='logo text-center mb-5'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo-white.png')}
                className='h-100px'
              />
            </div>
            {loanSubStep === 0 && loanStep === 1 && (
              <div className='stepper-nav mb-20'>
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <div className='stepper-line w-40px'></div>

                  <div className='stepper-icon w-40px h-40px p-5'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>

                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Resume Application</h3>

                    <div className='stepper-desc fw-bold'>
                      Welcome back, you are hardly 2 steps away, please continue the journey and
                      complete the application.
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(loanSubStep !== 0 || loanStep > 1) && (
              <div className='stepper-nav mb-20'>
                <div
                  className={`stepper-item ${
                    loanStep === 1 ? 'current' : loanStep > 1 ? 'completed' : ''
                  }`}
                  data-kt-stepper-element='nav'
                >
                  <div className='stepper-line w-40px'></div>

                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>

                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Loan Eligibility</h3>

                    {/* <div className='stepper-desc fw-bold'>Setup Your Account Details</div> */}
                  </div>
                </div>

                <div
                  className={`stepper-item ${
                    loanStep === 2 ? 'current' : loanStep > 2 ? 'completed' : ''
                  }`}
                  data-kt-stepper-element='nav'
                >
                  <div className='stepper-line w-40px'></div>

                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>

                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Income Documents</h3>
                    {/* <div className='stepper-desc fw-bold'>Setup Your Account Details</div> */}
                  </div>
                </div>

                <div
                  className={`stepper-item ${
                    loanStep === 3 ? 'current' : loanStep > 3 ? 'completed' : ''
                  }`}
                  data-kt-stepper-element='nav'
                >
                  <div className='stepper-line w-40px'></div>

                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>3</span>
                  </div>

                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Company Profile</h3>
                    {/* <div className='stepper-desc fw-bold'>Setup Your Account Details</div> */}
                  </div>
                </div>

                <div
                  className={`stepper-item ${
                    loanStep === 4 ? 'current' : loanStep > 3 ? 'completed' : ''
                  }`}
                  data-kt-stepper-element='nav'
                >
                  <div className='stepper-line w-40px'></div>

                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>4</span>
                  </div>

                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Shareholders</h3>
                    {/* <div className='stepper-desc fw-bold'>Setup Your Account Details</div> */}
                  </div>
                </div>

                <div
                  className={`stepper-item ${
                    loanStep === 5 ? 'current' : loanStep > 4 ? 'completed' : ''
                  }`}
                  data-kt-stepper-element='nav'
                >
                  <div className='stepper-line w-40px'></div>

                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>5</span>
                  </div>

                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Review Application</h3>
                    {/* <div className='stepper-desc fw-bold'>Setup Your Account Details</div> */}
                  </div>
                </div>
              </div>
            )}

            <div className='logo text-center'>
              <div className='card shadow-sm'>
                <div className='card-body circular-progressbar-card'>
                  <h5 className='text-start mb-5'>Application Progress</h5>
                  <div className='d-flex justify-content-center align-items-center mb-5'>
                    <div className='progress-container'>
                      <CircularProgressbar
                        value={progress}
                        text={`${progress}%`}
                        className='circular-progress-bar'
                        counterClockwise={true}
                      />
                    </div>
                  </div>
                  <p className='progress-bar-text mb-10'>
                    Business Loan Application is tracking your progress and you can resume at any
                    time
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className='d-flex flex-column flex-lg-row-fluid pb-10 px-20'
          style={{ paddingTop: '140px' }}
        >
          <div className='d-flex flex--center flex-column flex-column-fluid'>
            <div className={loanStep === 1 ? 'current' : ''} data-kt-stepper-element='content'>
              {loanStep === 1 && <Step1 {...additionalProps} />}
            </div>

            <div className={loanStep === 2 ? 'current' : ''} data-kt-stepper-element='content'>
              {loanStep === 2 && <Step2 {...additionalProps} />}
            </div>

            <div className={loanStep === 3 ? 'current' : ''} data-kt-stepper-element='content'>
              {loanStep === 3 && <Step3 {...additionalProps} />}
            </div>

            <div className={loanStep === 4 ? 'current' : ''} data-kt-stepper-element='content'>
              {loanStep === 4 && <Step3Sub1 {...additionalProps} />}
            </div>

            <div className={loanStep === 5 ? 'current' : ''} data-kt-stepper-element='content'>
              {loanStep === 5 && <Step4 {...additionalProps} />}
            </div>
          </div>
        </div>
      </div>

      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}
    </div>
  )
}

export default LoanApplication
