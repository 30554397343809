import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PinInput from "react-pin-input";

import { KTSVG } from "../../../../_metronic/helpers";
import { fetchStart } from "../../../../redux/actions/common";
import { setOpenVerification, setSubStep } from "../redux/actions";
import UserVerification from "../UserVerification";

const Step1Sub2: FC<any> = ({ mobileNo, sendOTP, validateOTP, id }) => {
  const dispatch = useDispatch();

  const [otp, setOtp] = useState<any>('');
  const openVerification = useSelector((state: any) => state.coApplicant.openVerification);

  // const [validateOTPMutation, { data, loading, error }] = useMutation(mutations.VALIDATE_OTP, { variables: { otp: otp, mobileNo: localStorage.getItem("mobileNo"), isApplicantOrShareholder: true } });

  const resendOTP = () => {
    dispatch(fetchStart());
    sendOTP({
      variables: {
        mobileNo,
        isApplicantOrShareholder: false
      }
    });
  }

  // const submitOTP = () => {
  //   if (otp.length === 6) {
  //     dispatch(fetchStart());
  //     validateOTP({
  //       variables: {
  //         otp,
  //         mobileNo,
  //         isApplicantOrShareholder: false,
  //         applicantId: id
  //       }
  //     })
  //   }
  // }

  const onSubmitOTP = (otp: string) => {
    dispatch(fetchStart());
    validateOTP({
      variables: {
        otp,
        mobileNo,
        isApplicantOrShareholder: false,
        applicantId: id
      }
    })
  }


  return (
    <>
      <div className="d-flex flex-column flex-center flex-column-fluid">
        <KTSVG

          path='/media/svg/misc/smartphone.svg'
          className='svg-icon svg-icon-5x me-1 mobile-icon-svg'
        />
        <h2>OTP Verification</h2>
        <div className="text-muted mb-20">Enter the verification code we sent to {mobileNo && mobileNo.replace(/[^\w ]/g, '').replace(/\d(?=.*\d{4})/g,'*')}</div>
        <div>Type your 6 digit security code</div>
        <PinInput
          length={6}
          initialValue=""
          secret
          focus={true}
          onChange={(value, index) => { setOtp(value) }}
          type="numeric"
          inputMode="number"
          style={{ padding: '10px' }}
          inputStyle={{ borderColor: 'red' }}
          inputFocusStyle={{ borderColor: 'blue' }}
          onComplete={(value, index) => { onSubmitOTP(value) }}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />

        <div className="mb-10">Didn’t get the code? <span className="nav-link inline-block cursor-pointer accent-color" onClick={() => resendOTP()}>Resend</span></div>

        {/* <div className="d-flex">
          <button
            type="button"
            className='btn btn-lg btn-primary me-3'
            disabled={otp.length < 6}
            onClick={() => submitOTP()}
          >Submit
          </button>
        </div> */}
      </div>
      {openVerification && (
        <UserVerification
          show={openVerification}
          handleClose={() => {
            dispatch(setOpenVerification(false))
          }}
          goToStep={() => {
            dispatch(setSubStep(3))
          }}
        />
      )}
    </>
  )
}

export { Step1Sub2 };