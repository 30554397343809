/**
 * Used by PartnerConnect and SG Vendors Journey,
 * here we decide which integration environment to call based on the env given in parameter
 * @param env
 * @returns
 */
export const getIntegrationURLFromENV = (env: string) => {
  const API_URL = 'https://[env]integration.credilinq.ai/'

  /**
   * In prod we should only fire prod's integration server, irrespective of env provided
   */

  if (isProductionEnvironment()) {
    env = 'prod'
  }

  switch (env) {
    case 'dev':
    case 'stage':
    case 'sandbox':
      return API_URL.replace('[env]', `${env}-`)

    case 'prod':
      return API_URL.replace('[env]', ``)

    default:
      return API_URL.replace('[env]', `dev-`)
  }
}

/**
 * Used by PLD journey,
 * where integration environment is decided based on url
 * @returns
 */
export const getIntegrationURLFromENVForPLD = () => {
  const API_URL = 'https://[env]integration.credilinq.ai/'
  const currentEnvironment = ['-react', 'localhost', '127.0.0.1'].some((el) =>
    window.location.origin.includes(el)
  )
    ? 'stage-'
    : ''

  return API_URL.replace('[env]', currentEnvironment)
}

/**
 * This function to return the default environment based on your production check
 * @returns {string}
 */
export const getDefaultEnvironment = () => {
  return isProductionEnvironment() ? 'prod' : 'stage'
}

/**
 * Checking if the hostname or domain indicates a production environment
 * @param env
 * @returns {boolean}
 */
export const isProductionEnvironment = () => {
  return window.location.hostname === 'alexi.credilinq.ai'
}

/**
 * Sets env in localstorage based on specific condition
 * @param searchQueryEnv
 */
export const handleSettingEnv = (searchQueryEnv: string) => {
  const envExist = localStorage.getItem('env') as string

  if (!envExist) {
    /**
     * if env not exists in localstorage and if prod then set to prod otherwise set it from search query
     */
    localStorage.setItem('env', isProductionEnvironment() ? 'prod' : searchQueryEnv || 'stage')
  } else if (searchQueryEnv && !isProductionEnvironment()) {
    /**
     * if env exists in localstorage and it is non prod env and searchquery is also present then set it to searchquery
     */
    localStorage.setItem('env', searchQueryEnv)
  }
}

/**
 * Finds and returns relevant env
 * @returns
 */
export const getStoredEnv = () => {
  const envExist = localStorage.getItem('env') as string

  /**
   * Always returning prod in case of prod environment
   */
  if (isProductionEnvironment()) {
    return 'prod'
  }

  /**
   * In non prod environment return from localstorage if not there then return default
   */
  return envExist || getDefaultEnvironment()
}
