import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import RangeSlider from 'react-bootstrap-range-slider'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import NumberFormat from 'react-number-format'
import { useFormik, validateYupSchema } from 'formik'
import * as Yup from 'yup'

import { validateUEN } from '../../../../_metronic/helpers/validation'
import { fetchStartWithForward } from '../../../../redux/actions/common'
import { deleteApplicationDetails, saveApplicationDetails, setLoanSubStep } from '../redux/actions'
import { trim } from '../../../../_metronic/helpers'
import { MaintenanceModeBanner } from '../../../../_metronic/helpers/components/MaintenanceModeBanner'
import { getStoredEnv } from '../../../../utils/getIntegrationURLFromENV'

const errorMsg = {
  required: 'required',
  amountRange: 'Amount range should be between 75,000 - 500,000',
  durationRange: 'Duration range should be between 3 - 12 months',
}

const Step1Sub1: FC<any> = ({ createApplication, mobileNo }) => {
  const dispatch = useDispatch()

  const maxAmount = 500000
  const minAmount = 75000
  const maxDuration = 12
  const minDuration = 3

  const [loanAmountRange, setLoanAmountRange] = useState<any>(75000)
  const [loanDurationRange, setLoanDurationRange] = useState<any>(6)
  // const [mobileNo, setMobileNo] = useState<any>('+977984');
  // const [sendOTP, {data,loading,error}] = useMutation(mutations.SEND_OTP, { variables: { mobileNo, isApplicantOrShareholder: true } });

  const savedApplicationDetails = useSelector((state: any) => state.loan.applicationDetails)

  const handleLoanAmountRange = (value: string) => {
    dispatch(saveApplicationDetails(formik.values))
    setLoanAmountRange(value)
    formik.setFieldValue('amountRequested', value)
    //if(error.match(/ name/i)) formik.setFieldError('name', error);
  }

  const handleLoanDurationRange = (value: string) => {
    dispatch(saveApplicationDetails(formik.values))
    setLoanDurationRange(value)
    formik.setFieldValue('durationInMonths', value)
  }

  const initialValues = {
    applicantName: '',
    // applicationStatus: '',
    companyUEN: '',
    positionInCompany: '',
    amountRequested: loanAmountRange,
    durationInMonths: loanDurationRange,
    mobileNumber: mobileNo ? mobileNo : '',
    promoCode: '',
  }

  const validationSchema = Yup.object().shape({
    amountRequested: Yup.number()
      .required(errorMsg.required)
      .min(minAmount, errorMsg.amountRange)
      .max(maxAmount, errorMsg.amountRange),
    durationInMonths: Yup.number()
      .required(errorMsg.required)
      .min(minDuration, errorMsg.durationRange)
      .max(maxDuration, errorMsg.durationRange),
    companyUEN: Yup.string()
      .required('Company UEN is required')
      .test('unique', 'Invalid UEN. Kindly recheck the entered UEN', (value: any) =>
        validateUEN(value)
      ),
    positionInCompany: Yup.string()
      .required('Position In Company is required')
      .test('unique', 'Position must not contain numbers', (value: any) => {
        return !/\d/.test(value)
      }),
    applicantName: Yup.string()
      .required('Applicant Name is required')
      .test('unique', 'Applicant Name must not contain numbers', (value: any) => {
        return !/\d/.test(value)
      }),
    promoCode: Yup.string(),
    mobileNumber: Yup.string()
      .required('Mobile number is required')
      .test(
        'mobileValidation',
        'Invalid Mobile number. Kindly recheck the entered number',
        () => true
      )
      .test('singaporemobileValidation', 'Only singapore number supported', (value: any) => {
        return /\+65\d{8}/g.test(value)
      }),
    //TODO: add validation on prod
    // ,(value:any) => {
    //   if(/^\+65(8|9)\d{7}$/g.test(value) || /^\+977\d{10}$/g.test(value)){
    //     return true
    //   }
    //   return false
    // })
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: savedApplicationDetails || initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const {
        amountRequested,
        durationInMonths,
        companyUEN,
        positionInCompany,
        applicantName,
        mobileNumber,
        promoCode,
      } = trim(values)

      dispatch(fetchStartWithForward())

      createApplication({
        variables: {
          data: {
            amountRequested: amountRequested * 1,
            durationInMonths: durationInMonths * 1,
            companyUEN,
            mibCompanyUEN: companyUEN,
            positionInCompany,
            applicantName,
            mobileNumber,
            promoCode,
            applicationStatus: 'in-progress',
            applicationCompleteStep: 1.1,
            applicationType: localStorage.getItem('loanType'),
            partnerId: localStorage.getItem('partnerId') || '',
            credilinqEnvironment: getStoredEnv(),
          },
        },
      })
      setSubmitting(false)
    },
  })

  useEffect(() => {
    return () => {
      dispatch(deleteApplicationDetails())
    }
  }, [])
  return (
    <div className='d-flex flex-column flex-column-fluid'>
      <MaintenanceModeBanner />
      <h2>Loan Eligibility</h2>
      <div className='sub-heading text-muted mb-10'>
        To begin applying for a Loan, help us get to know your company better
      </div>
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className='row mb-10'>
          <label htmlFor='' className='mt-3 form-label fs-6 fw-bolder text-dark col-sm-3'>
            I want to borrow
          </label>
          <div className='col-sm-9'>
            {/* <input 
              name="amountRequested"
              type="text" 
              className="form-control form-control-lg form-control-solid" 
              value={formik.values.amountRequested}
              onChange={formik.handleChange}
            /> */}
            <NumberFormat
              name='amountRequested'
              value={formik.values.amountRequested}
              className='form-control form-control-lg form-control-solid'
              // displayType={'text'}
              thousandSeparator={true}
              prefix={'SGD$'}
              onValueChange={(values: any) => {
                let { value } = values
                formik.setFieldValue('amountRequested', value)
              }}
              onBlur={(event: any) => {
                let { value } = event.target
                value = value.substring(4)
                value = value ? parseFloat(value.replace(/,/g, '')) : 0
                value = Math.round(value / 500) * 500
                value = Math.min(maxAmount, Math.max(minAmount, value))
                formik.setFieldValue('amountRequested', value)
              }}
            />
            {formik.touched.amountRequested && formik.errors.amountRequested && (
              <div className='text-danger mt-2'>{formik.errors.amountRequested}</div>
            )}
          </div>
          <RangeSlider
            // id="amountRequested"
            min={minAmount}
            max={maxAmount}
            step={5000}
            tooltip='on'
            tooltipPlacement='top'
            tooltipLabel={(currentValue) => `SGD$${currentValue}`}
            value={formik.values.amountRequested}
            onChange={(event: any) => {
              handleLoanAmountRange(event.target.value)
            }}
            // onChange={({target:{value}}) => { if(regexNumber.test(value)) formik.setFieldValue('total', value) }}
            // onChange={formik.handleChange}
          />
          <div className='d-flex flex-row justify-content-between'>
            <div>SGD$75,000</div>
            <div>SGD$500,000</div>
          </div>
        </div>

        <div className='row mb-10'>
          <label htmlFor='' className='mt-3 form-label fs-6 fw-bolder text-dark col-sm-3'>
            How long for?
          </label>
          <div className='col-sm-4'>
            <input
              name='durationInMonths'
              type='number'
              min={minDuration}
              max={maxDuration}
              step={1}
              className='form-control form-control-lg form-control-solid'
              value={formik.values.durationInMonths}
              onChange={formik.handleChange}
              onBlur={(event: any) => {
                let { value } = event.target
                value = value ? parseInt(value) : 0
                value = Math.min(maxDuration, Math.max(minDuration, value))
                formik.setFieldValue('durationInMonths', value)
              }}
            />
            {formik.touched.durationInMonths && formik.errors.durationInMonths && (
              <div className='text-danger mt-2'>{formik.errors.durationInMonths}</div>
            )}
          </div>
          <label htmlFor='' className='mt-3 form-label fs-6 fw-bolder text-dark col-sm-3'>
            months
          </label>
          <RangeSlider
            // id="durationInMonths"
            min={3}
            max={12}
            tooltip='on'
            tooltipPlacement='top'
            tooltipLabel={(currentValue) => `${currentValue} months`}
            value={formik.values.durationInMonths}
            // onChange={formik.handleChange}
            onChange={(event: any) => {
              handleLoanDurationRange(event.target.value)
            }}
          />
          <div className='d-flex flex-row justify-content-between'>
            <div>3 months</div>
            <div>12 months</div>
          </div>
        </div>

        <div className='row mb-10'>
          <label htmlFor='' className='mt-3 form-label fs-6 fw-bolder text-dark col-sm-3 required'>
            Company UEN
          </label>
          <div className='col-sm-9'>
            <input
              name='companyUEN'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={formik.values.companyUEN}
              onChange={formik.handleChange}
            />
            {formik.touched.companyUEN && formik.errors.companyUEN && (
              <div className='text-danger mt-2'>{formik.errors.companyUEN}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='mt-3 form-label fs-6 fw-bolder text-dark col-sm-3 required'>
            Position within company
          </label>
          <div className='col-sm-9'>
            <input
              name='positionInCompany'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={formik.values.positionInCompany}
              onChange={formik.handleChange}
            />
            {formik.touched.positionInCompany && formik.errors.positionInCompany && (
              <div className='text-danger mt-2'>{formik.errors.positionInCompany}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='mt-3 form-label fs-6 fw-bolder text-dark col-sm-3 required'>
            Applicant Name
          </label>
          <div className='col-sm-9'>
            <input
              name='applicantName'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={formik.values.applicantName}
              onChange={formik.handleChange}
            />
            {formik.touched.applicantName && formik.errors.applicantName && (
              <div className='text-danger mt-2'>{formik.errors.applicantName}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='mt-3 form-label fs-6 fw-bolder text-dark col-sm-3 required'>
            Mobile Number
          </label>
          <div className='col-sm-9'>
            <PhoneInput
              id='mobileNumber'
              international
              defaultCountry='SG'
              // countryCallingCodeEditable={false}
              value={formik.values.mobileNumber}
              onChange={(value) => formik.setFieldValue('mobileNumber', value)}
              placeholder='Enter phone number'
              className='int-phone-input'
            />
            {formik.touched.mobileNumber && formik.errors.mobileNumber && (
              <div className='text-danger mt-2'>{formik.errors.mobileNumber}</div>
            )}
          </div>
        </div>
        <div className='row mb-10 relative'>
          <label htmlFor='' className='mt-3 form-label fs-6 fw-bolder text-dark col-sm-3'>
            Promo Code
          </label>
          <div className='col-sm-9'>
            <input
              name='promoCode'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={formik.values.promoCode}
              onChange={formik.handleChange}
            />
          </div>
        </div>

        <div className='d-flex flex-end'>
          <button type='submit' className='btn btn-lg btn-primary me-3'>
            Send OTP
          </button>
        </div>
      </form>
    </div>
  )
}

export { Step1Sub1 }
