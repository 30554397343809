import React, {FC, useState, useEffect} from 'react';

interface AlertProps {
  variant?:string;
  autoClose?: number;
  children: any;
  showAlert:boolean;
  setShowAlert:any;
}

const Alert:  FC<AlertProps> = ({ variant='primary', autoClose= 5000, children, showAlert, setShowAlert }) => {

  // const [show, setShow] = useState(true);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowAlert(false)
    }, autoClose)

    return () => {
      clearTimeout(timeId)
    }
  }, []);

  if (!showAlert) {
    return null;
  }

  return(
    <div className={`alert alert-${variant} alert-dismissible custom-alert`} role="alert">
      {children}
      <button type="button" className="btn-close" onClick={ () => setShowAlert(false)}></button>
    </div>
  );
}

export default Alert;
