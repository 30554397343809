import {
  SET_LOAN_APPLICATION,
  SET_LOAN_APPLICATION_STATUS,
  SET_LOAN_STEP,
  SET_LOAN_SUB_STEP,
  SET_COMPANY_VERIFICATION_METHOD,
  SET_ENTITY_DATA,
  SET_PERSON_DATA,
  SET_SHAREHOLDERS,
  SET_GUARANTOR,
  SET_DIRECTOR,
  SET_DECISION_MAKER,
  AUTHORIZE_ACRA,
  AUTHORIZE_ACRA_SUCCEEDED,
  FETCH_ENTITY_PERSON,
  FETCH_ENTITY_PERSON_SUCCEEDED,
  SET_SINGPASS_INFO,
  SET_LOAN_ELIGIBILITY,
  RESET_LOAN,
  APPLICATION_DETAILS_DELETE,
  APPLICATION_DETAILS_SAVE,
  SET_PARTNER_DETAILS,
} from '../types'

export const setLoanApplication = (payload: any) => ({
  type: SET_LOAN_APPLICATION,
  payload,
})

export const setPartnerDetails = (payload: any) => ({
  type: SET_PARTNER_DETAILS,
  payload,
})

export const setLoanApplicationStatus = (status: number) => ({
  type: SET_LOAN_APPLICATION_STATUS,
  status,
})

export const setLoanStep = (step: number) => ({
  type: SET_LOAN_STEP,
  step,
})

export const setLoanSubStep = (step: number) => ({
  type: SET_LOAN_SUB_STEP,
  step,
})

export const setCompanyVerificationMethod = (method: number) => ({
  type: SET_COMPANY_VERIFICATION_METHOD,
  method,
})

export const setLoanEligibility = (payload: any) => ({
  type: SET_LOAN_ELIGIBILITY,
  payload,
})

export const setEntityData = (payload: any) => ({
  type: SET_ENTITY_DATA,
  payload,
})

export const setPersonData = (payload: any) => ({
  type: SET_PERSON_DATA,
  payload,
})

export const setShareholders = (payload: any) => ({
  type: SET_SHAREHOLDERS,
  payload,
})

export const setGuarantor = (payload: any) => ({
  type: SET_GUARANTOR,
  payload,
})
export const setDirector = (payload: any) => ({
  type: SET_DIRECTOR,
  payload,
})

export const setDecisionMaker = (payload: any) => ({
  type: SET_DECISION_MAKER,
  payload,
})

export const setSingPassInfo = (payload: any) => ({
  type: SET_SINGPASS_INFO,
  payload,
})

export const resetLoan = () => ({
  type: RESET_LOAN,
})

export const authorizeAcra = (payload: any) => ({
  type: AUTHORIZE_ACRA,
  payload,
})

export const authorizeAcraSucceeded = (payload: any) => ({
  type: AUTHORIZE_ACRA_SUCCEEDED,
  payload,
})

export const fetchEntityPerson = (payload: any) => ({
  type: FETCH_ENTITY_PERSON,
  payload,
})

export const fetchEntityPersonSucceeded = (payload: any) => ({
  type: FETCH_ENTITY_PERSON_SUCCEEDED,
  payload,
})

export const saveApplicationDetails = (payload: any) => ({
  type: APPLICATION_DETAILS_SAVE,
  payload,
})

export const deleteApplicationDetails = () => ({
  type: APPLICATION_DETAILS_DELETE,
})
