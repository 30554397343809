import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { fetchStart } from "../../../../redux/actions/common";
import {
  setSubStep
} from "../redux/actions";
import { trim } from "../../../../_metronic/helpers";

const errorMsg = {
  required: 'Required',
  amountRange: 'Amount range should be between 75,000 - 250,000',
  durationRange: 'Duration range should be between 3 - 12 months'
}

const Step1Sub1: FC<any> = ({ sendOTP, setMobile, id, name, mobile }) => {
  const dispatch = useDispatch();

  const initialValues = {
    applicantName: name ? name : '',
    mobileNumber: mobile ? mobile : '',
  }
  console.log(name,mobile, 29)

  const validationSchema = Yup.object().shape({
    applicantName: Yup.string().required('Applicant Name is required').test('unique', 'Applicant Name must not contain numbers', (value: any) => { return !/\d/.test(value) }),
    mobileNumber: Yup.string().required(errorMsg.required)
      .test('singaporemobileValidation', 'Only singapore number supported', (value: any) => {
        return /\+65\d{8}/g.test(value)
      })
      .test('mobileValidation', 'Invalid Mobile number. Kindly recheck the entered number', () => true)

  })

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const { mobileNumber } = trim(values);

      dispatch(fetchStart());
      setMobile(mobileNumber)
      sendOTP({
        variables: {
          mobileNo: mobileNumber,
          isApplicantOrShareholder: false,
          applicantId: id
        }
      });

      setSubmitting(false);
    }
  })

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <h2>Co-Applicant eKYC</h2>   { }
      <div className="sub-heading text-muted mb-10">You have been added as a guarantor or director for your company. Kindly proceed by completing your eKYC via Myinfo Singpass</div>

      <form onSubmit={formik.handleSubmit} noValidate>
        <div className="row mb-10">
          <label htmlFor="" className="mt-3 form-label fs-6 fw-bolder text-dark col-sm-3 required">Applicant Name</label>
          <div className="col-sm-9">
            <input
              name="applicantName"
              type="text"
              className="form-control form-control-lg form-control-solid"
              value={formik.values.applicantName}
              onChange={formik.handleChange}
            />
            {formik.touched.applicantName && formik.errors.applicantName && (
              <div className='text-danger mt-2'>{formik.errors.applicantName}</div>
            )}
          </div>
        </div>
        <div className="row mb-10">
          <label htmlFor="" className="mt-3 form-label fs-6 fw-bolder text-dark col-sm-3 required">Mobile Number</label>
          <div className="col-sm-9">
            <PhoneInput
              id="mobileNumber"
              international
              defaultCountry="SG"
              // countryCallingCodeEditable={false}
              value={formik.values.mobileNumber}
              onChange={(value) => formik.setFieldValue('mobileNumber', value)}
              placeholder="Enter phone number"
              className="int-phone-input"
            />
            {formik.touched.mobileNumber && formik.errors.mobileNumber && (
              <div className='text-danger mt-2'>{formik.errors.mobileNumber}</div>
            )}
          </div>
        </div>

        <div className="d-flex flex-end">
          <button type="submit" className='btn btn-lg btn-primary me-3'>Send OTP</button>
        </div>

      </form>
    </div>
  )
}

export { Step1Sub1 };