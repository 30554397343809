import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import CoApplicant from "./CoApplicant";
// import LoanType from "./LoanType";
import CoApplicantComplete from "./CoApplicantComplete";

const CoApplicantWrapper: FC = () => {

  return(
    <Switch>
      <Route path="/co-applicant/completed" exact> <CoApplicantComplete/> </Route>
      <Route path="/co-applicant" exact> <CoApplicant/> </Route>
      <Route path="/co-applicant/:id" exact><CoApplicant/></Route>
    </Switch>
  )
}

export default CoApplicantWrapper;