import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'

import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import query from '../../../setup/graphql/query'
import { fetchStop } from '../../../redux/actions/common'
import { setLoanApplication } from './redux/actions'
import Alert from '../../../_metronic/partials/alert'
import { LoanApplicationLocalStorageKeys } from '../../../utils/constants/LocalStorageKeys.enum'

const LoanComplete: FC = () => {
  const dispatch = useDispatch()
  const [getApplication, onSuccessGetApplication] = useLazyQuery(query.GET_APPLICATION)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const loanApplication = useSelector((state: any) => state.loan.loanApplication)
  const params: any = useParams()
  const { id } = params

  useEffect(() => {
    if (onSuccessGetApplication.called && !onSuccessGetApplication.loading) {
      dispatch(fetchStop())
      if (onSuccessGetApplication.data) {
        const loanApplication = onSuccessGetApplication.data.getLatestApplication
        if (loanApplication !== null) {
          dispatch(setLoanApplication(loanApplication))
          localStorage.removeItem('loanApplicationId')
          localStorage.removeItem('singpass')
          localStorage.removeItem('errorLogged')
          localStorage.removeItem('perfiosId')
          localStorage.removeItem('coApplicationId')
          localStorage.removeItem('accountDetails')
          localStorage.removeItem('env')
          localStorage.removeItem('uploadedFiles')
          localStorage.removeItem('loanType')
          localStorage.removeItem('partnerApplicationType')
          localStorage.removeItem('partnerId')
          localStorage.removeItem(LoanApplicationLocalStorageKeys.LOAN_APPLICATION_SUBMISSION_METHOD)
        }
        if (onSuccessGetApplication.error) {
          setShowAlert(true)
          setAlertMsg('error')
          setAlertType('primary')
          dispatch(fetchStop())
        }
      }
    }
  }, [onSuccessGetApplication.loading])

  useEffect(() => {
    const loanApplicationId = localStorage.getItem('loanApplicationId')
    if (id) {
      if (loanApplication === null) {
        getApplication({
          variables: {
            appId: id || loanApplicationId,
          },
        })
      }
    } else {
      localStorage.removeItem('loanApplicationId')
      localStorage.removeItem('singpass')
      localStorage.removeItem('errorLogged')
      localStorage.removeItem('perfiosId')
      localStorage.removeItem('coApplicationId')
      localStorage.removeItem('accountDetails')
      localStorage.removeItem('env')
      localStorage.removeItem('uploadedFiles')
      localStorage.removeItem('loanType')
      localStorage.removeItem('partnerApplicationType')
      localStorage.removeItem('partnerId')
      localStorage.removeItem(LoanApplicationLocalStorageKeys.LOAN_APPLICATION_SUBMISSION_METHOD)
    }
  }, [id])

  useEffect(() => {
    ReactPixel.track('Completed Registration')
  }, [])

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid w-100'>
        <div className='d-flex flex-column flex-lg-row-auto w-xl-800px'>
          <div className='d-flex flex-row-fluid flex-column p-20 pt-lg-20'>
            <div className='logo text--center mb-5'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo-cred-2.png')}
                className='h-200px'
              />
            </div>

            <h1 className='title mb-2 text-black'>Congratulations</h1>
            <div className='desc text-muted text-bold'>
              {/* Your Loan Application has been submitted successfully and our team will get back to you with the updated status in 48 hours */}
              <div className='fs-5 mt-3'>You loan application is successfully submitted.</div>
              <div className='fs-5 mt-3'>
                You will be contacted by CrediLinq.Ai team on your registered mobile number and
                email address for a personal discussion (via phone or at your company's office) at a
                mutually convenient time.
              </div>
              <div className='fs-5 mt-3'>
                We will also send you the loan application form to be signed via email.
              </div>
              <div className='fs-5 mt-3'>
                Thank you for trusting CrediLinq.Ai for your financial needs.
              </div>
            </div>
          </div>
          <div
            className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-150px min-h-lg-300px'
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/alexi/Illustration-14-1.svg')}')`,
            }}
          ></div>
        </div>
        <div className='d-flex flex-column flex-lg-row-fluid py-10'>
          <div className='d-flex flex-center flex-column flex-column-fluid'>
            <div className='p-10 p-lg-15 mx-auto'>
              <h1 className='text-center mb-10'>Application Details</h1>

              <h6 className='font-weight-bolder mb-3'>Loan Application Number</h6>
              <div className='text-dark-50 bg-gray-200 p-4 mb-10'>
                {loanApplication ? loanApplication.applicationNumber : 'xxxxxxxxxxxxxxxxx'}
              </div>

              <h6 className='font-weight-bolder mb-3'>Applicant Name</h6>
              <div className='text-dark-50 bg-gray-200 p-4 mb-10'>
                {loanApplication ? loanApplication.applicantName : 'xxxxxxxxxxxxxxxxx'}
              </div>

              <h6 className='font-weight-bolder mb-3'>Applicant Mobile Number</h6>
              <div className='text-dark-50 bg-gray-200 p-4 mb-10'>
                {loanApplication ? loanApplication.mobileNumber : 'xxxxxxxxxxxxxxxxx'}
              </div>

              <div>
                You can talk to us emailing us at{' '}
                <a href='mailto::loans@credilinq.ai'>loans@credilinq.ai</a> and use above
                information as reference
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}
    </div>
  )
}

export default LoanComplete
