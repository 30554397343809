/* eslint-disable import/no-anonymous-default-export */
import { gql } from '@apollo/client'
import keys from './keys'

export default {
  LOGIN: gql`
    mutation ($email: String!, $password: String!) {
      authenticateUserWithPassword(email: $email, password: $password) {
        __typename
      }
    }
  `,
  SEND_OTP: gql`
    mutation ($mobileNo: String!, $isApplicantOrShareholder: Boolean!, $applicantId: ID) {
      generateOtp(
        mobile: $mobileNo
        isApplicantOrShareholder: $isApplicantOrShareholder
        coApplicantId: $applicantId
      ) {
        id
        success
        type
      }
    }
  `,
  VALIDATE_OTP: gql`
    mutation (
      $mobileNo: String!
      $otp: String!
      $isApplicantOrShareholder: Boolean!
      $applicantId: ID
    ) {
      validateOtp(
        mobile: $mobileNo
        otp: $otp
        isApplicantOrShareholder: $isApplicantOrShareholder
        coApplicantId: $applicantId
      ) {
        success
        message
      }
    }
  `,
  CREATE_APPLICATION: gql`
        mutation ($data: ApplicationCreateInput!) { 
            createApplication(data: $data){
                ${keys.Application.join(',')}
            }
        }
    `,
  UPDATE_APPLICATION: gql`
        mutation ($data: ApplicationUpdateInput!, $where: ApplicationWhereUniqueInput!) {
            updateApplication(data: $data, where: $where){
                ${keys.Application.join(',')},
                loanEligibility{
                    ${keys.LoanEligibility.join(',')}
                },
                incomeDocuments{
                    ${keys.IncomeDocuments.join(',')}
                    documentFile{
                        ${keys.DocumentFile.join(',')}
                    }
                },
                shareholderKycStatus{
                    ${keys.ShareholderKYCStatus.join(',')}
                },
                externalDataStore{
                    ${keys.ExternalDataStore.join(',')}
                },
                phoneDiscussion{
                    ${keys.PhoneDisscussion.join(',')}
                }
            }
        }
    `,
  CREATE_LOAN_ELIGIBILITY: gql`
        mutation ($data: LoanEligibilityCreateInput!) { 
            createLoanEligibility(data: $data){
                ${keys.LoanEligibility.join(',')}
            }
        }
    `,
  UPDATE_LOAN_ELIGIBILITY: gql`
        mutation ($data: LoanEligibilityUpdateInput!, $where: LoanEligibilityWhereUniqueInput!) {
            updateLoanEligibility(data: $data, where: $where){
                ${keys.LoanEligibility.join(',')}
            }
        }
    `,
  CREATE_INCOME_DOCUMENT: gql`
    mutation ($data: IncomeDocumentCreateInput!) {
      createIncomeDocument(data: $data) {
        id
      }
    }
  `,
  CREATE_INCOME_DOCUMENTS: gql`
        mutation($data: [IncomeDocumentCreateInput!]!){
            createIncomeDocuments(data: $data){
                ${keys.IncomeDocuments.join(',')},
                documentFile{
                    ${keys.DocumentFile.join(',')}
                }
            }
        }
    `,
  DELETE_INCOME_DOCUMENT: gql`
    mutation ($where: IncomeDocumentWhereUniqueInput!) {
      deleteIncomeDocument(where: $where) {
        id
      }
    }
  `,
  CREATE_EXTERNAL_DATA_STORE: gql`
    mutation ($data: ExternalDataStoreCreateInput!) {
      createExternalDataStore(data: $data) {
        id
      }
    }
  `,
  CREATE_SHAREHOLDER_KYC_STATUS: gql`
    mutation ($data: ShareholderKYCStatusCreateInput!) {
      createShareholderKYCStatus(data: $data) {
        id
      }
    }
  `,
  UPDATE_SHAREHOLDER_KYC: gql`
        mutation($where: ShareholderKycStatusWhereUniqueInput!, $data:ShareholderKycStatusUpdateInput!){
            updateShareholderKycStatus(data: $data,where: $where){
                ${keys.ShareholderKYCStatus.join(',')}
            }
        }
    `,
  UPDATE_SHAREHOLDER_KYC_STATUS: gql`
    mutation ($data: [ShareholderKycStatusUpdateArgs!]!) {
      updateShareholderKycStatuses(data: $data) {
        id
      }
    }
  `,
  CREATE_PHONE_DISSCUSSION: gql`
    mutation ($data: PhoneDiscussionCreateInput!) {
      createPhoneDiscussion(data: $data) {
        id
      }
    }
  `,
  RESEND_GUARANTOR_EMAIL: gql`
    mutation ($shareholderId: ID!) {
      resendGuarantorEmail(shareholderId: $shareholderId)
    }
  `,
}
